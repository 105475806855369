// import React, { useState, useEffect } from "react";
// import Navbar from "../components/navbar";
// import Sidemanu from "../components/sidemanu";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { getApps, initializeApp } from "firebase/app";
// import { Modal, Button, message } from "antd";
// import { v4 } from "uuid";
// import { imageDb } from "../firebase";
// import {Card } from "antd";
// import Cookies from "js-cookie";

// const firebaseConfig = {
//     apiKey: "AIzaSyCzOTliKqjNZeZ-NLZqdV-_3miXolgLb88",
//     authDomain: "your-gut-map-fa-lab.firebaseapp.com",
//     projectId: "your-gut-map-fa-lab",
//     storageBucket: "your-gut-map-fa-lab.appspot.com",
//     messagingSenderId: "676426580196",
//     appId: "1:676426580196:web:9ae513007b748f9323acf1",
//     measurementId: "G-0LVJ73NLJF"
// }; // Your Firebase Config Here

// if (!getApps().length) {
//   initializeApp(firebaseConfig);
// }
// const storage = getStorage();

// const ReportStyles = () => {
//   const token = Cookies.get("Token");
//   const [styles, setStyles] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [modalVisible, setModalVisible] = useState(false);
//   const [editingStyle, setEditingStyle] = useState(null);
//   const [styleName, setStyleName] = useState("");
//   const [primaryColor, setPrimaryColor] = useState("#19b0e6");
//   const [secondaryColor, setSecondaryColor] = useState("#f4f4f4");
//   const [image, setImage] = useState(null);
//   const [previewImage, setPreviewImage] = useState(null);
//   const [isLoading,setIsLoading] = useState(true)

//   useEffect(() => {
//     const fetchStyles = async () => {
//       try {
//         // const response = await fetch(`${process.env.REACT_APP_API_URL}/getallstyles`);
//         const response = await fetch(`https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallstyles`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch styles");
//         }
//         const data = await response.json();
//         setStyles(data.data || []);
//         setIsLoading(false)
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchStyles();
//   }, []);

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setImage(file);
//       setPreviewImage(URL.createObjectURL(file));
//     }
//   };

// //   const saveStyle = async (e) => {
// //     e.preventDefault();
// //     if (!styleName) return message.error("Please enter a style name");

// //     let imageUrl = editingStyle?.imageUrl;
// //     let imageStoragePath = editingStyle?.imageStoragePath;

// //     if (image) {
// //       const imageId = v4();
// //       imageStoragePath = `report-styles/${imageId}`;
// //       const imgRef = ref(storage, imageStoragePath);
// //       await uploadBytes(imgRef, image);
// //       imageUrl = await getDownloadURL(imgRef);
// //     }

// //     const styleData = {
// //       styleName,
// //       primaryColor,
// //       secondaryColor,
// //       imageUrl,
// //       imageStoragePath,
// //     };

// //     if (editingStyle?._id) {
// //       styleData._id = editingStyle._id;
// //     }

// //     const requestOptions = {
// //       method: "POST",
// //       headers: { "Content-Type": "application/json" },
// //       body: JSON.stringify(styleData),
// //     };

// //     await fetch(`${process.env.REACT_APP_API_URL}/savestyle`, requestOptions)
// //       .then((response) => response.json())
// //       .then((result) => {
// //         if (editingStyle) {
// //           setStyles((prev) => prev.map((s) => (s._id === result.newStyle._id ? result.newStyle : s)));
// //         } else {
// //           setStyles((prev) => [...prev, result.newStyle]);
// //         }
// //         setModalVisible(false);
// //         setEditingStyle(null);
// //         message.success("Style saved successfully!");
// //       })
// //       .catch((error) => console.log("error", error));
// //   };

// const saveStyle = async (e) => {
//     e.preventDefault();
//     if (!styleName) return message.error("Please enter a style name");
  
//     let base64Image = editingStyle?.imageUrl; // Store Base64 Image instead of URL
  
//     if (image) {
//       base64Image = await convertImageToBase64(image); // Convert image to Base64
//     }
  
//     const styleData = {
//       styleName,
//       primaryColor,
//       secondaryColor,
//       imageBase64: base64Image, // Send Base64 image instead of URL
//     };
  
//     if (editingStyle?._id) {
//       styleData._id = editingStyle._id;
//     }
  
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(styleData),
//     };
  
//     // await fetch(`${process.env.REACT_APP_API_URL}/savestyle`, requestOptions)
//     await fetch(`https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/savestyle`, requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (editingStyle) {
//           setStyles((prev) =>
//             prev.map((s) => (s._id === result.newStyle._id ? result.newStyle : s))
//           );
//         } else {
//           setStyles((prev) => [...prev, result.newStyle]);
//         }
//         setModalVisible(false);
//         setEditingStyle(null);
//         message.success("Style saved successfully!");
//       })
//       .catch((error) => console.log("Error:", error));
//   };
//   const convertImageToBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = (error) => reject(error);
//     });
//   };
    

//   const deleteStyle = async (id) => {
//     try {
//       // await fetch(`${process.env.REACT_APP_API_URL}/deletestyle/${id}`, { method: "DELETE" });
//       await fetch(`https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/deletestyle/${id}`, { method: "DELETE" });
//       setStyles((prev) => prev.filter((s) => s._id !== id));
//       message.success("Style deleted successfully from database!");
//     } catch (error) {
//       message.error("Error deleting style");
//       console.log("Delete Error:", error);
//     }
//   };

//   return (
//     <>
//     {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
//       token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && (
//         <div>
//       <Navbar />
//       <div className="deshboardmain">
//         <Sidemanu />
//         <div className="Practitionermainbody">
//         <div className="header">
//             <h1 className="pppheading" style={{ marginBottom: "20px" }}>Report Styles</h1>
//           </div>
//         {!isLoading ? <>
        
//           <div className="" style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>

//           <Button type="primary" style={{background:"#6e4f9f",marginBottom:"20px"}} onClick={() => { setModalVisible(true); setEditingStyle(null); }}>Add Style</Button>
//           </div>

//           <Modal title={editingStyle ? "Edit Style" : "Add Style"} visible={modalVisible} onCancel={() => setModalVisible(false)} onOk={saveStyle}>
//             <input type="text" value={styleName} onChange={(e) => setStyleName(e.target.value)} placeholder="Enter style name" />
//             <input type="color" value={primaryColor} onChange={(e) => setPrimaryColor(e.target.value)} />
//             <input type="color" value={secondaryColor} onChange={(e) => setSecondaryColor(e.target.value)} />
//             <input type="file" accept="image/*" onChange={handleImageChange} />
//             {previewImage && <img src={previewImage} alt="Preview" style={{ width: "100px", height: "auto", marginTop: "10px" }} />}
//           </Modal>

//            <div className="styles-container" style={{marginBottom:"40px"}}>
//             {styles.map((style) => (
//               <Card key={style._id} className="style-card"  title={<span style={{ fontSize: "1.5em", fontWeight: "bold" }}>{style.styleName}</span>} bordered style={{borderColor:"#19b0e6",marginTop:"20px"}}>
//                 <div className="style-content" style={{ display: "flex", alignItems: "center",minHeight:"160px" }}>
//                   <div className="style-image" style={{ flex: 1 }}>
//                     {style.imageBase64 && <img src={style.imageBase64} alt="Style Image" style={{ width: "50%", height: "auto" }} />}
//                   </div>
//                   <div className="style-colors" style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center",gap:"10px" }}>
//                     <div style={{ backgroundColor: style.primaryColor, height: "50px", width: "70%",  borderRadius:"6px", textAlign: "center", lineHeight: "50px", color: "#fff" }}>Primary</div>
//                     <div style={{ backgroundColor: style.secondaryColor, height: "50px", width: "70%",borderRadius:"6px", textAlign: "center", lineHeight: "50px", color:"#fff" }}>Secondary</div>
//                   </div>
//                 <div style={{ marginTop: "10px", textAlign: "center", flex: 1, display: "flex", flexDirection: "column", alignItems: "center" ,gap:"10px"}}>
//                   <Button onClick={() => {
//                     setEditingStyle(style);
//                     setStyleName(style.styleName);
//                     setPrimaryColor(style.primaryColor);
//                     setSecondaryColor(style.secondaryColor);
//                     setPreviewImage(style.imageBase64);
//                     setModalVisible(true);
//                   }}>Edit</Button>
//                   <Button danger onClick={() => deleteStyle(style._id)} style={{ marginLeft: "10px" }}>Delete</Button>
//                 </div>
//                 </div>
//               </Card>
//             ))}
//           </div></> : <img alt='' src='/empty.gif' width={"40%"} />}
//         </div>
//       </div>
//     </div>
//       )}
//       </>
//   );
// };

// export default ReportStyles;


import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import Sidemanu from "../components/sidemanu";
import { getApps, initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { Modal, Button, message, Card } from "antd";
import Cookies from "js-cookie";

const firebaseConfig = {
  apiKey: "AIzaSyCzOTliKqjNZeZ-NLZqdV-_3miXolgLb88",
  authDomain: "your-gut-map-fa-lab.firebaseapp.com",
  projectId: "your-gut-map-fa-lab",
  storageBucket: "your-gut-map-fa-lab.appspot.com",
  messagingSenderId: "676426580196",
  appId: "1:676426580196:web:9ae513007b748f9323acf1",
  measurementId: "G-0LVJ73NLJF",
};

if (!getApps().length) {
  initializeApp(firebaseConfig);
}
const storage = getStorage();

const ReportStyles = () => {
  const token = Cookies.get("Token");
  const [styles, setStyles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingStyle, setEditingStyle] = useState(null);
  const [styleName, setStyleName] = useState("");
  const [primaryColor, setPrimaryColor] = useState("#19b0e6");
  const [secondaryColor, setSecondaryColor] = useState("#f4f4f4");
  const [image, setImage] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [footerImage, setFooterImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewHeader, setPreviewHeader] = useState(null);
  const [previewFooter, setPreviewFooter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const response = await fetch(`https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallstyles`);
        if (!response.ok) {
          throw new Error("Failed to fetch styles");
        }
        const data = await response.json();
        setStyles(data.data || []);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchStyles();
  }, []);

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const saveStyle = async (e) => {
    e.preventDefault();
    if (!styleName) return message.error("Please enter a style name");

    let base64Image = editingStyle?.imageBase64;
    let base64Header = editingStyle?.headerBase64;
    let base64Footer = editingStyle?.footerBase64;

    if (image) base64Image = await convertImageToBase64(image);
    if (headerImage) base64Header = await convertImageToBase64(headerImage);
    if (footerImage) base64Footer = await convertImageToBase64(footerImage);

    const styleData = {
      styleName,
      primaryColor,
      secondaryColor,
      imageBase64: base64Image,
      header: base64Header,
      footer: base64Footer,
    };

    if (editingStyle?._id) {
      styleData._id = editingStyle._id;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(styleData),
    };

    await fetch(`https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/savestyle`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (editingStyle) {
          setStyles((prev) =>
            prev.map((s) => (s._id === result.newStyle._id ? result.newStyle : s))
          );
        } else {
          setStyles((prev) => [...prev, result.newStyle]);
        }
        setModalVisible(false);
        setEditingStyle(null);
        message.success("Style saved successfully!");
      })
      .catch((error) => console.log("Error:", error));
  };

  const deleteStyle = async (id) => {
    try {
      await fetch(`https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/deletestyle/${id}`, { method: "DELETE" });
      setStyles((prev) => prev.filter((s) => s._id !== id));
      message.success("Style deleted successfully from database!");
    } catch (error) {
      message.error("Error deleting style");
      console.log("Delete Error:", error);
    }
  };

  return (
    <>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
        token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && (
        <div>
          <Navbar />
          <div className="deshboardmain">
            <Sidemanu />
            <div className="Practitionermainbody">
              <div className="header">
                <h1 className="pppheading" style={{ marginBottom: "20px" }}>
                  Report Styles
                </h1>
              </div>
              {!isLoading ? (
                <>
                  <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="primary"
                      style={{ background: "#6e4f9f", marginBottom: "20px" }}
                      onClick={() => {
                        setModalVisible(true);
                        setEditingStyle(null);
                        setStyleName("");
                        setPrimaryColor("#19b0e6");
                        setSecondaryColor("#f4f4f4");
                        setImage(null);
                        setHeaderImage(null);
                        setFooterImage(null);
                        setPreviewImage(null);
                        setPreviewHeader(null);
                        setPreviewFooter(null);
                      }}
                    >
                      Add Style
                    </Button>
                  </div>

                  <Modal
                    title={editingStyle ? "Edit Style" : "Add Style"}
                    open={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    onOk={saveStyle}
                  >
                    <input
                      type="text"
                      value={styleName}
                      onChange={(e) => setStyleName(e.target.value)}
                      placeholder="Enter style name"
                    />
                    <input
                      type="color"
                      value={primaryColor}
                      onChange={(e) => setPrimaryColor(e.target.value)}
                    />
                    <input
                      type="color"
                      value={secondaryColor}
                      onChange={(e) => setSecondaryColor(e.target.value)}
                    />

                    <label>Main Image:</label>
                    <input type="file" accept="image/*" onChange={handleImageChange} />
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{ width: "100px", height: "auto", marginTop: "10px" }}
                      />
                    )}

                    <label>Header Image:</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setHeaderImage(file);
                          setPreviewHeader(URL.createObjectURL(file));
                        }
                      }}
                    />
                    {previewHeader && (
                      <img
                        src={previewHeader}
                        alt="Header Preview"
                        style={{ width: "100px", marginTop: "10px" }}
                      />
                    )}

                    <label>Footer Image:</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setFooterImage(file);
                          setPreviewFooter(URL.createObjectURL(file));
                        }
                      }}
                    />
                    {previewFooter && (
                      <img
                        src={previewFooter}
                        alt="Footer Preview"
                        style={{ width: "100px", marginTop: "10px" }}
                      />
                    )}
                  </Modal>

                  <div className="styles-container" style={{ marginBottom: "40px" }}>
                    {styles.map((style) => (
                      <Card
                        key={style._id}
                        className="style-card"
                        title={
                          <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                            {style.styleName}
                          </span>
                        }
                        bordered
                        style={{ borderColor: "#19b0e6", marginTop: "20px" }}
                      >
                        <div
                          className="style-content"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            minHeight: "160px",
                          }}
                        >
                          <div className="style-image" style={{ flex: 1 }}>
                            {style.imageBase64 && (
                              <img
                              src={style.imageBase64}
                                alt="Main"
                                style={{ width: "50%", height: "auto" }}
                                />
                              )}
                              <p>Logo</p>
                          </div>
                          <div
                            className="style-colors"
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: style.primaryColor,
                                height: "50px",
                                width: "70%",
                                borderRadius: "6px",
                                textAlign: "center",
                                lineHeight: "50px",
                                color: "#fff",
                              }}
                            >
                              Primary
                            </div>
                            <div
                              style={{
                                backgroundColor: style.secondaryColor,
                                height: "50px",
                                width: "70%",
                                borderRadius: "6px",
                                textAlign: "center",
                                lineHeight: "50px",
                                color: "#fff",
                              }}
                            >
                              Secondary
                            </div>
                          </div>
                          <div
                            className="style-colors"
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div className="style-image" style={{ flex: 1 }}>
                            {style.imageBase64 && (
                              <img
                                src={style.header || '/blank.png'}
                                alt="Main"
                                style={{ width: "50%", height: "auto" }}
                                />
                              )}
                              {style.header ? <p>Header</p> : null}
                          </div>
                          <div className="style-image" style={{ flex: 1 }}>
                            {style.imageBase64 && (
                              <img
                              src={style.footer || '/blank.png'}
                              alt="Main"
                              style={{ width: "50%", height: "auto" }}
                              />
                            )}
                            {style.header ? <p>Footer</p> : null}
                          </div>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {style.headerBase64 && (
                              <img
                                src={style.headerBase64}
                                alt="Header"
                                style={{ width: "50px", height: "auto" }}
                              />
                            )}
                            {style.footerBase64 && (
                              <img
                                src={style.footerBase64}
                                alt="Footer"
                                style={{ width: "50px", height: "auto" }}
                              />
                            )}

                            <Button
                              onClick={() => {
                                setEditingStyle(style);
                                setStyleName(style.styleName);
                                setPrimaryColor(style.primaryColor);
                                setSecondaryColor(style.secondaryColor);
                                setPreviewImage(style.imageBase64);
                                setPreviewHeader(style.headerBase64);
                                setPreviewFooter(style.footerBase64);
                                setModalVisible(true);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              danger
                              onClick={() => deleteStyle(style._id)}
                              style={{ marginLeft: "10px" }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </>
              ) : (
                <img alt="" src="/empty.gif" width={"40%"} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportStyles;
