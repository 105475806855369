import React, { useState, useEffect, useRef, useMemo } from 'react'
import Navbar from '../../components/navbar';
import Sidemanu from '../../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Button, Checkbox, message, Progress } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../../css/sendemail.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaFileArrowDown } from 'react-icons/fa6';
import { ImCross } from 'react-icons/im';
import ReactQuill, { Quill } from 'react-quill';
import Papa from 'papaparse';
import { ImUpload2, ImUserTie } from 'react-icons/im'
import NotLogin from '../../components/notLogin';
import { SiMinutemailer } from 'react-icons/si';
import 'react-quill/dist/quill.snow.css';
import { imageDb } from '../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
const Delta = Quill.import("delta")


function Massemail() {



  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const token2 = Cookies.get("Token2")
  const [subject, setsubject] = useState("")




  const [from, setfrom] = useState("lab@yourgutmap.co.uk")
  const [Template, setTemplate] = useState([])

  const [Message, setMessages] = useState([]);
  const messagesEndRef = useRef(null); // Reference to the bottom of the message list

  const [to, setto] = useState("")
  const [trackemail, settrackemail] = useState(false)
  const [saveemail, setsaveemail] = useState(false)
  const [allinone, setallinone] = useState(false)
  const [popup, setpopup] = useState(false)
  const [popup2, setpopup2] = useState(false)
  const [selectedevent, setselectedevent] = useState({})

  const [loading, setloading] = useState(false)
  const [loading2, setloading2] = useState(false)
  const [loadingbar, setloadingbar] = useState(0)

  const apiurlforbackend = process.env.REACT_APP_APIURL;
  const [tosendemail, settosendemail] = useState([])
  const [nottosend, setnottosend] = useState([])
  const [practitioner, setpractitioner] = useState([])
  const [event, setevent] = useState([])

  const [content, setContent] = useState('');

  const quillRef = useRef(null);



  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ['Apple'];
  const [checkedList, setCheckedList] = useState([]);
  const [checkedList2, setCheckedList2] = useState([]);

  const checkAll = practitioner.length === checkedList.length;
  const [emailstyles, setemailstyles] = useState(false)



  const indeterminate = checkedList.length > 0 && checkedList.length < practitioner.length;


  const onChange = (e) => {
    const { checked, value } = e.target; // ✅ Extract checkbox state and value

    setCheckedList(prev =>
      Array.isArray(prev) // ✅ Ensure prev is an array
        ? checked
          ? [...prev, value] // ✅ Add value if checked
          : prev.filter(item => item !== value) // ✅ Remove if unchecked
        : [value] // ✅ If prev is not an array, initialize it correctly
    );
  };


  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? practitioner.map(item => item.email) : []);
  };


  const getprac = async () => {




    let data = JSON.stringify({



    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/getpracnameandemailforemail',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        setpractitioner(response.data.pracname);

      })
      .catch((error) => {
        console.log(error);
      });


  }


  const sendEmail = async (e) => {
    e.preventDefault();

    if (!content.trim()) {
      message.error("Please enter the email content");
      return;
    }


    if (tosendemail.length === 0) {
      message.error("Please enter the emails");
      return;
    }

    const modifiedContent = content.replace(/<img /g, '<img style="width: 100%;" ');

    await setloading(true)
    await setloading2(true)

    await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">Processing ${tosendemail.length} Emails </p>`]);

    let procces = 0



    if (!allinone) {
      for (const value of tosendemail) {


        await new Promise(resolve => setTimeout(resolve, 1000));
        procces++
        await setloadingbar(Math.round((procces / tosendemail.length) * 100))

        try {
          await axios.post(apiurlforbackend + '/send-singal-email', { to: value, subject, content:modifiedContent, trackemail, from, loginname, loginemail, saveemail, emailstyles });
          message.success("Email sent successfully");
          await setMessages((prevMessages) => [...prevMessages, `<p style="color:green;">Email sent to ${value} successfully </p>`]);

        } catch (error) {
          message.error("Failed to send email");
          await setMessages((prevMessages) => [...prevMessages, `<p style="color:red;">Email sent to ${value} Failed </p>`]);

        }

      }
    } else {

      const value2 = tosendemail.join(",");
      try {
        await axios.post(apiurlforbackend + '/send-singal-email', { to: value2, subject, content, trackemail, from, loginname, loginemail, saveemail });
        message.success("Email sent successfully");
        await setMessages((prevMessages) => [...prevMessages, `<p style="color:green;">Email sent to ${value2} successfully </p>`]);

      } catch (error) {
        message.error("Failed to send email");
        await setMessages((prevMessages) => [...prevMessages, `<p style="color:red;">Failed to send email </p>`]);

      }
    }



    setloading2(false)
    // try {
    //   await axios.post(apiurlforbackend + '/send-singal-email', { to, subject, content, trackemail, from, loginname, loginemail, saveemail });
    //   message.success("Email sent successfully");

    // } catch (error) {
    //   message.error("Failed to send email");
    // }

    setContent("")
   
    setsubject("")
    settrackemail(false)
    // setloading(false)
  };


  const imageHandler = () => {
    console.log("Image button clicked");
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;
      const date = Date.now();
      const imgRef = ref(imageDb, `emailimages/${date}-${file.name}`);

      // Upload image to Firebase
      await uploadBytes(imgRef, file);
      const filefirebaseurl = await getDownloadURL(imgRef);

      const quill = quillRef.current?.getEditor();
      const range = quill.getSelection();

      if (quill) {
        quill.insertEmbed(range.index, "image", filefirebaseurl);

        // Wait for the image to be inserted, then modify its size
      
      }
    };
  };


  const attachmentHandler = () => {
    console.log("Attachment button clicked");
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;
      const date = Date.now();
      const fileRef = ref(imageDb, `emailattachments/${date}-${file.name}`);

      // Upload file to Firebase
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      const quill = quillRef.current?.getEditor();
      const range = quill.getSelection();

      // Custom styled attachment block
      const attachmentHTML = `
              <br>
              <a href='${fileUrl}' target="_blank"
                  style="display: flex; text-decoration: none; align-items: center; padding: 10px; 
                  border: 2px solid #ddd; border-radius: 8px; background-color: #f9f9f9; 
                  width: 300px; cursor: pointer;">
                  <img src="https://img.icons8.com/ios-filled/50/000000/pdf.png" alt="PDF Icon"
                      style="width: 40px; height: 40px; margin-right: 10px;">
                  <div>
                      <p style="margin: 0; font-size: 16px; color: #333;">${file.name}</p>
                  </div>
              </a>
              <br>
          `;

      if (quill) {
        quill.clipboard.dangerouslyPasteHTML(range.index, attachmentHTML);
        quill.setSelection(range.index + 1);
      }
    };
  };


  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ["link", "image", "custom-attachment"],  // Add attachment button
      ],
      handlers: {
        image: imageHandler,
        "custom-attachment": attachmentHandler, // Custom attachment handler
      },
    },
  }), []); // Ensures this object is not recreated on each render

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
    "image",

  ];



  // useEffect(() => {
  //     if (quillRef.current) {
  //         quillRef.current.getEditor().clipboard.addMatcher("img", (node, delta) => {
  //             return new Delta(); // Prevents image insertion
  //         });
  //     }
  // }, []);
  useEffect(() => {
    const toolbar = document.querySelector(".ql-toolbar");
    if (toolbar) {
      const button = document.createElement("button");
      button.innerHTML = "📎"; // Paperclip icon
      button.type = "button"; // Paperclip icon
      button.className = "ql-custom-attachment"; // Custom class
      button.onclick = attachmentHandler;
      toolbar.appendChild(button);
    }
  }, [loading]);





  const gettemp = async () => {



    try {
      const response = await axios.post(apiurlforbackend + '/get-email-template', { tempfor: 'all' });
      await setTemplate(response.data)

    } catch (error) {
      message.error("Failed to send email");
    }

  };


  const getevent = async (page) => {

    let data = JSON.stringify({
      // page: page,
      status: false,

    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/geteventsbystatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setevent(response.data.data)
        // setpage(response.data.currentPage)
        // settotalPages(response.data.totalPages)
        // settotal(response.data.totalEvents)

      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      gettemp()
      getprac()
      getevent()
    }
  }, [])



  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [Message]);


  return (
    <div>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") ?
        <>



          <Navbar />

          <div className='deshboardmain'>
            <Sidemanu />

            <div className='mainbody' style={{ paddingTop: "20px" }}>


              <h1
                style={{
                  color: "#6E4E9F",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SiMinutemailer style={{ width: "35px", height: "35px" }} />
                Send Mass Email
              </h1>

              <div className='emailbody' style={{ marginBottom: "25px" }}>

                {loading ? (<>



                  <div style={{ padding: "20px" }}>


                    <h3>Logs</h3>
                    <div className='showreportlogs'>
                      {Message.map((value, index) => (<>
                        <div
                          dangerouslySetInnerHTML={{ __html: value }}
                        />



                      </>))}
                      {!allinone && <>   <Progress percent={loadingbar} strokeColor="#6E4E9F" percentPosition={{ align: 'center', type: 'inner' }} size={["40%", 30]} />
                      </>}
                      {loading2 ? (<>
                        <img alt='' src='/empty.gif' width={"5%"} />
                      </>) : (<>


                        <Button style={{ marginTop: "20px" }} onClick={() => {
                          setMessages([])
                          setloading(false)
                          setloadingbar(0)
                        }} danger>Clear</Button >
                      </>)}
                      <div ref={messagesEndRef} />
                    </div>


                  </div>





                </>) : (<>

                  <form onSubmit={(e) => {
                    e.preventDefault()
                    settosendemail((prev) => {
                      if (!prev.includes(to)) {
                        return [...prev, to]; // ✅ Add only if value is not present
                      }
                      return prev; // ✅ Return previous state if value already exists
                    });

                    setto("")
                  }} className='addemailform'>
                    <div className='contantdiv  ' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                      <h3>Add Email:</h3>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div className='addemailform'>
                          <input type='email' required className='formalinput ' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '86.9%' }} value={to} onChange={(e) => setto(e.target.value)} />
                          <button className='button addemailformbtn'>ADD</button>
                        </div>


                        <button type='button' className='button' onClick={() => setpopup(true)}>ADD Practitioners Emails</button>
                        <button type='button' className='button' onClick={() => setpopup2(true)}>ADD Webinar Participants Emails</button>


                      </div>
                    </div>
                  </form>

                  <form onSubmit={sendEmail}>
                    <div className='headeremails'>
                      <div className='tosend'>
                        <h5 style={{ color: "#6E4E9F" }}> To: </h5>
                        <div className='alltosendemails'>
                          {tosendemail.map((value, index2) => (<>
                            <p>{value} <ImCross onClick={() => {
                              settosendemail((prevArray) => prevArray.filter((_, index) => index !== index2)); // Remove the correct index

                              setnottosend(prev => [...prev, value]);

                            }} style={{ color: 'red', cursor: 'pointer' }} /> </p>
                          </>))}

                        </div>
                      </div>
                      {tosendemail.length > 0 && <>
                        <a href='' style={{ display: 'flex' }} onClick={(e) => {
                          e.preventDefault()
                          settosendemail([])
                        }}>Clear All {tosendemail.length}</a>
                      </>}
                      <div className="contantdiv" style={{
                        color: '#6E4E9F',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '0',
                        alignItems: 'center',
                        padding: '0'
                      }}>
                        {/* Send One by One */}
                        <input
                          checked={!allinone}
                          id="trackemailllllll"
                          name="sendemail"
                          type="radio"
                          className="formalinput"
                          style={{
                            borderRadius: "10px",
                            border: "1px solid #6E4E9F",
                            color: "#6E4E9F",
                            width: '20px',
                            height: '20px'
                          }}
                          onChange={() => setallinone(false)}
                        />
                        <label htmlFor="trackemailllllll">One by One</label>

                        {/* All in One Email */}
                        <input
                          checked={allinone}
                          id="saveemaillllll"
                          name="sendemail"
                          type="radio"
                          className="formalinput"
                          style={{
                            borderRadius: "10px",
                            marginLeft: "50px",
                            border: "1px solid #6E4E9F",
                            color: "#6E4E9F",
                            width: '20px',
                            height: '20px'
                          }}
                          onChange={() => setallinone(true)}
                        />
                        <label htmlFor="saveemaillllll">ALL in One Email</label>


                      </div>


                      <div className='tosend'>
                        <h5 style={{ color: "#6E4E9F" }}>NOT TO Send  <h2>{nottosend.length}</h2></h5>
                        <div className='alltosendemails'>
                          {nottosend.map((value, idx) => (
                            <p key={idx}>
                              {value}
                              <ImCross
                                onClick={() => {
                                  settosendemail((prev) => {
                                    if (!prev.includes(value)) {
                                      return [...prev, value]; // ✅ Add only if value is not present
                                    }
                                    return prev; // ✅ Return previous state if value already exists
                                  });
                                  setnottosend((prevArray) => prevArray.filter((_, index) => index !== idx)); // Remove the correct index
                                }}
                                style={{ color: 'red', cursor: 'pointer' }}
                              />
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>


                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                      <h3>From:</h3>
                      {/* <input required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={to} onChange={(e) => setto(e.target.value)} /> */}
                      <select required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", width: "32%", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={from} onChange={(e) => setfrom(e.target.value)}>
                        <option selected value={"lab@yourgutmap.co.uk"}>lab@yourgutmap.co.uk</option>
                        <option value={"info@yourgutmap.co.uk"}>info@yourgutmap.co.uk</option>
                        {loginemail !== "josh@yourgutmap.co.uk" && <> <option value={"josh@yourgutmap.co.uk"}>josh@yourgutmap.co.uk</option></>}
                        {loginemail !== "dominic@yourgutmap.co.uk" && <> <option value={"dominic@yourgutmap.co.uk"}>dominic@yourgutmap.co.uk</option></>}
                      </select>

                      <p style={{ padding: '0', margin: '0', fontSize: '0.8em' }}><strong>NOTE:</strong> This email ({from}) will be used to send email.  </p>
                    </div>


                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                      <h3>Email Subject:</h3>
                      <input required className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={subject} onChange={(e) => setsubject(e.target.value)} />

                    </div>



                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                      <h3>Template:</h3>
                      {/* <input required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={to} onChange={(e) => setto(e.target.value)} /> */}
                      <select
                        onChange={(e) => {
                          const selectedTemplate = Template.find((template) => template._id === e.target.value);

                          if (selectedTemplate) {
                            setContent(selectedTemplate.content);
                            setsubject(selectedTemplate.subject);
                            console.log(selectedTemplate.content);
                          }
                        }}
                        className="formalinput"
                        placeholder="Enter Email"
                        style={{
                          borderRadius: "10px",
                          width: "32%",
                          border: "1px solid #6E4E9F",
                          color: "#6E4E9F",
                        }}
                      >
                        <option value="" selected disabled>
                          Please Select
                        </option>

                        {Template.map((value, index) => (
                          <option key={value._id} value={value._id}>
                            {value.name}
                          </option>
                        ))}
                      </select>


                      <p style={{ padding: '0', margin: '0', fontSize: '0.8em' }}><strong>NOTE:</strong> The <strong>subject</strong> and <strong>content</strong> of Template will appear in the editor, allowing for easy modifications and updates as needed.  </p>
                    </div>

                    <div >

                      <div className='contantdiv' style={{ color: '#6E4E9F' }}>



                        <h3>Email Content:</h3>
                        <div  >
                          <ReactQuill

                            ref={quillRef}
                            value={content}
                            onChange={setContent}
                            modules={modules}
                            formats={formats}
                            placeholder="Write your email here..."
                          />
                        </div>
                      </div>
                    </div>


                    {/* <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px", display: 'flex', flexDirection: 'row !important', gap: '0', alignItems: 'center' }}>
                      <input checked={trackemail} id='trackemail' type='checkbox' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => settrackemail(e.target.checked)} />

                      <label for="trackemail">Track this Email</label >


                      <input checked={saveemail} id='saveemail' type='checkbox' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", marginLeft: "50px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => setsaveemail(e.target.checked)} />

                      <label for="saveemail">Save This Mail information in database</label >

                    </div> */}



                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px", display: 'flex', flexDirection: 'row !important', gap: '0', alignItems: 'center' }}>


                      <input name='style' checked={!emailstyles} id='YGMBrandedEmailStyle' type='Radio' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => setemailstyles(false)} />

                      <label for="YGMBrandedEmailStyle">YGM Branded Email Style</label >

                      <input name='style' checked={emailstyles} id='SimpleGMailStyle' type='Radio' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", marginLeft: "50px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => setemailstyles(true)} />

                      <label for="SimpleGMailStyle">Simple G-Mail Style </label >


                    </div>
                    <div className='contantdiv' style={{ marginBottom: "10px" }}>
                      <button type='submit' className='button' style={{ color: "#dcd2fc", padding: "7px 25px", borderRadius: "6px", background: "#6e4f9f" }}>SEND EMAIL</button>
                    </div>
                  </form>
                </>)}
              </div>



            </div>


            {popup === true && <>
              <div onClick={() => {
                setpopup(false)
                setCheckedList([]);
              }
              } className='popupbg'></div>
              <div className='popup'>



                <div className='header' >
                  <h2>Select pracstates to Send Emails</h2>
                </div>
                <div>
                  <div className='imp' style={{ maxHeight: '300px' }}>
                    <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                      <thead className='tablephead' style={{ zIndex: '10' }}>
                        <tr>
                          <th>

                            <Checkbox
                              indeterminate={indeterminate}
                              onChange={onCheckAllChange}


                            >

                            </Checkbox>

                            {/* <input indeterminate={indeterminate} checked={checkAll} type='checkbox' /> */}
                          </th>

                          <th>Name</th>



                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {practitioner.map((value, index) => (<>
                          <tr key={value.email}> {/* ✅ Add unique key */}
                            <td>

                              {/* <Checkbox

                                checked={checkedList.includes(value.email)}
                                id={value.email}
                                value={value.email}
                                onChange={onChange}


                              /> */}
                              <input id={value.email} onChange={onChange} value={value.email} checked={checkedList.includes(value.email)} type='checkbox' />

                            </td>

                            <td><label for={value.email}> {value.name} </label></td> {/* ✅ Removed 'for' (not valid in <td>) */}
                            <td><label for={value.email}> {value.email}  </label></td>
                          </tr>
                        </>))}




                      </tbody>
                    </table>
                  </div>
                  <div className="bownpopupbutton">
                    <button
                      onClick={() => {
                        setpopup(false);
                        setCheckedList([]);
                      }}
                      style={{ border: "1px solid red", color: "red" }}
                    >
                      CANCEL
                    </button>


                    <button
                      className='button'
                      onClick={() => {
                        // ✅ Add new emails without duplicates
                        settosendemail((prev) => {
                          const updatedList = [...prev, ...checkedList.filter(email => !prev.includes(email))];
                          return updatedList;
                        });

                        // ✅ Clear the checked list
                        setCheckedList([]);

                        // ✅ Close the popup
                        setpopup(false);
                      }}

                    // style={{ backgroundColor: "#4180b7" }}
                    >
                      ADD
                    </button>


                  </div>
                </div>
              </div></>}






            {popup2 === true && <>
              <div onClick={() => {
                setpopup2(false)
                setCheckedList2([]);
              }
              } className='popupbg'></div>
              <div className='popup'>



                <div className='header' >
                  <h2>Select Event to Add Emails</h2>
                </div>
                <div>

                  <div className="questionboxnew">
                    {event.map((value, index) => {
                      return (
                        <>
                          {value.status !== true && (
                            <>
                              <div className={selectedevent === value ? ("questionnew selectedevent") : ("questionnew")} style={{ cursor: "pointer" }} onClick={() => setselectedevent(value)}>
                                <div className="questiontitle">
                                  <h4 style={{ display: "flex", margin: "0" }}>
                                    {/* <h4 className="" style={{ width: "30px" }}> </h4>{" "} */}
                                    {value.title}
                                  </h4>
                                </div>
                                <div className="date">

                                  <h5 style={{ width: "30px" }}></h5> Date:{" "}
                                  {value.Schedule}

                                </div>

                                {/* <h5 style={{color: '#6E4E9F', display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}></h4> Descriptions</h5> */}

                                {/* <div className="about">
                                  <p
                                    style={{ display: "flex", textAlign: "left" }}
                                  >
                                    <h4 style={{ width: "0px" }}></h4>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: value.About,
                                      }}
                                    />
                                  </p>
                                </div> */}


                                <div className="dltbtndivnew">
                                  <h3 className="participants">Total Participants: {value.userCount}</h3>
                                  {" "}

                                </div>
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>

                  <div className="bownpopupbutton">
                    <button
                      onClick={() => {
                        setpopup(false);

                      }}
                      style={{ border: "1px solid red", color: "red" }}
                    >
                      CANCEL
                    </button>


                    <button
                      className="button"
                      onClick={() => {
                        // ✅ Add new emails without duplicates
                        settosendemail((prev) => {
                          const newEmails = selectedevent.user
                            .map((ur) => ur.Email) // Extract emails from user objects
                            .filter((email) => !prev.includes(email)); // Filter out duplicates

                          return [...prev, ...newEmails]; // Update state with unique emails
                        });

                        // ✅ Clear the checked list (if needed)
                        // setCheckedUsers([]);

                        // ✅ Close the popup
                        setpopup2(false);
                      }}
                    >
                      ADD
                    </button>



                  </div>
                </div>
              </div></>}



          </div>



        </> : <NotLogin />}
    </div>
  )
}

export default Massemail
