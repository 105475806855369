import React, { useState, useEffect, useRef } from "react";
import Navbar from "../components/navbar";
import Sidemanu from "../components/sidemanu";
import { Checkbox, Drawer, Menu, Slider } from "antd";
import { MdClose } from "react-icons/md";
import { DatePicker, Space } from "antd";
import { BiSolidAddToQueue } from "react-icons/bi";
import JSZip from "jszip";
import JsBarcode from "jsbarcode";
import { saveAs } from "file-saver";

import {
  AiFillDelete,
  AiOutlineMail,
  AiTwotoneInteraction,
} from "react-icons/ai";
import {
  MdAssignmentReturn,
  MdAttachEmail,
  MdEditSquare,
  MdOutlinePayment,
  MdOutlinePayments,
  MdOutlinePendingActions,
} from "react-icons/md";
import { loadStripe } from "@stripe/stripe-js";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import Cookies from "js-cookie";
import {
  AutoComplete,
  Button,
  Dropdown,
  Input,
  message,
  Popconfirm,
} from "antd";
import Papa from "papaparse";
import { FaKitMedical } from "react-icons/fa6";
import { GiFirstAidKit } from "react-icons/gi";
import { ImUpload2, ImUserTie } from "react-icons/im";
import { TbGitBranchDeleted, TbReportMoney } from "react-icons/tb";

import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { BsFiletypeAac } from "react-icons/bs";
import NotLogin from "./notLogin";
import { Select, Tag } from "antd";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";

const KITS = (params) => {







  const backgroundcolor = Cookies.get("backgroundcolor") || params.backgroundcolor;
  const color = Cookies.get("color") || params.color;
  const Buttonbackgroundcolor = Cookies.get("Buttonbackgroundcolor") || params.Buttonbackgroundcolor;
  const Buttoninsidecolor = Cookies.get("Buttoninsidecolor") || params.Buttoninsidecolor;
  const prinarycolor = Cookies.get("prinarycolor") || params.prinarycolor;
  const prinarycolortext = Cookies.get("prinarycolortext") || params.prinarycolortext;
  const seconderycolor = Cookies.get("seconderycolor") || params.seconderycolor;
  const portalid = Cookies.get("portalid") || params.portalid;






  const filterStatus = [
    {
      value: "Kit Created",
    },
    {
      value: "Kit Not received in lab yet",
    },
    {
      value: "KIT Received in Lab, Awaiting Results",
    },
    {
      value: "Results Published",
    },
  ];
  const filterKitType = [
    {
      value: "Prepaid",
    },
    {
      value: "Consignment",
    },
    {
      value: "Prepaid Invoice",
    },
    {
      value: "Consignment Invoice",
    },
    {
      value: "Consignment Kit",
    },
    {
      value: "Consignment Replacement",
    },
    {
      value: "Prepaid Replacement",
    },
    {
      value: "Prepaid Retail",
    },
  ];

  const [filterPracNames, setFilterPracNames] = useState([]);
  const [filterPrice, setFilterPrice] = useState([]);

  const allStatus = [
    "Kit Created",
    "Kit Not received in lab yet",
    "KIT Received in Lab, Awaiting Results",
    "Results Published",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [kitTypeExplicit, setkitTypeExplicit] = useState("Prepaid");
  const [selectedPrac, setSelectedPrac] = useState([]); // State to manage selected values
  const [selectedPrice, setSelectedPrice] = useState([]); // State to manage selected values
  const [selectedStatus, setSelectedStatus] = useState([]); // State to manage selected values
  const [selectedKitType, setSelectedKitType] = useState([]); // State to manage selected values
  const [maxPrice, setMaxPrice] = useState([]); // State to manage selected values

  const { RangePicker } = DatePicker;

  const handleClearPracName = () => {
    setSelectedPrac([]); // Clear selected values on delete icon click
  };
  const handleClearPrice = () => {
    // setSelectedPrice([]); // Clear selected values on delete icon click
    setFilterPrice([]); // Clear selected values on delete icon click
  };
  const handleClearStatus = () => {
    setSelectedStatus([]); // Clear selected values on delete icon click
  };
  const handleClearKittype = () => {
    setSelectedKitType([]); // Clear selected values on delete icon click
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        // color={value}
        color="#6e4e9f"
        // color= {(`#${Math.floor(Math.random() * 16777215).toString(16)}`)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          // marginInlineEnd: 4,
          margin: "4px", // Space between tags
          padding: "4px 8px", // Inner padding inside each tag
          display: "flex",
          alignItems: "center",
        }}
      >
        {label}
      </Tag>
    );
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleKitTypeChange = async (e) => {

    setkitTypeExplicit(e); // Update state

  };

  const itemsfortype1 = [
    { key: "1", label: "Prepaid" },
    { key: "2", label: "Consignment" },
    { key: "3", label: "Prepaid Invoice" },
    { key: "4", label: "Consignment Invoice" },
    { key: "5", label: "Consignment Kit" },
    { key: "6", label: "Consignment Replacement" },
    { key: "7", label: "Prepaid Replacement" },
    { key: "8", label: "Prepaid Retail" },
  ];

  const fileInputRef = useRef(null);
  const loginemail = Cookies.get("email");
  const loginname = Cookies.get("Name");
  const loginid = Cookies.get("id");
  const token = Cookies.get("Token");
  const token2 = Cookies.get("Token2");
  const apiurlforbackend = process.env.REACT_APP_APIURL;
  const [searchParams] = useSearchParams();

  const pageinurl = searchParams.get("page");
  const search = searchParams.get("search");

  const navigate = useNavigate();
  const [kit, setkit] = useState([]);
  const [kit2, setkit2] = useState([]);

  const [kitids, setkitids] = useState([]);
  const [page, setpage] = useState(1);
  const [page2, setpage2] = useState(1);

  const [totalPages, settotalPages] = useState(1);
  const [totalPages2, settotalPages2] = useState(1);

  const [practitioner, setpractitioner] = useState([]);
  const [practitioner2, setpractitioner2] = useState([]);

  const [practitionertoassign, setpractitionertoassign] = useState([]);
  const [practitionertoassign2, setpractitionertoassign2] = useState([]);

  const [kitIDtoassign, setkitIDtoassign] = useState([]);

  const [practitionertoassignID, setpractitionertoassignID] = useState("");
  const [assignall, setassignall] = useState(false);
  const type = `${params.kittype}`;

  const [popup, setpopup] = useState(false);
  const [popup_2, setpopup_2] = useState(false);
  const [popup2, setpopup2] = useState(false);
  const [popup3, setpopup3] = useState(false);
  const [addkit2, setaddkit2] = useState("");
  const [addkit2p, setaddkit2p] = useState("");
  const [addkit, setaddkit] = useState([]);
  const [kitdetails, setkitdetails] = useState([]);
  const [totalKits, setTotalKits] = useState(0);

  const [isPatietnEditing, setIsPaientEditing] = useState(false);
  const [patientInputValue, setPatientInputValue] = useState("");
  const [patientName, setPatietName] = useState("Please enter patient name");
  const [filterDates, setFilterDates] = useState([null, null]);

  const handleDateChange = (value) => {
    setFilterDates(value); // value is an array with [startDate, endDate]
  };
  const handlePriceChange = (value) => {
    setFilterPrice(value); // value is an array with [startDate, endDate]
  };

  const setkitbyres = async (response) => {
    setkit(response.data.data);
    setkit2(response.data.data);
    setpractitioner(response.data.practitioner);
    setpractitioner2(response.data.practitioner);
    setpage(response.data["current page"]);
    setpage2(response.data["current page"]);
    settotalPages(response.data["total pages"]);
    settotalPages2(response.data["total pages"]);
    setTotalKits(response.data["total kits"]);
  };

  const getkits = async (page) => {
    let tokentosend;
    if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
      tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53";
    } else {
      tokentosend = token;
    }

    let data = JSON.stringify({
      pagenumber: page,
      type: type,
      token: tokentosend,
      loginid: loginid,
      portalid: portalid,

      selectedKitType,
      selectedStatus,
      filterDates,
      filterPrice,
      selectedPrac

    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/getkitsbytype",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setkitbyres(response);

        // setFilterPrice(response.data.data.map((user) => ({ value: user.Kitprice2 })));
        // setFilterPrice(
        //   [...new Set(response.data.data.map((user) => user.Kitprice2))].map(
        //     (uniquePrice) => ({ value: uniquePrice })
        //   )
        // );
        const prices = response.data.data.map((user) =>
          parseFloat(user.Kitprice2)
        ); // Convert prices to numbers
        const mymaxPrice = Math.max(...prices); // Find the maximum price

        setMaxPrice(mymaxPrice);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updatePatientName = async () => {
    // e.preventDefault();
    let tokentosend;
    if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
      tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53";
    } else {
      tokentosend = token;
    }

    let data = JSON.stringify({
      pagenumber: page,
      type: type,
      token: tokentosend,
      loginid: loginid,
      newname: patientInputValue,
      id: kitdetails._id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/updatepat",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then(async (response) => {
        // setkitbyres(response);
        await getkits(page);
        await setkitdetails(response.data.message);


        const prices = response.data.data.map((user) =>
          parseFloat(user.Kitprice2)
        );
        const mymaxPrice = Math.max(...prices);

        setMaxPrice(mymaxPrice);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const getkitsids = async () => {
    let tokentosend;
    if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
      tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53";
    } else {
      tokentosend = token;
    }
    let data = JSON.stringify({
      type: type,
      token: tokentosend,
      loginid: loginid,
      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/getallkitids",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setkitids(response.data.kitID);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getpractoassign = async () => {
    let data = JSON.stringify({

      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/getpracinfotoassign",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setpractitionertoassign(response.data);
        setpractitionertoassign2(response.data);
        // setFilterPracNames(response.data.map((user) => ({ value: user.name })));
        setFilterPracNames((prevData) => [
          ...prevData,
          ...response.data.map((user) => ({ value: user.email })),
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getkits(page);
    getkitsids();
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      getpractoassign();
    }
  }, []);

  useEffect(() => {
    setPatietName(kitdetails.patientName);
  }, [kitdetails])

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
    const startPage = Math.max(1, page - maxPageToShow);
    const endPage = Math.min(totalPages, page + maxPageToShow);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => handlePageClick(i)}
          className={page === i ? "activeeee" : ""}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  const handlePageClick = async (pageNumber) => {
    await setkit([]);
    await window.scrollTo(0, 0);
    await getkits(pageNumber);

    await renderPageNumbers();
  };

  const Addkit = async (e) => {
    await e.preventDefault();

    await setaddkit([
      ...addkit,
      {
        Kit: addkit2,
        Kitprice: addkit2p,
        Kittype: type,
        kittypecon: kitTypeExplicit,
      },
    ]);
    setaddkit2("");
    setaddkit2p("");
  };

  const RemoveItemByIndex = (index) => {
    const updatedAddkit = addkit.filter((_, i) => i !== index);
    setaddkit(updatedAddkit);
  };





  const messagesEndRef = useRef(null); // Reference to the bottom of the message list

  const [Message, setMessages] = useState([]);
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [Message]);


  const sendkittobackend = async () => {
    await setpopup3(false);
    await setloading(true)
    await setloading2(true)
    await setMessages((prevMessages) => [...prevMessages, `<p style="color:${prinarycolor};">Processing ${addkit.length} KITS </p>`]);

    for (const kitItem of addkit) {
      let data = JSON.stringify({
        type: type,
        pagenumber: page,
        KITDATA: kitItem,
        assignto: practitionertoassignID,
        assignall: assignall,
        portalid: portalid,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: apiurlforbackend + "/addkit",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then(async (response) => {
          // setkitbyres(response);
          getkits(page);
          await setMessages((prevMessages) => [...prevMessages, response.data.message]);
        })
        .catch((error) => {
          console.log(error);
        });


    }
    setloading(false)
  }

  const assignkittopra = async () => {
    const hide = message.loading("Action in progress", 0);

    let data = JSON.stringify({
      type: type,
      pagenumber: page,
      kitid: kitdetails._id,
      kitID: kitIDtoassign._id,
      userid: practitionertoassignID,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/assignkittoprac",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        // setkitbyres(response);
        getkits(page);
        setpopup(false);
        setpopup2(false);
        setpopup3(false);
      })
      .catch((error) => {
        console.log(error);
      });

    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  var options = kitids;

  var sno1 = 1;
  var sno2 = 1 + 15 * (page - 1);
  var sno3 = 1 + 15 * (page - 1);

  const [searchTerm2, setSearchTerm2] = useState("");
  var options2 = [];

  const dooption2 = () => {
    practitionertoassign2.map((value, index) => {
      options2.push({ value: value.name });
      options2.push({ value: value.email });
    });
  };

  const handleInputChange2 = (e) => {
    e.preventDefault();

    const intttt = searchTerm2;

    const filteredSuggestions = practitionertoassign.filter(
      (item) =>
        item.name.toLowerCase().includes(intttt.toLowerCase()) ||
        item.email.toLowerCase().includes(intttt.toLowerCase())
    );

    setpractitionertoassign(filteredSuggestions);

    setsearchdone2(true);
  };

  const [searchdone2, setsearchdone2] = useState(false);

  const clearsearch2 = () => {
    setpractitionertoassign(practitionertoassign2);

    setsearchdone2(false);
  };

  const findpracemail = () => {
    var emailll;
    practitioner.map((value) => {
      if (value._id === kitdetails.assignedto) {
        emailll = value.email;
      }
    });
    if (token !== "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      emailll = loginemail;
    }

    return emailll;
  };

  const findpracname = () => {
    var nameee;
    practitioner.map((value) => {
      if (value._id === kitdetails.assignedto) {
        nameee = value.name;
      }
    });

    if (token !== "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      nameee = loginname;
    }
    return nameee;
  };

  const kitdetailsKitprice = () => {
    var paymenttt;
    if (kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf") {
      paymenttt = kitdetails.Kitprice2;
    } else {
      paymenttt = kitdetails.Kitprice2;
    }

    return paymenttt;
  };

  const kitdetailsKitpricestatus = () => {
    var paymenttt;
    if (kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf") {
      paymenttt = "Payment Completed";
    } else {
      paymenttt = "Payment Pending";
    }

    return paymenttt;
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async () => {
            const stripe = await loadStripe(
              "pk_live_51MsenqJSzdsymN5jGOTIP3q4qBmD4PDra9chWFQYDC6RCchx2jLlIgdDRrUnhI24QhZeNeAqEo9tc6l3oiR4SWc3000yjqG8qW"
            );

            var myHeaders = new Headers();
            myHeaders.append(
              "Content-Type",
              "application/x-www-form-urlencoded"
            );

            var urlencoded = new URLSearchParams();
            urlencoded.append("KitID", kitdetails._id);

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: urlencoded,
              redirect: "follow",
            };

            const response = await fetch(
              "https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/create-checkout-session",
              requestOptions
            );

            const session = await response.json();

            const result = stripe.redirectToCheckout({
              sessionId: session.id,
            });

            if (result.error) {
              console.log(result.error);
            }
          }}
        >
          Pay The Ammount Of Kit
        </a>
      ),
      icon: (
        <MdOutlinePayment
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/kitpricepaymentdoneover",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                // setkitbyres(response);
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Mark as paid
        </a>
      ),

      disabled: kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf",
      icon: (
        <MdOutlinePayment
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
    {
      key: "3",
      label: (
        <a target="_blank" rel="noopener noreferrer">
          Payment is Due
        </a>
      ),

      disabled: true,
      icon: (
        <AiOutlineMail
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
  ];

  const items1 = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async () => {
            const hide = message.loading("Action in progress", 0);
            const stripe = await loadStripe(
              "pk_live_51MsenqJSzdsymN5jGOTIP3q4qBmD4PDra9chWFQYDC6RCchx2jLlIgdDRrUnhI24QhZeNeAqEo9tc6l3oiR4SWc3000yjqG8qW"
            );

            var myHeaders = new Headers();
            myHeaders.append(
              "Content-Type",
              "application/x-www-form-urlencoded"
            );

            var urlencoded = new URLSearchParams();
            urlencoded.append("KitID", kitdetails._id);

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: urlencoded,
              redirect: "follow",
            };

            const response = await fetch(
              "https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/create-checkout-session",
              requestOptions
            );

            const session = await response.json();

            const result = stripe.redirectToCheckout({
              sessionId: session.id,
            });

            if (result.error) {
              console.log(result.error);
            }

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Pay The Ammount Of Kit
        </a>
      ),
      disabled: kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf",
      icon: (
        <MdOutlinePayment
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
  ];

  const items2 = [
    {
      key: "1",
      label: (
        <a target="_blank" rel="noopener noreferrer">
          Pay The Ammount Of Kit
        </a>
      ),
      disabled: true,
      icon: (
        <MdOutlinePayment
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/kitpricepaymentdoneover",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Mark as paid
        </a>
      ),
      disabled: kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf",

      icon: (
        <MdOutlinePayment
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();
            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              emailtype: "payment ack",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/sendemailtoprac",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                // setkitbyres(response);
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Send Payment Acknowledgment
        </a>
      ),

      disabled: kitdetails.ackpayment,
      icon: (
        <AiOutlineMail
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
  ];

  const itemsstatus1 = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              emailtype: "kit ack",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/sendemailtoprac",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Send Kit Received Acknowledgment
        </a>
      ),
      disabled: kitdetails.ack,
      icon: (
        <AiOutlineMail
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              emailtype: "payment ack and kit ack",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/sendemailtoprac",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Send Kit Received Acknowledgment with Payment information
        </a>
      ),
      disabled: kitdetails.ack && kitdetails.ackpayment,

      icon: (
        <AiOutlineMail
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
    {
      key: "3",
      label: (
        <a target="_blank" rel="noopener noreferrer">
          Results are Pending
        </a>
      ),

      disabled: true,
      icon: (
        <AiOutlineMail
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },

    {
      key: "4",
      label: (
        <Popconfirm
          title="Delete the KIT"
          description="Are you sure to delete this KIT?"
          onConfirm={async () => {
            let data = JSON.stringify({
              type: type,
              pagenumber: page,
              kitid: kitdetails._id,
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/dltkitsss",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then(async (response) => {
                // setkitbyres(response);
                await getkits(page);
                await setpopup(false);
                await setpopup2(false);
                await setpopup3(false);
              })
              .catch((error) => {
                console.log(error);
              });
          }}
          okText="Yes"
          cancelText="No"
        >
          <p
            className="hovar"
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px",
              margin: "0px",
            }}
          >
            Delete This Kit
          </p>
        </Popconfirm>
      ),
      icon: (
        <AiFillDelete style={{ width: "20px", height: "20px", color: "red" }} />
      ),
    },
  ];
  const itemsstatusfordelete = [
    {
      key: "4",
      label: (
        <Popconfirm
          title="Delete the KIT"
          description="Are you sure to delete this KIT?"
          onConfirm={async () => {
            let data = JSON.stringify({
              type: type,
              pagenumber: page,
              kitid: kitdetails._id,
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/dltkitsss",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                // setkitbyres(response);
                getkits(page);
                setpopup(false);
                setpopup2(false);
                setpopup3(false);
              })
              .catch((error) => {
                console.log(error);
              });
          }}
          okText="Yes"
          cancelText="No"
        >
          <p
            className="hovar"
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px",
              margin: "0px",
            }}
          >
            Delete This Kit
          </p>
        </Popconfirm>
      ),
      icon: (
        <AiFillDelete style={{ width: "20px", height: "20px", color: "red" }} />
      ),
    },
  ];

  const itemsstatus2 = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              emailtype: "kit ack",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/sendemailtoprac",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Send Kit Received Acknowledgment
        </a>
      ),
      disabled: kitdetails.ack,
      icon: (
        <AiOutlineMail
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              emailtype: "payment ack and kit ack",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/sendemailtoprac",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Send Kit Received Acknowledgment with Payment information
        </a>
      ),
      disabled: kitdetails.ack && kitdetails.ackpayment,

      icon: (
        <AiOutlineMail
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              emailtype: "results",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/sendemailtoprac",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Send Results To Practitioner
        </a>
      ),

      disabled: kitdetails.ackresult,
      icon: (
        <AiOutlineMail
          style={{ width: "20px", height: "20px", color: "#4885B9" }}
        />
      ),
    },

    {
      key: "4",
      label: (
        <Popconfirm
          title="Delete the KIT"
          description="Are you sure to delete this KIT?"
          onConfirm={async () => {
            let data = JSON.stringify({
              type: type,
              pagenumber: page,
              kitid: kitdetails._id,
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/dltkitsss",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                // setkitbyres(response);
                getkits(page);
                setpopup(false);
                setpopup2(false);
                setpopup3(false);
              })
              .catch((error) => {
                console.log(error);
              });
          }}
          okText="Yes"
          cancelText="No"
        >
          <p
            className="hovar"
            style={{
              display: "flex",
              // color: "red",
              alignItems: "center",
              padding: "0px",
              margin: "0px",
            }}
          >
            Delete This Kit
          </p>
        </Popconfirm>
      ),
      icon: (
        <AiFillDelete style={{ width: "20px", height: "20px", color: "red" }} />
      ),
    },
  ];

  const itemsfortype = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              typetochange: "Prepaid",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/changekittype",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                // setkitbyres(response);
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Prepaid
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              typetochange: "Consignment",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/changekittype",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Consignment
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              typetochange: "Prepaid Invoice",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/changekittype",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Prepaid Invoice
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              typetochange: "Consignment Invoice",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/changekittype",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Consignment Invoice
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              typetochange: "Consignment Kit",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/changekittype",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Consignment Kit
        </a>
      ),
    },
    {
      key: "6",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              typetochange: "Consignment Replacement",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/changekittype",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Consignment Replacement
        </a>
      ),
    },
    {
      key: "7",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              typetochange: "Prepaid Replacement",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/changekittype",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Prepaid Replacement
        </a>
      ),
    },
    {
      key: "8",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault();

            const hide = message.loading("Action in progress", 0);

            let data = JSON.stringify({
              pagenumber: page,
              type: type,
              id: kitdetails._id,
              typetochange: "Prepaid Retail",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: apiurlforbackend + "/changekittype",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            await axios
              .request(config)
              .then((response) => {
                getkits(page);
                setpopup2(false);
              })
              .catch((error) => {
                console.log(error);
              });

            await setTimeout(() => {
              hide(); // Call hide to stop the loading message
              message.success("Action completed successfully");
            }, 2000);
          }}
        >
          Prepaid Retail
        </a>
      ),
    },
  ];

  const handleInputChange = async (e) => {
    e.preventDefault();

    setkit([]);
    let tokentosend;
    if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
      tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53";
    } else {
      tokentosend = token;
    }
    let data = JSON.stringify({
      type: type,
      search: searchTerm,
      token: tokentosend,
      loginid: loginid,
      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/searchkits",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then(async (response) => {
        await setkit(response.data.data);
        await setpractitioner(response.data.practitioners);
        await setpage(1);
        await settotalPages(response.data.totalPages);
        await renderPageNumbers();
      })
      .catch((error) => {
        console.log(error);
      });

    setsearchdone(true);
  };

  const [searchdone, setsearchdone] = useState(false);

  const clearsearch = async () => {
    await setkit(kit2);
    await setpractitioner(practitioner2);
    await setpage(page2);
    await settotalPages(totalPages2);
    await renderPageNumbers();

    setsearchdone(false);
  };

  const [csvFile, setCSVFile] = useState(null);
  const [csvData, setCsvData] = useState([]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    setCSVFile(file); // Set the uploaded file state

    Papa.parse(file, {
      complete: async (result) => {
        const parsedData = result.data;
        setCsvData(parsedData); // Set the parsed data state

        // Prepare the new kit data
        const newKits = parsedData.map((value) => ({
          Kit: value["Kit Code"],
          Kitprice: 0,
          Kittype: type,
          kittypecon: kitTypeExplicit,
        }));

        // Update the state with the new kits
        setaddkit((prevAddKit) => [...prevAddKit, ...newKits]);

        const date = new Date().toISOString().replace(/:/g, "-");

        const csvContent =
          "Kit ID,Kit Price,Kit Type\n" +
          newKits.map((kit) => `${kit.Kit},${kit.Kitprice},${kit.Kittype}`).join("\n");

        // Create ZIP file
        const zip = new JSZip();
        zip.file(`generated_kits ${date}.csv`, csvContent); // Add CSV file

        const barcodeFolder = zip.folder("Barcodes"); // Create 'Barcodes' folder inside ZIP

        // Generate barcodes and store them in ZIP
        const generateBarcodesAndDownload = async () => {
          const promises = newKits.map(async (kit) => {
            return new Promise((resolve) => {
              const canvas = document.createElement("canvas");

              JsBarcode(canvas, kit.Kit, {
                format: "CODE128",
                displayValue: true,
                width: 4, // Thicker bars for high-res
                height: 200,
              });

              canvas.toBlob((blob) => {
                barcodeFolder.file(`${kit.Kit}.jpg`, blob);
                resolve();
              }, "image/jpeg");
            });
          });

          await Promise.all(promises);

          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `kits_with_barcodes ${date}.zip`);
          });
        };

        await generateBarcodesAndDownload();
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === "text/csv") {
      setCSVFile(droppedFile);
      Papa.parse(droppedFile, {
        complete: async (result) => {
          const parsedData = result.data;
          setCsvData(parsedData); // Set the parsed data state

          // Prepare the new kit data
          const newKits = parsedData.map((value) => ({
            Kit: value["Kit Code"],
            Kitprice: 0,
            Kittype: type,
            kittypecon: kitTypeExplicit,
          }));

          // Update the state with the new kits
          setaddkit((prevAddKit) => [...prevAddKit, ...newKits]);

          const date = new Date().toISOString().replace(/:/g, "-");

          const csvContent =
            "Kit ID,Kit Price,Kit Type\n" +
            newKits.map((kit) => `${kit.Kit},${kit.Kitprice},${kit.Kittype}`).join("\n");

          // Create ZIP file
          const zip = new JSZip();
          zip.file(`generated_kits ${date}.csv`, csvContent); // Add CSV file

          const barcodeFolder = zip.folder("Barcodes"); // Create 'Barcodes' folder inside ZIP

          // Generate barcodes and store them in ZIP
          const generateBarcodesAndDownload = async () => {
            const promises = newKits.map(async (kit) => {
              return new Promise((resolve) => {
                const canvas = document.createElement("canvas");

                JsBarcode(canvas, kit.Kit, {
                  format: "CODE128",
                  displayValue: true,
                  width: 4, // Thicker bars for high-res
                  height: 200,
                });

                canvas.toBlob((blob) => {
                  barcodeFolder.file(`${kit.Kit}.jpg`, blob);
                  resolve();
                }, "image/jpeg");
              });
            });

            await Promise.all(promises);

            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, `kits_with_barcodes ${date}.zip`);
            });
          };

          await generateBarcodesAndDownload();
        },
        header: true,
        skipEmptyLines: true,
      });
    } else {
      alert("Please drop a valid CSV file.");
    }
  };

  const handleClearInput = () => {
    // setFiles([]); // Clear the files state
    fileInputRef.current.value = ""; // Clear the file input value
    setCSVFile(null);
  };

  const [randomnumber, setrandomnumber] = useState(0)

  return (
    <>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
        token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" ? (
        <>
          <Navbar />
          <div className="deshboardmain" style={{ backgroundColor: backgroundcolor }}>
            <Sidemanu />
            <div className="adminkitmainbody">
              <div className="header">
                <h1
                  style={{
                    color: prinarycolor,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FaKitMedical style={{ width: "35px", height: "35px" }} />
                  {type} Kits
                </h1>
                {searchdone === false && (
                  <>
                    <form onSubmit={handleInputChange}>
                      <AutoComplete
                        style={{ width: 200 }}
                        options={options}
                        placeholder="Search by Kit ID"
                        onChange={(inputValue) => setSearchTerm(inputValue)}
                        filterOption={(inputValue, options) =>
                          options.value
                            .toString()
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                      <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Search</button>
                    </form>
                  </>
                )}
                {searchdone === true && (
                  <>
                    {" "}
                    <div className="clearsearch">
                      <h3>search: {searchTerm}</h3>{" "}
                      <button onClick={clearsearch}>
                        <AiOutlineClose /> Clear
                      </button>{" "}
                    </div>
                  </>
                )}
              </div>

              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                <>
                  {" "}
                  <div className="addbutton">
                    <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}
                      onClick={() => {
                        setpopup(true);
                      }}
                    >
                      <BiSolidAddToQueue
                        style={{ width: "20px", height: "20px" }}
                      />
                      Add Kit
                    </button>
                    <div style={{ marginLeft: "20px" }}>
                      <Button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} type="primary" onClick={showDrawer}>
                        Filter
                      </Button>
                      <Drawer
                        title="Filter Kits"
                        placement="right"
                        onClose={onClose}
                        open={open}
                        closeIcon={
                          <CloseOutlined
                            style={{ color: "white", fontSize: "18px" }}
                          />
                        }
                        styles={{
                          header: {
                            backgroundColor: "#6e4e9f",
                            color: "white",
                          }, // Title background & color
                          body: { backgroundColor: "#f8f9fa" }, // Drawer body background
                        }}
                      >
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h2 style={{ color: "#6e4e9f" }}>Practitioner</h2>
                          {/* <CloseOutlined  /> */}
                          <DeleteOutlined
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginRight: "5px",
                            }}
                            onClick={handleClearPracName}
                          />
                        </div>

                        <Select
                          mode="multiple"
                          tagRender={tagRender}
                          value={selectedPrac}
                          onChange={setSelectedPrac}
                          options={filterPracNames}
                          style={{
                            width: "100%",
                          }}
                        />
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "20px",
                          }}
                        >
                          <h2 style={{ color: "#6e4e9f" }}>Status</h2>
                          {/* <CloseOutlined  /> */}
                          <DeleteOutlined
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginRight: "5px",
                            }}
                            onClick={handleClearStatus}
                          />
                        </div>
                        <Select
                          mode="multiple"
                          tagRender={tagRender}
                          value={selectedStatus}
                          onChange={setSelectedStatus}
                          style={{
                            width: "100%",
                          }}
                          options={filterStatus}
                        />
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "20px",
                          }}
                        >
                          <h2 style={{ color: "#6e4e9f" }}>Kit Types</h2>
                          {/* <CloseOutlined  /> */}
                          <DeleteOutlined
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginRight: "5px",
                            }}
                            onClick={handleClearKittype}
                          />
                        </div>
                        <Select
                          mode="multiple"
                          tagRender={tagRender}
                          value={selectedKitType}
                          onChange={setSelectedKitType}
                          style={{
                            width: "100%",
                          }}
                          options={filterKitType}
                        />
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "20px",
                          }}
                        >
                          <h2 style={{ color: "#6e4e9f" }}>Price Range</h2>
                          <DeleteOutlined
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginRight: "5px",
                            }}
                            onClick={handleClearPrice}
                          />
                        </div>
                        <Space
                          direction="vertical"
                          style={{
                            padding: "4px 20px",
                            borderRadius: "6px",
                            width: "90%",
                            background: "#d2cdff",
                          }}
                          size={12}
                        >
                          <Slider
                            range
                            min={0}
                            max={maxPrice !== 0 ? maxPrice : 1000}
                            step={10}
                            defaultValue={filterPrice}
                            onChange={handlePriceChange}
                            value={filterPrice}
                            // tooltipVisible={false}
                            tipFormatter={(value) => `$${value}`} // Format the tooltip as price (optional)
                            style={{
                              background: "#d2cdff",
                              width: "100%",
                              color: "#6e4e9f",
                            }}
                          />
                          {/* <div>
        <p>Price Range: ${filterPrice[0]} - ${filterPrice[1]}</p>
      </div> */}
                        </Space>
                        {/* <Select
                          mode="multiple"
                          tagRender={tagRender}
                          value={selectedPrice}
                          onChange={setSelectedPrice}
                          style={{
                            width: "100%",
                          }}
                          options={filterPrice}
                        /> */}
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "20px",
                          }}
                        >
                          <h2 style={{ color: "#6e4e9f" }}>Kit Registeration Date Range</h2>
                          {/* <DeleteOutlined
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginRight: "5px",
                            }}
                            onClick={handleClearPrice}
                          /> */}
                        </div>
                        <Space direction="vertical" size={12}>
                          <RangePicker
                            style={{
                              background: "#d2cdff",
                              width: "100%",
                              paddingRight: "54px",
                              color: "#6e4e9f",
                            }}
                            onChange={handleDateChange}
                          />
                        </Space>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "40px",
                          }}
                        >
                          <button
                            style={{
                              background: Buttonbackgroundcolor,
                              padding: "7px 90px",
                              borderRadius: "20px",
                              border: "none",
                              color: Buttoninsidecolor,
                              fontSize: "17px",
                            }}

                            onClick={async () => {
                              await onClose()
                              await setkit([])
                              await getkits(1)
                            }}
                          >
                            Filter
                          </button>
                        </div>

                        {/* <Select
                          mode="multiple"
                          tagRender={tagRender}
                          value={selectedPrice}
                          onChange={setSelectedPrice}
                          style={{
                            width: "100%",
                          }}
                          options={filterPrice}
                        /> */}
                        {/* <Checkbox checked={checkedOne} onChange={onChangeOne}>
                          Practitioner Emails
                        </Checkbox>
                        <br />
                        <Checkbox checked={checkedTwo} onChange={onChangeTwo}>
                          Option Two
                        </Checkbox>
                        <br />
                        <Checkbox
                          checked={checkedThree}
                          onChange={onChangeThree}
                        >
                          Option Three
                        </Checkbox> */}
                      </Drawer>
                    </div>
                  </div>
                </>
              )}

              {popup === true && (
                <>

                  <div
                    onClick={() => {
                      setpopup(false);
                      setassignall(false);
                    }}
                    className="popupbg"
                  ></div>

                  <div className="popup">

                    {loading2 ? (<>
                      {/* <img alt='' src='/empty.gif' width={"20%"} /> */}
                      <h3>Logs</h3>
                      <div className='showreportlogs'>
                        {Message.map((value, index) => (<>
                          <div
                            dangerouslySetInnerHTML={{ __html: value }}
                          />



                        </>))}
                        {loading ? (<>
                          <img alt='' src='/empty.gif' width={"5%"} />
                        </>) : (<>
                          <Button onClick={() => {
                            setMessages([])
                            setloading2(false)
                            setaddkit([])
                            setassignall(false);
                          }} danger>Clear</Button >
                        </>)}
                        <div ref={messagesEndRef} />
                      </div>
                    </>) : (<>


                      {popup_2 ? (
                        <>
                          <div
                            className="file-drop-zone"
                            onDrop={handleFileDrop}
                            onDragOver={(e) => e.preventDefault()}
                          >
                            <label className="custom-file-upload">
                              <div className="clickablediv">
                                <ImUpload2
                                  style={{ width: "100px", height: "100px" }}
                                />
                                <h4>Click or drag file to this area to upload</h4>
                              </div>
                              <input
                                multiple="off"
                                ref={fileInputRef}
                                className="inputbuttontouploadfile"
                                type="file"
                                accept=".csv"
                                onChange={handleFileUpload}

                              />
                            </label>
                          </div>


                          {(type === "FoodSensitivityMap" || type === "Parasitology Test" || type === "Oral Microbiome") && <>

                            <form
                              onSubmit={async (e) => {
                                e.preventDefault();
                                const date = Date.now()

                                const existingKits = new Set(kitids.map((kit) => kit.value));

                                const generateUniqueKitId = () => {
                                  let newKitId;
                                  do {

                                    if (type === "FoodSensitivityMap") {
                                      const randomDigits = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
                                      newKitId = `T4-0${randomDigits}-YGM`;

                                    } else if (type === "Parasitology Test") {

                                      const randomDigits = Math.floor(1000000 + Math.random() * 9000000); // 7-digit random number
                                      newKitId = `PGM${randomDigits}`;
                                    } else if (type === "Oral Microbiome") {

                                      const randomDigits = Math.floor(10000 + Math.random() * 90000); // 7-digit random number
                                      newKitId = `OM${randomDigits}`;
                                    }
                                  } while (existingKits.has(newKitId)); // Ensure uniqueness

                                  return newKitId;
                                };

                                const numKits = parseInt(randomnumber);
                                if (isNaN(numKits) || numKits <= 0) {
                                  alert("Please enter a valid number of kits to generate.");
                                  return;
                                }

                                // Generate all kit objects first
                                const newKits = [];
                                for (let i = 0; i < numKits; i++) {
                                  const newKitId = generateUniqueKitId();
                                  newKits.push({
                                    Kit: newKitId,
                                    Kitprice: 0,
                                    Kittype: type,
                                    kittypecon: kitTypeExplicit,
                                  });
                                }

                                // Update state once with all new kits
                                await setaddkit((prevKits) => [...prevKits, ...newKits]);

                                // Convert newKits array to CSV format
                                const csvContent =
                                  "Kit ID,Kit Price,Kit Type\n" +
                                  newKits.map((kit) => `${kit.Kit},${kit.Kitprice},${kit.Kittype}`).join("\n");

                                // Create ZIP file
                                const zip = new JSZip();
                                zip.file(`generated_kits ${date}.csv`, csvContent); // Add CSV file

                                const barcodeFolder = zip.folder("Barcodes"); // Create 'Barcodes' folder inside ZIP

                                // Generate barcodes and store them in ZIP
                                const generateBarcodesAndDownload = async () => {
                                  const promises = newKits.map(async (kit) => {
                                    return new Promise((resolve) => {
                                      const canvas = document.createElement("canvas");

                                      JsBarcode(canvas, kit.Kit, {
                                        format: "CODE128",
                                        displayValue: true,
                                        width: 4, // Thicker bars for high-res
                                        height: 200,
                                      });

                                      canvas.toBlob((blob) => {
                                        barcodeFolder.file(`${kit.Kit}.jpg`, blob);
                                        resolve();
                                      }, "image/jpeg");
                                    });
                                  });

                                  await Promise.all(promises);

                                  zip.generateAsync({ type: "blob" }).then((content) => {
                                    saveAs(content, `kits_with_barcodes ${date}.zip`);
                                  });
                                };

                                await generateBarcodesAndDownload();

                                // Reset form values if needed
                                setrandomnumber(0);
                                setpopup_2(false);
                              }}
                              style={{ marginTop: "10px" }}
                            >
                              <input
                                required
                                value={randomnumber === 0 ? "" : randomnumber}
                                onChange={(e) => setrandomnumber(e.target.value)}
                                type="number"
                                placeholder="Number of kits to Generate"
                              />
                              <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Generate</button>
                            </form>


                          </>}

                          <div className="filename">
                            {" "}
                            {csvFile && (
                              <>
                                {" "}
                                <p>Selected CSV File: {csvFile.name}</p>
                                <AiFillDelete
                                  onClick={handleClearInput}
                                  className="hovar"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    color: "red",
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <form onSubmit={Addkit}>
                        <input
                          onChange={(e) =>
                            setaddkit2(e.target.value.toUpperCase())
                          }
                          value={addkit2}
                          required
                          placeholder="Enter the Kit ID"
                          style={{ width: "fit-content", minWidth: "400px" }}
                        />

                        <input
                          pattern="(\d).{1,10000}"
                          onChange={(e) => setaddkit2p(e.target.value)}
                          value={addkit2p}
                          required
                          placeholder="Enter the Kit Price"
                          style={{ width: "110px" }}
                        />
                        <div className=""></div>
                        {/* {kitdetails.kittypecon}
                              <Dropdown
                                menu={{ items: itemsfortype }}
                                placement="bottomRight"
                                arrow
                              >
                                <IoIosArrowDropdown
                                  className="hovar"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    margin: "0 10px",
                                  }}
                                />
                              </Dropdown> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {/* Display Selected Kit Type */}
                          <div
                            style={{
                              padding: "8px",
                              border: "1px solid #e1e1e1",
                              borderRadius: "5px",
                              backgroundColor: "white",
                              width: "10rem",
                              fontSize: "10px",
                            }}
                          >
                            {/* Selected Kit Type:  */}
                            {kitTypeExplicit}
                          </div>
                          <Dropdown
                            overlay={
                              <Menu>
                                {filterKitType.map((item) => (
                                  <Menu.Item key={item.key} onClick={() => handleKitTypeChange(item.value)}>
                                    {item.value}
                                  </Menu.Item>
                                ))}
                              </Menu>
                            }
                            placement="bottomRight"
                            arrow
                          >
                            <IoIosArrowDropdown
                              className="hovar"
                              style={{
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                            />
                          </Dropdown>
                        </div>

                        <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>ADD</button>

                        {popup_2 ? (
                          <>
                            <Button
                              danger
                              onClick={() => setpopup_2(!popup_2)}
                              style={{
                                margin: "10px",
                                color: "red",
                                background: "none",
                                border: "1px solid red",
                              }}
                              type="button"
                            >
                              Close Upload Area
                            </Button>
                          </>
                        ) : (
                          <>
                            {" "}
                            <button
                              onClick={() => setpopup_2(!popup_2)}
                              style={{ margin: "10px", width: 'fit-content', backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}
                              type="button"

                            >
                              UPLOAD CSV{(type === "FoodSensitivityMap" || type === "Parasitology Test" || type === "Oral Microbiome") && <> or Genrate Random</>}
                            </button>
                          </>
                        )}
                      </form>

                      {addkit.length !== 0 && (
                        <>
                          <table
                            className="tablep"
                            cellPadding={"10"}
                            cellSpacing={"0"}
                          >
                            <thead className="tablephead" style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                              <tr>
                                <th>S NO.</th>
                                <th>Kit ID</th>
                                <th>Kit Price</th>
                                <th>Kit Type</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody style={{ color: "red" }}>
                              {console.log(addkit)}
                              {addkit.map((kit, index) => (
                                <tr>
                                  <td style={{ color: prinarycolor }}>{sno1++}</td>

                                  <td style={{ color: prinarycolor }}>{kit.Kit} </td>
                                  <td style={{ color: prinarycolor }}>&pound;{kit.Kitprice} </td>
                                  <td style={{ color: prinarycolor }}> {kit.kittypecon} </td>
                                  <td className="assignbuttom">
                                    <AiFillDelete
                                      className="hovar"
                                      onClick={() => RemoveItemByIndex(index)}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        color: "red",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </>)}
                    <div className="bownpopupbutton">
                      <button
                        onClick={() => {
                          setpopup(false);
                        }}
                        style={{ border: "1px solid red", color: "red", backgroundColor: 'white' }}
                      >
                        CANCEL
                      </button>
                      {!loading2 && <>
                        <button
                          onClick={() => {
                            if (addkit.length > 0) {
                              setpopup3(true);
                              setassignall(true);
                            } else {
                              message.error("Please Enter KITS to Assign");
                            }
                          }}
                          style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}
                        >
                          ASSIGN ALL
                        </button>

                        <button
                          onClick={sendkittobackend}
                          style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}
                        >
                          SUBMIT
                        </button>
                      </>}
                    </div>
                  </div>
                </>
              )}

              {popup2 === true && (
                <>
                  <div
                    onClick={() => {
                      setpopup2(false);
                    }}
                    className="popupbg"
                  ></div>
                  <div className="popup">
                    <div className="header">
                      <h2>Kits Details</h2>
                    </div>
                    <div className="kitdetailsmain">
                      <div className="kitdetails">
                        <h3
                          style={{
                            display: "flex",
                            color: color,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <GiFirstAidKit
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "0 10px",
                            }}
                          />{" "}
                          KIT ID
                        </h3>
                        <h3
                          style={{
                            display: "flex",
                            color: prinarycolor,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {kitdetails.kitid}{" "}
                        </h3>
                      </div>

                      <div className="kitdetails">
                        <h3
                          style={{
                            display: "flex",
                            color: color,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <FaUser
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "0 10px",
                            }}
                          />{" "}
                          Practitioner Name
                        </h3>
                        <h3
                          style={{
                            display: "flex",
                            color: prinarycolor,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {findpracname()}{" "}
                          {token ===
                            "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                              <>
                                {" "}
                                <MdEditSquare
                                  className="hovar"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    margin: "0 10px",
                                  }}
                                  onClick={() => {
                                    setpopup3(true);

                                    setkitIDtoassign(kitdetails);
                                  }}
                                />
                              </>
                            )}
                        </h3>
                      </div>

                      <div className="kitdetails">
                        <h3
                          style={{
                            display: "flex",
                            color: color,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <MdAttachEmail
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "0 10px",
                            }}
                          />{" "}
                          Practitioner Email
                        </h3>
                        <h3
                          style={{
                            display: "flex",
                            color: prinarycolor,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {findpracemail()}{" "}
                        </h3>
                      </div>

                      <div className="kitdetails">
                        <h3
                          style={{
                            display: "flex",
                            color: color,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <FaUser
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "0 10px",
                            }}
                          />{" "}
                          Patient Name
                        </h3>
                        <h3
                          style={{
                            display: "flex",
                            color: prinarycolor,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {/* {findpracname()}{" "} */}

                          {" "}

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {isPatietnEditing ? (
                              <>

                                {/* <form> */}
                                <input
                                  placeholder="Enter Patient Name"
                                  type="text"
                                  value={patientInputValue}
                                  onChange={(e) =>
                                    setPatientInputValue(e.target.value)
                                  }
                                  style={{
                                    padding: "7px",
                                    fontSize: "14px",
                                    marginRight: "20px",
                                    borderRadius: "5px",
                                    border: "none",
                                    background: "#dcd2fc",
                                    border: "0.5px solid #6e4f9f",
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    // console.log(
                                    //   "Submitted:",
                                    //   patientInputValue
                                    // );

                                    if (patientInputValue === "") {
                                      message.error("Please Enter Patient Name First")
                                    } else {
                                      setIsLoading(true);
                                      updatePatientName()
                                      setIsPaientEditing(false);
                                      setPatientInputValue("");
                                    }
                                  }}
                                  style={{
                                    padding: "5px 10px",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // color:"#dcd2fc",
                                    color: Buttoninsidecolor,
                                    padding: "7px 20px",
                                    borderRadius: "6px",
                                    backgroundColor: Buttonbackgroundcolor,
                                  }}
                                >
                                  Submit

                                </button>

                                {/* </form> */}
                                <MdClose
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    color: "purple",
                                    marginLeft: "5px",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    background: "#dcd2fc",
                                  }}
                                  onClick={() => {
                                    setIsPaientEditing(false);
                                    setPatientInputValue("");
                                  }}
                                />
                              </>
                            ) : (
                              <div
                                style={{
                                  width: "450px",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                {isLoading ? (
                                  <img
                                    alt=""
                                    src="/empty.gif"
                                    width={"7.5%"}
                                    style={{ padding: "0px" }}
                                  />
                                ) : null}

                                {!isLoading ? (kitdetails.patientName ? kitdetails.patientName : <p style={{ color: "red", padding: "0px", margin: "0px" }}>No Patient</p>) : null}

                                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                                  {!isLoading ? (<MdEditSquare
                                    className="hovar"
                                    style={{
                                      background: "",
                                      width: "20px",
                                      height: "20px",
                                      margin: "0 10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setIsPaientEditing(true)}
                                  />) : null}

                                </>}

                              </div>
                            )}
                          </div>


                        </h3>
                      </div>

                      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                        <>
                          <div className="kitdetails">
                            <h3
                              style={{
                                display: "flex",
                                color: color,
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <BsFiletypeAac
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  margin: "0 10px",
                                }}
                              />{" "}
                              KIT Type
                            </h3>
                            <h3
                              style={{
                                display: "flex",
                                color: prinarycolor,
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              {kitdetails.kittypecon}
                              <Dropdown
                                menu={{ items: itemsfortype }}
                                placement="bottomRight"
                                arrow
                              >
                                <IoIosArrowDropdown
                                  className="hovar"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    margin: "0 10px",
                                  }}
                                />
                              </Dropdown>
                            </h3>
                          </div>
                        </>
                      )}

                      <div className="kitdetails">
                        <h3
                          style={{
                            display: "flex",
                            color: color,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <MdOutlinePendingActions
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "0 10px",
                            }}
                          />{" "}
                          Status
                        </h3>
                        <h3
                          style={{
                            display: "flex",
                            color: prinarycolor,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {kitdetails.resultuploaded ? "Results Published"
                            : kitdetails.receiveddate
                              ? "KIT Received in Lab, Awaiting Results"

                              : kitdetails.assignedto
                                ? "Kit Not received in lab yet"
                                :
                                "Kit Created"
                          }
                          {/* <Dropdown
                            menu={{ items: itemsfortype }}
                            placement="bottomRight"
                            arrow
                          >
                            <IoIosArrowDropdown
                              className="hovar"
                              style={{
                                width: "20px",
                                height: "20px",
                                margin: "0 10px",
                              }}
                            />
                          </Dropdown> */}
                        </h3>
                      </div>

                      <div className="kitdetails">
                        <h3
                          style={{
                            display: "flex",
                            color: color,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <MdOutlinePayments
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "0 10px",
                            }}
                          />{" "}
                          Payment
                        </h3>
                        <h3
                          style={{
                            display: "flex",
                            color: prinarycolor,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          &pound; {kitdetailsKitprice()}
                          {kitdetails.assignedto ? (
                            <>
                              <Dropdown
                                menu={{
                                  items:
                                    kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf"
                                      ? token ===
                                        "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
                                        ? items2
                                        : items1
                                      : token ===
                                        "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
                                        ? items
                                        : items1,
                                }}
                                placement="bottomRight"
                                arrow
                              >
                                <IoIosArrowDropdown
                                  className="hovar"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    margin: "0 10px",
                                  }}
                                />
                              </Dropdown>
                            </>
                          ) : (
                            <></>
                          )}
                        </h3>
                      </div>

                      <div className="kitdetails">
                        <h3
                          style={{
                            display: "flex",
                            color: color,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <TbReportMoney
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "0 10px",
                            }}
                          />{" "}
                          Payment Status
                        </h3>
                        <h3
                          style={{
                            display: "flex",
                            color: prinarycolor,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {kitdetailsKitpricestatus()}{" "}
                        </h3>
                      </div>

                      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                        <>
                          <div className="kitdetails">
                            <h3
                              style={{
                                display: "flex",
                                color: color,
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <TbGitBranchDeleted
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  margin: "0 10px",
                                }}
                              />{" "}
                              Action{" "}
                            </h3>
                            <div className="">
                              {/* {kitdetails.assignedto ? 
                              (   
                                    <>
                                      {token ===
                                        "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                                        <>
                                          <Dropdown
                                            menu={{
                                              items:
                                                kitdetails.status ===
                                                "Results Published"
                                                  ? itemsstatus2
                                                  : kitdetails.status!==""  ? itemsstatus1 : itemsstatusfordelete,
                                            }}
                                            placement="bottomRight"
                                            arrow
                                          >
                                            <IoIosArrowDropdown
                                              className="hovar"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                margin: "0 10px",
                                                color: "#6e4e9f",
                                              }}
                                            />
                                          </Dropdown>
                                        </>
                                      )}
                                    </>
                                 


                           
                              ) : (
                                <><Dropdown
                                menu={{
                                  items: itemsstatusfordelete,
                                }}
                                placement="bottomRight"
                                arrow
                              >
                                <IoIosArrowDropdown
                                  className="hovar"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    margin: "0 10px",
                                    color: "#6e4e9f",
                                  }}
                                />
                              </Dropdown></>
                              ) } */}

                              <Dropdown
                                menu={{
                                  items:
                                    // kitdetails.status ===
                                    // "Results Published"
                                    //   ? itemsstatus2
                                    //   : kitdetails.status!==""  ? itemsstatus1 : itemsstatusfordelete,
                                    kitdetails.resultuploaded

                                      ? itemsstatus2
                                      :
                                      kitdetails.receiveddate ? itemsstatus1
                                        : itemsstatusfordelete,
                                }}
                                placement="bottomRight"
                                arrow
                              >
                                <IoIosArrowDropdown
                                  className="hovar"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    margin: "0 10px",
                                    color: "#6e4e9f",
                                  }}
                                />
                              </Dropdown>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="bownpopupbutton">
                      <button
                        onClick={() => {
                          setpopup2(false);
                        }}
                        style={{ border: "1px solid red", color: "red" }}

                      >
                        cancel
                      </button>
                    </div>
                  </div>
                </>
              )}

              {popup3 === true && (
                <>
                  <div
                    onClick={() => {
                      setpopup3(false);
                      setassignall(false);
                    }}
                    className="popupbg"
                  ></div>
                  <div className="popup">
                    <div className="header">
                      <h4>
                        Assign&#160;
                        {assignall ? <>All</> : <>({kitIDtoassign.kitid})</>}
                        &#160; Kits to:
                      </h4>

                      {dooption2()}

                      {searchdone2 === false && (
                        <>
                          <form
                            onSubmit={handleInputChange2}
                            style={{ width: "fit-content" }}
                          >
                            <AutoComplete
                              type="number"
                              style={{ width: 200 }}
                              options={options2}
                              placeholder="Search by Email/Name"
                              filterOption={
                                (inputValue, options) =>
                                  options.value
                                    .toString()
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                                //  console.log(kitss)
                              }
                              onChange={(inputValue) =>
                                setSearchTerm2(inputValue)
                              }
                            />
                            <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Search</button>
                          </form>
                        </>
                      )}
                      {searchdone2 === true && (
                        <>
                          {" "}
                          <div className="clearsearch">
                            <h3>search: {searchTerm2}</h3>{" "}
                            <button onClick={clearsearch2}>
                              <AiOutlineClose /> Clear
                            </button>{" "}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="userbox">
                      {practitionertoassign.map((item, index) => (
                        <div
                          onClick={() => setpractitionertoassignID(item._id)}
                          className={
                            practitionertoassignID === item._id
                              ? "userboxxinside slecteddd"
                              : "userboxxinside"
                          }
                        >
                          <img alt="" src={item.profilepic} width={"25%"} />
                          <div>
                            <h5>Name: {item.name}</h5>
                            <h5>email: {item.email}</h5>

                            {item.phone !== "-" ? (
                              <>
                                <h5>phone:{item.phone}</h5>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="bownpopupbutton">
                      <button
                        onClick={() => {
                          setpopup3(false);
                          setassignall(false);
                        }}
                        style={{ border: "1px solid red", color: "red" }}
                      >
                        CANCEL
                      </button>

                      <button
                        onClick={() => {
                          if (practitionertoassignID !== "") {
                            assignall ? sendkittobackend() : assignkittopra();
                          } else {
                            message.error("Please Select Practitioner");
                          }
                        }}
                        style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}
                      >
                        ASSIGN
                      </button>
                    </div>
                  </div>
                </>
              )}

              {kit.length !== 0 && (
                <>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      color: prinarycolor,
                    }}
                  >
                    <h3>Total Kits {totalKits}</h3>
                  </div>

                  <div className="tableforpc">
                    <table
                      className="tablep"
                      cellPadding={"10"}
                      cellSpacing={"0"}
                    >
                      <thead className="tablephead" style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                        <tr>
                          {/* <th><input type='checkbox' /></th> */}
                          <th>S NO.</th>
                          <th>Kit ID</th>
                          {(token ===
                            "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
                            token2 ===
                            "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && (
                              <>
                                {" "}
                                <th>Assigned to</th>
                              </>
                            )}

                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{ color: prinarycolor }}>
                        {kit.map((item, index) => (
                          <>
                            <tr>
                              {/* <td><input type='checkbox' /></td> */}
                              <td style={{ color: prinarycolor }}>{sno2++}</td>
                              <td style={{ color: prinarycolor }}>{item.kitid}</td>
                              {(token ===
                                "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
                                token2 ===
                                "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && (
                                  <>
                                    {item.assignedto ? (
                                      <td
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          textAlign: "left",
                                          color: prinarycolor
                                        }}
                                      >
                                        {practitioner.map((item2, index) => (
                                          <>
                                            {item2._id === item.assignedto && (
                                              <>
                                                <td
                                                  style={{
                                                    border: "none",
                                                    width: "50%",
                                                    color: prinarycolor
                                                  }}
                                                >
                                                  {item2.name}{" "}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "none",
                                                    width: "50%",
                                                    color: prinarycolor
                                                  }}
                                                >
                                                  {item2.email}
                                                </td>
                                              </>
                                            )}
                                          </>
                                        ))}
                                      </td>
                                    ) : (
                                      <td
                                        className="hovar"
                                        style={{
                                          color: prinarycolor,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        onClick={() => {
                                          setpopup3(true);
                                          setkitIDtoassign(item);
                                        }}
                                      >
                                        <MdAssignmentReturn
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            color: prinarycolor,
                                          }}
                                        />
                                        <h5> Assign</h5>
                                      </td>
                                    )}
                                  </>
                                )}

                              <td style={{ color: prinarycolor }}>
                                {item.resultuploaded ? "Results Published"
                                  : item.receiveddate
                                    ? "KIT Received in Lab, Awaiting Results"

                                    : item.assignedto
                                      ? "Kit Not received in lab yet"
                                      :
                                      "Kit Created"
                                }
                              </td>
                              {/* <td>{item.status}</td> */}

                              <td style={{ color: prinarycolor }}>
                                <button
                                  className="button"
                                  onClick={() => {
                                    setpopup2(true);
                                    setkitdetails(item);
                                  }}
                                  style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}
                                >
                                  {" "}
                                  Details
                                  {console.log(params)}
                                </button>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>

                  </div>

                  <div className="tableformobile">
                    {kit.map((item, index) => (<>

                      <div className="tableformobileinside">

                        <div className="tableformobileinsidediv">   <h3 style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>S No.</h3><h4 style={{ color: color }}>{sno3++}</h4></div>

                        <div className="tableformobileinsidediv">   <h3 style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>Kit Code</h3 ><h4 style={{ color: color }}>{item.kitid}</h4></div>

                        <div className="tableformobileinsidediv">   <h3 style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>Assign To</h3><h4 style={{ color: color }}>
                          {item.assignedto ? (<>

                            {practitioner.find((item2) => item2._id === item.assignedto)?.name || "practitioner Removed from portal"}<br />
                            {practitioner.find((item2) => item2._id === item.assignedto)?.email || ""}

                          </>) : (<>
                            <div
                              className="hovar"
                              style={{
                                color: prinarycolor,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => {
                                setpopup3(true);
                                setkitIDtoassign(item);
                              }}
                            >
                              <MdAssignmentReturn
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  color: prinarycolor,
                                }}
                              />
                              <h5> Assign</h5>
                            </div>

                          </>)}
                        </h4></div>

                        <div className="tableformobileinsidediv">   <h3 style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>Status</h3><h4 style={{ color: color }}>

                          {item.resultuploaded ? "Results Published"
                            : item.receiveddate
                              ? "KIT Received in Lab, Awaiting Results"

                              : item.assignedto
                                ? "Kit Not received in lab yet"
                                :
                                "Kit Created"
                          }
                        </h4></div>
                        <div className="tableformobileinsidediv">   <h3 style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>Action</h3><h4 style={{ color: color }}>

                          <button
                            className="button"
                            onClick={() => {
                              setpopup2(true);
                              setkitdetails(item);
                            }}
                            style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}
                          >
                            {" "}
                            Details
                            {console.log(params)}
                          </button>
                        </h4></div>
                      </div>
                    </>))}
                  </div>
                </>
              )}

              {kit.length === 0 && (
                <>
                  {totalPages === 0 ? (
                    <>
                      <img alt="" src="/empty2.gif" width={"35%"} />
                    </>
                  ) : (
                    <>
                      {" "}
                      <img alt="" src="/empty.gif" width={"40%"} />{" "}
                    </>
                  )}
                </>
              )}
              <div className="pagination">
                {page > 1 && (
                  <span onClick={() => handlePageClick(page - 1)}>
                    &lt;&lt;
                  </span>
                )}
                {renderPageNumbers()}
                {page < totalPages && (
                  <span onClick={() => handlePageClick(page + 1)}>
                    &gt;&gt;
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <NotLogin />
      )}
    </>
  );
};

export default KITS;
