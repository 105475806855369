import React, { useState, useEffect, useRef, useMemo } from 'react'
import Navbar from '../../components/navbar';
import Sidemanu from '../../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder, CgTemplate } from 'react-icons/cg';
import axios from 'axios';
import { Checkbox, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../../css/sendemail.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdOutlineSubject, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendarDate, BsFiletypeAac, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaFileArrowDown } from 'react-icons/fa6';
import { ImCross } from 'react-icons/im';
import ReactQuill, { Quill } from 'react-quill';
import Papa from 'papaparse';
import { ImUpload2, ImUserTie } from 'react-icons/im'
import NotLogin from '../../components/notLogin';
import { SiMinutemailer, SiMonkeytype } from 'react-icons/si';
import 'react-quill/dist/quill.snow.css';
import { imageDb } from '../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { BiSolidRename } from 'react-icons/bi';
const Delta = Quill.import("delta")


function Emailtemplates() {



  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const token2 = Cookies.get("Token2")




  const [from, setfrom] = useState("lab@yourgutmap.co.uk")
  const [Template, setTemplate] = useState("")
 

  const [to, setto] = useState("")
  const [trackemail, settrackemail] = useState(false)
  const [addtemp, setaddtemp] = useState(false)
  const [subscriber, setsubscriber] = useState(false)
  const [subscriberlist, setsubscriberlist] = useState([])

  const [loading, setloading] = useState(false)
  const apiurlforbackend = process.env.REACT_APP_APIURL;


  const [name, setname] = useState("")
  const [content, setContent] = useState('');
  const [subject, setsubject] = useState("")
  const [tempfor, settempfor] = useState("")

  const [template, settemplate] = useState([])
  const [templatedetails, settemplatedetails] = useState({})


  const quillRef = useRef(null);



  const sendEmail = async (e) => {
    e.preventDefault();
    if (!content.trim()) {
      message.error("Please enter the email content");
      return;
    }
    setloading(true)
  

    console.log(content)
    try {
      await axios.post(apiurlforbackend + '/save-email-template', { name, subject, content, createdby: id, tempfor });
      message.success("Template Saved successfully");
      await setContent("")
      await setname("")
      await setsubject("")
      await gettemp()
      await settempfor(false)

    } catch (error) {
      message.error("Failed to send email");
    }
    setloading(false)
    setaddtemp(false)
  };


  const gettemp = async () => {



    try {
      const response = await axios.post(apiurlforbackend + '/get-email-template', { tempfor: 'all' });
      await settemplate(response.data)

    } catch (error) {
      message.error("Failed to send email");
    }

  };

  const getunsubscriber = async () => {


    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/get-email-unsubscriber',
      headers: {
        'Content-Type': 'application/json'
      },

    };

    await axios.request(config)
      .then((response) => {
        setsubscriberlist(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }


  useEffect(() => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      gettemp()
      getunsubscriber()
    }
  }, [])

  const imageHandler = () => {
    console.log("Image button clicked");
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;
      const date = Date.now();
      const imgRef = ref(imageDb, `emailimages/${date}-${file.name}`);

      // Upload image to Firebase
      await uploadBytes(imgRef, file);
      const filefirebaseurl = await getDownloadURL(imgRef);

      const quill = quillRef.current?.getEditor();
      const range = quill.getSelection();

      if (quill) {
        quill.insertEmbed(range.index, "image", filefirebaseurl);

        // Wait for the image to be inserted, then modify its size
       
      }
    };
  };


  const attachmentHandler = () => {
    console.log("Attachment button clicked");
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;
      const date = Date.now();
      const fileRef = ref(imageDb, `emailattachments/${date}-${file.name}`);

      // Upload file to Firebase
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      const quill = quillRef.current?.getEditor();
      const range = quill.getSelection();

      // Custom styled attachment block
      const attachmentHTML = `
              <br>
              <a href='${fileUrl}' target="_blank"
                  style="display: flex; text-decoration: none; align-items: center; padding: 10px; 
                  border: 2px solid #ddd; border-radius: 8px; background-color: #f9f9f9; 
                  width: 300px; cursor: pointer;">
                  <img src="https://img.icons8.com/ios-filled/50/000000/pdf.png" alt="PDF Icon"
                      style="width: 40px; height: 40px; margin-right: 10px;">
                  <div>
                      <p style="margin: 0; font-size: 16px; color: #333;">${file.name}</p>
                  </div>
              </a>
              <br>
          `;

      if (quill) {
        quill.clipboard.dangerouslyPasteHTML(range.index, attachmentHTML);
        quill.setSelection(range.index + 1);
      }
    };
  };


  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ["link", "image", "custom-attachment"],  // Add attachment button
      ],
      handlers: {
        image: imageHandler,
        "custom-attachment": attachmentHandler, // Custom attachment handler
      },
    },
  }), []); // Ensures this object is not recreated on each render

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
    "image",
    "custom-attachment"
  ];



  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.getEditor().clipboard.addMatcher("img", (node, delta) => {
        return new Delta(); // Prevents image insertion
      });
    }
  }, []);
  useEffect(() => {
    const toolbar = document.querySelector(".ql-toolbar");
    if (toolbar) {
      const button = document.createElement("button");
      button.innerHTML = "📎"; // Paperclip icon
      button.type = "button"; // Paperclip icon
      button.className = "ql-custom-attachment"; // Custom class
      button.onclick = attachmentHandler;
      toolbar.appendChild(button);
    }
  }, [addtemp]);


  var sno1 = 1

  const [popupdetails, setpopupdetails] = useState(false)
  const [email, setemail] = useState("")
  const [nameemail, setnameemail] = useState("")





  return (
    <div>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") ?
        <>



          <Navbar />

          <div className='deshboardmain'>
            <Sidemanu />

            <div className='mainbody' style={{ paddingTop: "20px" }}>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1
                  style={{
                    color: "#6E4E9F",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CgTemplate style={{ width: "35px", height: "35px" }} />
                  Email Templates
                </h1>

                <div style={{ display: "flex", gap: "20px" }}>
                  {addtemp ? (<>
                    <button onClick={() => { setaddtemp(false); setsubscriber(false) }} className='dltbtn'>Close</button>


                  </>) : (<>
                    <button onClick={() => { setaddtemp(true); setsubscriber(true) }} className='button'>Unsubscribe</button>


                  </>)}

                  {addtemp ? (<>

                  </>) : (<>
                    <button onClick={() => setaddtemp(true)} className='button'>Add New Template</button>


                  </>)}
                </div>
              </div>

              {addtemp ? (<>

                {subscriber ? (<>


                  <form className='emailbody addemailform' style={{ marginBottom: "25px", backgroundColor: 'transparent' }} onSubmit={async (e) => {
                    e.preventDefault()
                    let data = JSON.stringify({
                      "name": nameemail,
                      "email": email,

                    });

                    let config = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: apiurlforbackend + '/save-email-unsubscriber',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      data: data
                    };

                    await axios.request(config)
                      .then((response) => {

                        if (response.status === 202) {
                          message.error(response.data.message);

                        } else {
                          message.success(response.data.message);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                    setnameemail("")
                    setemail("")
                    await getunsubscriber()

                    // setto("")
                  }} s>
                    <div className='contantdiv  ' style={{ color: '#6E4E9F', backgroundColor: "transparent", marginTop: "10px" }}>
                      <h3>Add Email:</h3>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div className='addemailform' style={{ display: 'flex', width: "100%", gap: '10px' }}>
                          <input type='text' required className='formalinput ' placeholder='Enter Name' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '60%' }} value={nameemail} onChange={(e) => setnameemail(e.target.value)} />

                          <input type='email' required className='formalinput ' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '60%' }} value={email} onChange={(e) => setemail(e.target.value)} />
                          <button className='button addemailformbtn'>ADD</button>
                        </div>


                      </div>
                    </div>
                  </form>

                  <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                    <thead className='tablephead'>
                      <tr>
                        <th>S NO.</th>

                        <th>Name</th>
                        <th>Email</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>


                      {subscriberlist.map((item, index) =>

                        <>
                          <tr>
                            <td>{sno1++} </td>

                            <td>{item.name}</td>

                            <td>{item.email}</td>




                            <td  >
                              <button className='dltbtn' onClick={() => {
                                // setpopupdetails(true)
                                // settemplatedetails(item)
                              }}>Delete</button>
                            </td>




                          </tr>
                        </>
                      )}



                    </tbody>
                  </table>

                </>) : (<>
                  <form onSubmit={sendEmail} className='emailbody' style={{ marginBottom: "25px" }}>

                    {loading ? (<>

                      <img src='/empty.gif' width={"50%"} />
                    </>) : (<>
                      <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                        <h3>Name For Template:</h3>
                        <input required className='formalinput' placeholder='Enter Name' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={name} onChange={(e) => setname(e.target.value)} />
                        <p style={{ padding: '0', margin: '0', fontSize: '0.8em' }}><strong>NOTE:</strong> this will not be used in email content, just for team to identify Template</p>
                      </div>





                      <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                        <h3>Email Subject:</h3>
                        <input required className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={subject} onChange={(e) => setsubject(e.target.value)} />

                      </div>



                      <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                        <h3>Template Used For:</h3>
                        {/* <input required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={to} onChange={(e) => setto(e.target.value)} /> */}
                        <select required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", width: "32%", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} >
                          <option value={""} selected disabled>Please Select</option>
                          <option value={"Practitioners"}>Practitioners</option>
                          <option value={"Leads"}>Leads</option>
                          <option value={"Other"}>Other</option>

                        </select>

                      </div>

                      <div >

                        <div className='contantdiv' style={{ color: '#6E4E9F' }}>



                          <h3>Email Content:</h3>
                          <div  >
                            <ReactQuill

                              ref={quillRef}
                              value={content}
                              onChange={setContent}
                              modules={modules}
                              formats={formats}
                              placeholder="Write your email here..."
                            />
                          </div>
                        </div>
                      </div>




                      <div className='contantdiv' style={{ marginBottom: "10px" }}>
                        <button type='submit' className='button' style={{ color: "#dcd2fc", padding: "7px 25px", borderRadius: "6px", background: "#6e4f9f" }}>Save Template</button>
                      </div>
                    </>)}
                  </form>
                </>)}
              </>) : (<>


                <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>

                      <th>Template Name</th>
                      <th>Subject</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>


                    {template.map((item, index) =>

                      <>
                        <tr>
                          <td>{sno1++} </td>

                          <td>{item.name}</td>

                          <td>{item.subject}</td>




                          <td  >
                            <button className='button' onClick={() => {
                              setpopupdetails(true)
                              settemplatedetails(item)
                            }}>Detail</button>
                          </td>




                        </tr>
                      </>
                    )}



                  </tbody>
                </table>

              </>)}


              {popupdetails === true && <>
                <div onClick={() => {
                  setpopupdetails(false)

                }
                } className='popupbg'></div>
                <div className='popup'>



                  <div className='header' >
                    <h2>Template Details</h2>
                  </div>
                  <div className='kitdetailsmain' >




                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <BiSolidRename style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Template Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {templatedetails.name} </h3>
                    </div>

                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <SiMonkeytype style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Template Used For</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {templatedetails.tempfor} </h3>
                    </div>

                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Created By</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {templatedetails.createdby} </h3>
                    </div>

                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <BsFiletypeAac style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Email Subject</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {templatedetails.subject} </h3>
                    </div>

                    <div className='kitdetails' style={{ flexDirection: 'column' }}>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlineSubject style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Email Content</h3>



                      <div style={{ display: 'block !important', textAlign: 'left' }}> 
                        <div dangerouslySetInnerHTML={{ __html: templatedetails.content }} />
                      </div>

                    </div>








                  </div>




                  <div className='bownpopupbutton'>
                    <button onClick={() => {
                      setpopupdetails(false)

                    }
                    } style={{ border: '1px solid red', color: 'black' }} >cancel</button>












                  </div>

                </div>
              </>}


            </div>

          </div>



        </> : <NotLogin />}
    </div>
  )
}

export default Emailtemplates
