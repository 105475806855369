import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Button, Checkbox, Drawer, message, Popconfirm } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../css/orderlables.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdEvent, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendar2EventFill, BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaEarthAfrica, FaFileArrowDown, FaRegMessage } from 'react-icons/fa6';
import NotLogin from '../components/notLogin';

function Eventinfo() {
  var sno1 = 1
  var sno2 = 1

  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")


  const [popup, setpopup] = useState(false)

  const [showevent, setshowevent] = useState(false)



  const [orders, setorders] = useState([])
  const [eventlist, seteventlist] = useState([])


  const [orderdetails, setorderdetails] = useState([])

  const [kit_code, setkitcode] = useState([])

  const getorders = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getuploadresultotherevent", requestOptions)
      .then(response => response.json())
      .then(result => setorders(result))
  };





  const [email, setemail] = useState("")
  const [nameemail, setnameemail] = useState("")

  const apiurlforbackend = process.env.REACT_APP_APIURL;




  const getenevt = async () => {


    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/get-event-list',
      headers: {
        'Content-Type': 'application/json'
      },

    };

    await axios.request(config)
      .then((response) => {
        seteventlist(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }


  useEffect(() => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      getorders()


      getenevt()
    }
  }, [])

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [filtersource, setfiltersource] = useState([])


  return (
    <div>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ?
        <>



          <Navbar />

          <div className='deshboardmain'>
            <Sidemanu />

            <div className='mainbody'>

              <div className='header'>
                <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><BsCalendar2EventFill style={{ width: '30px', height: '30px' }} />Event Info</h1>
                {showevent ? (<>
                  <button className='button' style={{ backgroundColor: "#fff", color: "red", border: "1px solid red" }} onClick={() => { setshowevent(false) }} >Close</button>
                </>) : (<>
                  <button className='button' onClick={() => { setshowevent(true) }} >Add Event</button>
                </>)}
              </div>


              <div className='header' style={{ marginTop: '20px' }}>
                <h3></h3>
                <div style={{ display: 'flex', gap: '20px' }}>

                  <button className='button' onClick={() => {

                    const csvData = orders.map(order => ({
                      Name: order.Name,
                      Email: order.Email,
                      Event: eventlist.find(event => event._id === order.event)?.event || 'N/A',
                      Phone: order.Phone,
                      Country: order.Country,
                      AccountManager: order.accountmanager,
                      KitCodes: order.KitCodes
                    }));

                    const csvHeader = Object.keys(csvData[0]).join(',');
                    const escapeCsvValue = (value) => {
                      if (typeof value === 'string' && value.includes(',')) {
                        return `"${value.replace(/"/g, '""')}"`;
                      }
                      return value;
                    };

                    const csvRows = csvData.map(row => Object.values(row).map(escapeCsvValue).join(',')).join('\n');
                    const csvContent = `data:text/csv;charset=utf-8,${csvHeader}\n${csvRows}`;

                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', 'event_info.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                  }} >Download As CSV</button>
                  <button className='button' onClick={showDrawer} >Filter</button>

                </div>
              </div>

              <Drawer title="Set Filter" onClose={onClose} open={open}>
                <form onSubmit={async (e) => {
                  let info
                  e.preventDefault()


                  var myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



                  var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,

                    redirect: 'follow'
                  };

                  await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getuploadresultotherevent", requestOptions)
                    .then(response => response.json())
                    .then(result => info = result)
                  onClose()
                  if (filtersource.length === 0) {
                    setorders(info)
                  } else {
                    setorders(info.filter((value) => filtersource.includes(value.event)))
                  }
                  console.log(filtersource)
                  // Add value._id when checked
                  // setfiltersource([])                   // Remove value._id when unchecked    
                }}>





                  <h3>Events</h3>


                  {eventlist.length > 0 && <>
                    {eventlist.map((value) => <>
                      <div className="sourcecheckbox">
                        <input
                          id={value._id} // Use `id` to match with `label`
                          name={value._id}
                          type="checkbox"
                          checked={filtersource.includes(value._id)}
                          onChange={(e) => {
                            setfiltersource((prevFilters) =>
                              e.target.checked
                                ? [...prevFilters, value._id] // Add value._id when checked
                                : prevFilters.filter((item) => item !== value._id) // Remove value._id when unchecked
                            );
                          }}
                        />
                        <label htmlFor={value._id}>{value.event}</label> {/* Use `htmlFor` instead of `for` */}
                      </div>

                    </>)}

                  </>}
                  <button className='button' type='submit'>APPLY</button>
                </form>
              </Drawer>

              {popup === true && <>
                <div onClick={() => {
                  setpopup(false)


                }
                } className='popupbg'></div>
                <div className='popup'>


                  <div className='header' >
                    <h2>Event Info</h2>
                  </div>

                  <div className='kitdetailsmain' >

                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {orderdetails.Name} {orderdetails.lName} </h3>
                    </div>

                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdEmail style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Email </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {orderdetails.Email} </h3>
                    </div>



                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdEvent style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Event</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {eventlist.find((event) => event._id === orderdetails.event)?.event || 'N/A'} </h3>
                    </div>
                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlineNumbers style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Phone</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {orderdetails.Phone} </h3>
                    </div>

                    {/* <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlineFormatListNumbered style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Kitcodes</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {kit_code.map((value2, index) => (<>  {value2} - {index + 1}<br /></>))} </h3>
                    </div> */}

                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaEarthAfrica style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Country</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.Country} </h3>
                    </div>

                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Account Manager</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.accountmanager} </h3>
                    </div>


                    <div className='kitdetails' style={{ flexDirection: 'column' }}>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegMessage style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Kit codes or message</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'left', marginLeft: '40px' }}>  {orderdetails.KitCodes} </h3>
                    </div>




                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbGitBranchDeleted style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action </h3><h3 onClick={async () => {
                      const hide = message.loading("Action in progress", 0)
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                      var urlencoded = new URLSearchParams();
                      urlencoded.append("_id", orderdetails._id);

                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                      };


                      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dleventinfoformdata", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                          setorders(result)
                          setpopup(false)

                        })
                        .catch(error => console.log('error', error));





                      await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                      }, 2000);

                    }} className='hovar' style={{ display: "flex", color: 'red', alignItems: 'center' }}>  Detele This Data <AiFillDelete style={{ width: '20px', height: '20px', margin: "0 10px" }} /></h3>
                    </div>


                  </div>

                  <div className='bownpopupbutton'>
                    <button onClick={() => {
                      setpopup(false)

                    }
                    } style={{ border: '1px solid red', color: 'black' }} >Close</button>






                  </div>

                </div>
              </>}




              {!showevent ? (<>
                <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>

                      <th>Name</th>
                      <th>Email </th>


                      <th>Event</th>

                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>




                    {orders.map((value, index) => {



                      return (<>
                        <tr>

                          <td>{sno1++}</td>
                          <td>{value.Name} {value.lName}</td>
                          <td>{value.Email}</td>
                          <td>{eventlist.find((event) => event._id === value.event)?.event || 'N/A'}</td>

                          {/* <td style={{textAlign:'left'}}>{kit_code.map((value2, index) => (<>
                        {index + 1} - {value2}<br/>
                      </>))}</td> */}


                          <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                            <button className='button'
                              onClick={() => {
                                setpopup(true)



                                setkitcode(value.KitCodes.split("\n"))

                                setorderdetails(value)
                              }}

                            > Details</button>



                          </td>


                        </tr>
                      </>)
                    })}















                  </tbody>
                </table>
              </>) : (<>

                <form className='emailbody addemailform' style={{ marginBottom: "25px", backgroundColor: 'transparent' }} onSubmit={async (e) => {
                  e.preventDefault()
                  let data = JSON.stringify({
                    "event": nameemail,
                    "accountmanager": email,

                  });

                  let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: apiurlforbackend + '/add-event-list',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    data: data
                  };

                  await axios.request(config)
                    .then((response) => {


                      message.success(response.data.message);

                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  setnameemail("")
                  setemail("")
                  await getenevt()


                }} >
                  <div className='contantdiv  ' style={{ color: '#6E4E9F', backgroundColor: "transparent", marginTop: "10px" }}>
                    <h3>Add Event:</h3>

                    <div style={{ display: "flex", gap: "20px" }}>
                      <div className='addemailform' style={{ display: 'flex', width: "100%", gap: '10px' }}>
                        <input type='text' required className='formalinput ' placeholder='Enter Event Name' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '50%' }} value={nameemail} onChange={(e) => setnameemail(e.target.value)} />

                        {/* <input type='email' required className='formalinput ' placeholder='' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '60%' }} value={email} onChange={(e) => setemail(e.target.value)} /> */}
                        <select required className='formalinput ' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '40%' }} value={email} onChange={(e) => setemail(e.target.value)} >
                          <option value="" selected disabled>Select Account Manager</option>
                          <option value="Josh Netherwood" >Josh Netherwood</option>
                          <option value="Dominic Kitching" >Dominic Kitching</option>
                          <option value="Josh Netherwood & Dominic Kitching" >Josh Netherwood & Dominic Kitching </option>

                          <option value="Other" >Other</option>

                        </select>




                        <button className='button addemailformbtn'>ADD</button>
                      </div>


                    </div>
                  </div>
                </form>

                <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>

                      <th>Event</th>
                      <th>Account Manager</th>
                      <th>Action</th>


                    </tr>
                  </thead>
                  <tbody>


                    {eventlist.map((item, index) =>

                      <>
                        <tr>
                          <td>{sno2++} </td>

                          <td>{item.event}</td>

                          <td>{item.accountmanager}</td>
                          <td>
                            <Popconfirm
                              onConfirm={async () => {



                                var myHeaders = new Headers();
                                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                var urlencoded = new URLSearchParams();
                                urlencoded.append("_id", item._id);

                                var requestOptions = {
                                  method: 'POST',
                                  headers: myHeaders,
                                  body: urlencoded,
                                  redirect: 'follow'
                                };


                                await fetch(apiurlforbackend + "/deleventinfobyid", requestOptions)
                                  .then(response => response.json())
                                  .then(result => {
                                    seteventlist(result)

                                  })
                                  .catch(error => console.log('error', error));




                              }}

                              title="Are you sure to delete this event?"
                              onCancel={() => { }}

                            >

                              <Button danger >Delete</Button>

                            </Popconfirm>

                          </td>
                        </tr>
                      </>
                    )}



                  </tbody>
                </table>

              </>)}


            </div>

          </div>



        </> : <NotLogin />}
    </div>
  )
}

export default Eventinfo