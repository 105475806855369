import React, { useEffect, useState } from "react";
import "../components/component.css";
import { GiExitDoor, GiTongue } from "react-icons/gi";
import { FaBars, FaTasks, FaUserFriends } from "react-icons/fa";
import { ImCross } from "react-icons/im";

import { IoNotificationsSharp } from "react-icons/io5";
import axios from "axios";
import Cookies from "js-cookie";



import {
  MdBiotech,
  MdDataExploration,
  MdEmail,
  MdLabel,
  MdMedicalInformation,
  MdOutlineAnalytics,
  MdOutlineAppRegistration,
  MdOutlineQueryStats,
  MdOutlineSpaceDashboard,
  MdOutlineWeb,
} from "react-icons/md";
import {
  FaBacteria,
  FaBowlFood,
  FaChalkboardUser,
  FaClipboardQuestion,
  FaKitMedical,
} from "react-icons/fa6";
import { HiOutlineUsers, HiUserGroup } from "react-icons/hi2";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { CgProfile, CgReorder } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

import {
  GiDna1,
  GiDna2,
  GiInsectJaws,
  GiLiver,
  GiMicroscope,
} from "react-icons/gi";
import { FcBiomass } from "react-icons/fc";
import {
  FaMapMarkedAlt,
  FaMicroscope,
  FaShippingFast,
  FaShoppingCart,
  FaTicketAlt,
  FaUpload,
} from "react-icons/fa";
import { BiHelpCircle } from "react-icons/bi";
import { IoFastFood, IoFastFoodOutline, IoTicketSharp } from "react-icons/io5";
import { BsCalendar2EventFill } from "react-icons/bs";
import { SiGoogleadsense, SiGooglemeet } from "react-icons/si";
import { RiMailSendLine, RiMicroscopeLine } from "react-icons/ri";
import { Badge, message, Space } from "antd";







import { MdOutlineEmail } from "react-icons/md"
// import { FaBacteria, FaBowlFood, FaChalkboardUser, FaClipboardQuestion, FaKitMedical } from "react-icons/fa6"
// import { HiOutlineUsers, HiUserGroup } from 'react-icons/hi2'
// import { HiOutlineDocumentReport } from "react-icons/hi"
import { CgTemplate } from "react-icons/cg"
// import { useNavigate } from 'react-router-dom'
// import Cookies from 'js-cookie';
import { GiReturnArrow } from 'react-icons/gi'
// import { FcBiomass } from "react-icons/fc";
import { FaWpforms } from 'react-icons/fa'
// import { BiHelpCircle } from 'react-icons/bi'
// import { IoFastFood, IoFastFoodOutline, IoTicketSharp } from 'react-icons/io5'
// import { BsCalendar2EventFill } from 'react-icons/bs'
import { SiAmazonsimpleemailservice } from 'react-icons/si'
import { RiSendPlaneLine } from "react-icons/ri";
import { TbReportSearch } from 'react-icons/tb'


function Navbar() {
  const loginemail = Cookies.get("email");
  const loginname = Cookies.get("Name");
  const id = Cookies.get("id");
  const token = Cookies.get("Token");
  const token2 = Cookies.get("Token");
  const navigate = useNavigate();
  const apiurlforbackend = process.env.REACT_APP_APIURL;

  const logout = () => {

    const cookiesToKeep = [
      "logourl",
      "backgroundcolor",
      "color",
      "Buttonbackgroundcolor",
      "Buttoninsidecolor",
      "prinarycolor",
      "prinarycolortext",
      "seconderycolor",
      "portalid",
      "dashboard",
      "microbiome",
      "microbiomePlus",
      "microbiomeAdvanced",
      "foodSensitivity",
      "foodSensitivityMap",
      "dnaMap",
      "parasitologyTest",
      "candidaProfile",
      "sampleReceived",
      "uploadResults",
      "allPractitioners",
      "dataAndAnalytics",
      "ordersInformation",
      "maps",
      "shipping",
      "whiteLabel",
      "academy",
      "meetings",
      "emails",
      "helpCenter",
      "profile"
    ];

    const allCookies = Cookies.get();
    for (const cookieName in allCookies) {
      if (!cookiesToKeep.includes(cookieName)) {
        Cookies.remove(cookieName);
      }
    }
    // const allCookies = Cookies.get();
    // for (const cookieName in allCookies) {
    //   Cookies.remove(cookieName);
    // }
    navigate("/");
  };

  const [manukonssa, setmanukonssa] = useState("0");

  const [manuuu, setmanu] = useState(false);
  const [manuuunoti, setmanuuunoti] = useState(false);
  const [notification, setnotification] = useState([]);
  const [tickets, settickets] = useState([]);

  const openNotification = async () => {
    try {
      if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          "https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallnotification",
          requestOptions
        );

        const result = await response.json();

        await setnotification(result);

        // result.forEach(value => {
        //     if (!value.status) {
        //         const key = `${value._id}`;
        //         const btn = (
        //             <Space>

        //                 <Button type="primary" size="small" onClick={() => {
        //                     api.destroy(key)
        //                     var myHeaders = new Headers();
        //                     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        //                     var urlencoded = new URLSearchParams();
        //                     urlencoded.append("_id", value._id);

        //                     var requestOptions = {
        //                         method: 'POST',
        //                         headers: myHeaders,
        //                         body: urlencoded,
        //                         redirect: 'follow'
        //                     };

        //                     fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/editnotification", requestOptions)
        //                         .then(response => response.text())
        //                         .then(result => console.log(""))

        //                 }}>
        //                     Mark as Read
        //                 </Button>
        //             </Space>
        //         );
        //         api.open({
        //             message: value.title,
        //             description: value.contant,
        //             btn,
        //             key,
        //             duration: null,

        //         });
        //     }

        // });
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      // Handle error (e.g., show error notification)
    }
  };

  const getticketsunread = async (idd) => {
    let data = JSON.stringify({
      id: idd,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/getallteamticketsbyidandunseen",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        settickets(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      const interval = setInterval(() => {
        getticketsunread(id);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    openNotification();
  }, []);

  const count = () => {
    return notification.filter((item) => item.status === false).length;
  };

  const [show, setshow] = useState(false);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("email", loginemail);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmeetschudulebyemail",
      requestOptions
    )
      .then((response) => response.json())
      .then(async (result) => {
        if (id === result.id) {
          setshow(true);
          //    Cookies.set('Token2', "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd", { expires: 7 }); // Expires in 7 days
        }
      })

      .catch((error) => console.error(error));
  }, []);








  const logourl = Cookies.get("logourl") || "empty.gif";

  // const logourl = Cookies.get("logourl") || "empty.gif";
  const backgroundcolor = Cookies.get("backgroundcolor") || "#999";
  const color = Cookies.get("color") || "#000";
  const Buttonbackgroundcolor = Cookies.get("Buttonbackgroundcolor") || "#dddddd";
  const Buttoninsidecolor = Cookies.get("Buttoninsidecolor") || "#000";
  const prinarycolor = Cookies.get("prinarycolor") || "#000";
  const prinarycolortext = Cookies.get("prinarycolortext") || "#000";
  const seconderycolor = Cookies.get("seconderycolor") || "#000";
  const portalid = Cookies.get("portalid") || "";

  const dashboard = Cookies.get("dashboard") || false;
  const microbiome = Cookies.get("microbiome") || false;
  const microbiomePlus = Cookies.get("microbiomePlus") || false;
  const microbiomeAdvanced = Cookies.get("microbiomeAdvanced") || false;
  const foodSensitivity = Cookies.get("foodSensitivity") || false;
  const foodSensitivityMap = Cookies.get("foodSensitivityMap") || false;
  const dnaMap = Cookies.get("dnaMap") || false;
  const parasitologyTest = Cookies.get("parasitologyTest") || false;
  const candidaProfile = Cookies.get("candidaProfile") || false;
  const sampleReceived = Cookies.get("sampleReceived") || false;
  const uploadResults = Cookies.get("uploadResults") || false;
  const allPractitioners = Cookies.get("allPractitioners") || false;
  const dataAndAnalytics = Cookies.get("dataAndAnalytics") || false;
  const ordersInformation = Cookies.get("ordersInformation") || false;
  const maps = Cookies.get("maps") || false;
  const shipping = Cookies.get("shipping") || false;
  const whiteLabel = Cookies.get("whiteLabel") || false;
  const academy = Cookies.get("academy") || false;
  const meetings = Cookies.get("meetings") || false;
  const emails = Cookies.get("emails") || false;
  const helpCenter = Cookies.get("helpCenter") || false;
  const profile = Cookies.get("profile") || false;



  const baseUrl = `${window.location.host}`;

  // useEffect(() => {
  //     let data = JSON.stringify({
  //         "url": baseUrl
  //     });

  //     let config = {
  //         method: 'post',
  //         maxBodyLength: Infinity,
  //         url: apiurlforbackend + '/get-portal-by-url',
  //         headers: {
  //             'Content-Type': 'application/json'
  //         },
  //         data: data
  //     };

  //     axios.request(config)
  //         .then((response) => {
  //             console.log(JSON.stringify(response.data));
  //             setlogourl(response.data.logourl)
  //             document.title = response.data.bartitle;
  //             setbackgroundcolor(response.data.backgroundcolor)
  //             setcolor(response.data.testcolor)
  //             setButtonbackgroundcolor(response.data.Buttonbackgroundcolor)
  //             setButtoninsidecolor(response.data.Buttoninsidecolor)
  //             setprinarycolor(response.data.prinarycolor)
  //             setprinarycolortext(response.data.prinarycolortext)
  //             setportalid(response.data._id)
  //             setseconderycolor(response.data.seconderycolor)

  //             setDashboard(response.data.dashboard);
  //             setMicrobiome(response.data.microbiome);
  //             setMicrobiomePlus(response.data.microbiomePlus);
  //             setMicrobiomeAdvanced(response.data.microbiomeAdvanced);
  //             setFoodSensitivity(response.data.foodSensitivity);
  //             setFoodSensitivityMap(response.data.foodSensitivityMap);
  //             setDnaMap(response.data.dnaMap);
  //             setParasitologyTest(response.data.parasitologyTest);
  //             setCandidaProfile(response.data.candidaProfile);
  //             setSampleReceived(response.data.sampleReceived);
  //             setUploadResults(response.data.uploadResults);
  //             setAllPractitioners(response.data.allPractitioners);
  //             setDataAndAnalytics(response.data.dataAndAnalytics);
  //             setOrdersInformation(response.data.ordersInformation);
  //             setMaps(response.data.maps);
  //             setShipping(response.data.shipping);
  //             setWhiteLabel(response.data.whiteLabel);
  //             setAcademy(response.data.academy);
  //             setMeetings(response.data.meetings);
  //             setEmails(response.data.emails);
  //             setHelpCenter(response.data.helpCenter);
  //             setProfile(response.data.profile);
  //         })
  //         .catch((error) => {
  //             console.log(error);
  //         });
  // }, [])






  return (
    <>
      {" "}
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
        <>
          {manuuunoti === true && (
            <>
              <div
                className="notificationbg"
                onClick={() => setmanuuunoti(false)}
              >
                {" "}
              </div>
              <div className="notifications">
                {notification.map((value, index) => {
                  return (
                    <>
                      <div
                        className={`subnoti ${value.status === false ? "bglight" : ""
                          }`}
                      >
                        <h4 style={{ margin: 0 }}>{value.title}</h4>
                        <p style={{ margin: 0 }}>{value.contant}</p>

                        {value.status === false && (
                          <>
                            <button
                              onClick={() => {
                                const hide = message.loading(
                                  "Action in progress",
                                  0
                                );
                                var myHeaders = new Headers();
                                myHeaders.append(
                                  "Content-Type",
                                  "application/x-www-form-urlencoded"
                                );

                                var urlencoded = new URLSearchParams();
                                urlencoded.append("_id", value._id);

                                var requestOptions = {
                                  method: "POST",
                                  headers: myHeaders,
                                  body: urlencoded,
                                  redirect: "follow",
                                };

                                fetch(
                                  "https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/editnotification",
                                  requestOptions
                                )
                                  .then((response) => response.json())
                                  .then((result) => {
                                    setnotification(result);
                                    // setmanuuunoti(false)
                                  });

                                setTimeout(() => {
                                  hide(); // Call hide to stop the loading message
                                  message.success(
                                    "Action completed successfully"
                                  );
                                }, 2000);
                              }}
                              className="button"
                            >
                              Mark as Read
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
      <div className="navbar" style={{ color: prinarycolor }}>
        <div className="navlogo">
          <img
            alt=""
            src={logourl}
            width={"160px"}
            className="hovar"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
          token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && (
            <>
              <div className="navmanu" style={{ display: "flex", gap: "20px" }}>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                  <>
                    {sampleReceived === "true" && <>
                      <h5
                        onClick={() => {
                          navigate("/samplerecieved");
                        }}
                        className="hovar link"
                        style={{
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: prinarycolor,
                        }}
                      >
                        {" "}
                        SAMPLE RECIEVED
                      </h5>
                    </>}

                    {dataAndAnalytics === "true" && <>
                      <h5
                        onClick={() => {
                          navigate("/samplereturn");
                        }}
                        className="hovar link"
                        style={{
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: prinarycolor,
                        }}
                      >
                        {" "}
                        SAMPLE RETURN
                      </h5>
                    </>}


                    <h5
                      onClick={() => {
                        navigate("/kitack");
                      }}
                      className="hovar link"
                      style={{
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: prinarycolor,
                      }}
                    >
                      {" "}
                      KIT RECIEVED
                    </h5>


                    {shipping === "true" && <>
                      <h5
                        onClick={() => {
                          navigate("/shipping");
                        }}
                        className="hovar link"
                        style={{
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: prinarycolor,
                        }}
                      >
                        {" "}
                        SHIPPING FORM
                      </h5>
                    </>}

                    {dataAndAnalytics === "true" && <>
                      <h5
                        onClick={() => {
                          navigate("/eventinfo");
                        }}
                        className="hovar link"
                        style={{
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: prinarycolor,
                        }}
                      >
                        {" "}
                        EVENT INFO
                      </h5>
                    </>}

                    {uploadResults === "true" && <>
                      <h5
                        onClick={() => {
                          navigate("/bucket");
                        }}
                        className="hovar link"
                        style={{
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: prinarycolor,
                        }}
                      >
                        {" "}
                        BUCKET
                      </h5>
                    </>}
                  </>
                )}

                {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>
                  {sampleReceived === "true" && <>
                    <h5

                      className="hovar link"
                      style={{
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: prinarycolor,
                      }}
                    >
                      {" "}
                      <a target="_blank" style={{ textDecoration: 'none', color: prinarycolor }} href="https://yourgutmap.co.uk/shop/?v=1cd3c693132f">Order Tests</a>
                    </h5>
                  </>}

                  {sampleReceived === "true" && <>
                    <h5

                      className="hovar link"
                      style={{
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: prinarycolor,
                      }}
                    >
                      {" "}
                      <a target="_blank" style={{ textDecoration: 'none', color: prinarycolor }} href="https://meet.yourgutmap.co.uk/teams/nutrition">  Book Results Interpretation</a>
                    </h5>
                  </>}
                </>}
                <h5
                  onClick={logout}
                  className="hovar"
                  style={{
                    margin: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: prinarycolor,
                  }}
                >
                  {" "}
                  <GiExitDoor style={{ width: "35px", height: "35px" }} /> LOGOUT
                </h5>

                {baseUrl === "portal.yourgutmap.co.uk" && <>
                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                    <>
                      <Space size="large">
                        <Badge count={count()} overflowCount={10}>
                          <IoNotificationsSharp
                            className="hovar"
                            onClick={() => {
                              setmanuuunoti(!manuuunoti);
                            }}
                            style={{
                              width: "25px",
                              height: "25px",
                              color: "#4180B7",
                            }}
                          />
                        </Badge>
                      </Space>
                    </>
                  )}
                </>}

                {baseUrl === "portal.yourgutmap.co.uk" && <>
                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                    <>
                      <Space size="large">
                        <Badge count={tickets.length} overflowCount={99}>
                          <FaTasks
                            className="hovar"
                            onClick={() => {
                              navigate("/dashboard/teams/tickets");
                            }}
                            style={{
                              width: "35px",
                              height: "35px",
                              color: "#4180B7",
                              marginLeft: "30px",
                            }}
                          />
                        </Badge>
                      </Space>
                    </>
                  )}
                </>}
              </div>
            </>
          )}
        <div className="navmanumob">
          {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
            <>
              <Space size="large">
                <Badge count={count()} overflowCount={10}>
                  <IoNotificationsSharp
                    className="hovar"
                    onClick={() => {
                      setmanuuunoti(!manuuunoti);
                    }}
                    style={{ width: "30px", height: "30px", color: "#4180B7" }}
                  />
                </Badge>
              </Space>
            </>
          )}

          {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
            token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && (
              <>
                {manuuu === false && (
                  <>
                    {" "}
                    <h5
                      onClick={() => setmanu(true)}
                      className="hovar"
                      style={{
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#7050A0",
                      }}
                    >
                      {" "}
                      <FaBars style={{ width: "30px", height: "30px" }} />{" "}
                    </h5>
                  </>
                )}
                {manuuu === true && (
                  <>
                    {" "}
                    <h5
                      onClick={() => setmanu(false)}
                      className="hovar"
                      style={{
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#7050A0",
                      }}
                    >
                      {" "}
                      <ImCross style={{ width: "30px", height: "30px" }} />{" "}
                    </h5>
                  </>
                )}
              </>
            )}
        </div>
      </div>
      {manuuu === true && (
        <>
          {" "}
          <div className="mobmanubg" onClick={() => setmanu(false)}>
            {" "}
          </div>
          <div className="mobmanu" style={{ color: prinarycolor }}>
            {dashboard && <>

              <h3 onClick={() => navigate("/dashboard")}><MdOutlineSpaceDashboard style={{ width: '21px', height: '21px' }} /> Dashboard</h3>
            </>}

            {microbiome === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <h3 onClick={() => setmanukonssa("2")}><GiLiver style={{ width: '21px', height: '21px' }} />  Microbiome           </h3>
                {manukonssa === "2" && <>     <ul>
                  <li onClick={() => navigate("/dashboard/microbiome/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/microbiome/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                  <li onClick={() => navigate("/dashboard/microbiome/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>

                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/kitregistration")}><MdOutlineAppRegistration style={{ width: '15px', height: '15px' }} />White Label KIT Registration </li></>}


                </ul>
                </>}
              </>}
            </>}


            {microbiomeAdvanced === "true" && <>
              <h3 onClick={() => setmanukonssa("11")}> <GiMicroscope style={{ width: '21px', height: '21px' }} /> MicrobiomePlus            </h3>
              {manukonssa === "11" && <>   <ul>
                <li onClick={() => navigate("/dashboard/microbiomeplus/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kits </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/microbiomeplus/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                <li onClick={() => navigate("/dashboard/microbiomeplus/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>

              </ul>
              </>}
            </>}

            {microbiomePlus === "true" && <>
              <h3 onClick={() => setmanukonssa("12")}> <RiMicroscopeLine style={{ width: '21px', height: '21px' }} /> MicrobiomeAdvanced         </h3>
              {manukonssa === "12" && <>   <ul>
                <li onClick={() => navigate("/dashboard/microbiomeadvanced/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kits </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/microbiomeadvanced/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                <li onClick={() => navigate("/dashboard/microbiomeadvanced/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>

              </ul>
              </>}
            </>}


            {microbiomePlus === "true" && <>
              <h3 onClick={() => setmanukonssa("14")}> <GiTongue style={{ width: '21px', height: '21px' }} /> Oral Microbiome         </h3>
              {manukonssa === "14" && <>   <ul>
                <li onClick={() => navigate("/dashboard/oralmicrobiome/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kits </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/oralmicrobiome/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                <li onClick={() => navigate("/dashboard/oralmicrobiome/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>

              </ul>
              </>}
            </>}



            {foodSensitivity === "true" && <>
              <h3 onClick={() => setmanukonssa("1")}> <FaBowlFood style={{ width: '21px', height: '21px' }} /> Food Sensitivity        </h3>
              {manukonssa === "1" && <>   <ul>
                <li onClick={() => navigate("/dashboard/fs100/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />100 foods Kits </li>
                <li onClick={() => navigate("/dashboard/fs210/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />210 foods Kits </li>

                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/fs100/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />100 foods kit Practitioners </li></>}
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/fs210/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} /> 210 foods kit Practitioners </li></>}

                <li onClick={() => navigate("/dashboard/fs100/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />100 foods Reports </li>
                <li onClick={() => navigate("/dashboard/fs210/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />210 foods Reports </li>

              </ul>
              </>}  </>}

            {foodSensitivityMap === "true" && <>
              <h3 onClick={() => setmanukonssa("10")}> <IoFastFoodOutline style={{ width: '21px', height: '21px' }} /> FoodSensitivityMap           </h3>
              {manukonssa === "10" && <>   <ul>
                <li onClick={() => navigate("/dashboard/fsmap/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/fsmap/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                <li onClick={() => navigate("/dashboard/fsmap/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/foodsensitivityform-view")}><IoFastFood style={{ width: '15px', height: '15px' }} />Food Sensitivity Form </li></>}

              </ul>
              </>}  </>}

            {dnaMap === "true" && <>
              <h3 onClick={() => setmanukonssa("3")}>  <GiDna1 style={{ width: '21px', height: '21px' }} />DNAMap      </h3>
              {manukonssa === "3" && <>     <ul>
                <li onClick={() => navigate("/dashboard/dna/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/dna/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                <li onClick={() => navigate("/dashboard/dna/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dnamapform-view")}><FaWpforms style={{ width: '15px', height: '15px' }} />DNAMap Form Data </li></>}

              </ul>
              </>}  </>}

            {parasitologyTest === "true" && <>
              <h3 onClick={() => setmanukonssa("4")}>  <FaMicroscope style={{ width: '21px', height: '21px' }} />Parasitology Test      </h3>
              {manukonssa === "4" && <>     <ul>
                <li onClick={() => navigate("/dashboard/Parasitology/kit?page=1")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/Parasitology/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                <li onClick={() => navigate("/dashboard/Parasitology/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/parasitologyform-view")}><FaWpforms style={{ width: '15px', height: '15px' }} />Parasitology Form Data </li></>}

              </ul>
              </>}  </>}


            {candidaProfile === "true" && <>
              <h3 onClick={() => setmanukonssa("8")}>  <FaBacteria style={{ width: '21px', height: '21px' }} />Candida Profile      </h3>
              {manukonssa === "8" && <>     <ul>
                <li onClick={() => navigate("/dashboard/candidaprofile/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/candidaprofile/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                <li onClick={() => navigate("/dashboard/candidaprofile/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/candidaform-view")}><FaWpforms style={{ width: '15px', height: '15px' }} />Candida Form Data </li></>}


              </ul>
              </>}  </>}

            {uploadResults === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/release-reults")}><RiMailSendLine style={{ width: '21px', height: '21px' }} />Release Results</h3></>}
            </>}


            {sampleReceived === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/samplerecieved-data")}><GiReturnArrow style={{ width: '21px', height: '21px' }} />Sample Received</h3></>}
            </>}


            {uploadResults === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/uploadreport")}><FaUpload style={{ width: '21px', height: '21px' }} />Upload Results</h3></>}
            </>}

            {allPractitioners === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/practitioner")}><HiOutlineUsers style={{ width: '21px', height: '21px' }} />All Practitioners</h3></>}
            </>}

            {dataAndAnalytics === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

                <h3 onClick={() => setmanukonssa("7")}>  <MdDataExploration style={{ width: '21px', height: '21px' }} />Data and Analytics                </h3>

                {manukonssa === "7" && <>     <ul>
                  <li onClick={() => navigate("/leads")}><SiGoogleadsense style={{ width: '15px', height: '15px' }} />Leads </li>
                  <li onClick={() => navigate("/leads-converted")}><SiGoogleadsense style={{ width: '15px', height: '15px' }} />Converted Leads </li>

                  <li onClick={() => navigate("/new-Practitioner-data")}><FaUserFriends style={{ width: '15px', height: '15px' }} />New Practitioner Data</li>

                  <li onClick={() => navigate("/dashboard/practitionerstates")}><MdOutlineQueryStats style={{ width: '15px', height: '15px' }} />Practitioners States</li>

                  <li onClick={() => navigate("/dashboard/eventinfogdata")}><BsCalendar2EventFill style={{ width: '15px', height: '15px' }} />Event Info </li>
                  <li onClick={() => navigate("/dashboard/samplereturn-data")}><GiReturnArrow style={{ width: '15px', height: '15px' }} />Sample Return - Data </li>

                  <li onClick={() => navigate("/dashboard/analysis")}><MdOutlineAnalytics style={{ width: '15px', height: '15px' }} />Kits Analysis </li>

                  <li onClick={() => navigate("/dashboard/order-states")}><FaShoppingCart style={{ width: '15px', height: '15px' }} />Order States </li>

                </ul>
                </>}

              </>}
            </>}





            {ordersInformation === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/orders")}><CgReorder style={{ width: '21px', height: '21px' }} />Orders Information</h3></>}
            </>}
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/reportstyles")}><HiOutlineDocumentReport style={{ width: '21px', height: '21px' }} />Report Styles</h3></>}
            {maps === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>    <h3 onClick={() => navigate("/dashboard/map")}><FaMapMarkedAlt style={{ width: '21px', height: '21px' }} />Maps</h3></>}
            </>}
            {shipping === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>    <h3 onClick={() => navigate("/dashboard/shippingdata")}><FaShippingFast style={{ width: '21px', height: '21px' }} />Shipping</h3></>}
            </>}

            {whiteLabel === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <h3 onClick={() => setmanukonssa("6")}><MdLabel style={{ width: '21px', height: '21px' }} />  White Label                </h3>
                {manukonssa === "6" && <>     <ul>

                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dnamapform-view-wl")}><GiDna2 style={{ width: '15px', height: '15px' }} />DNAMap Form </li></>}
                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/parasitologyform-view-wl")}><GiInsectJaws style={{ width: '15px', height: '15px' }} />Parasitology Test Form </li></>}
                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/foodsensitivityform-view-wl")}><IoFastFoodOutline style={{ width: '15px', height: '15px' }} />FoodSensitivityMap Form </li></>}
                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/candidaform-view-wl")}><FaBacteria style={{ width: '15px', height: '15px' }} />CandidaProfile Form </li></>}


                </ul>
                </>}   </>}</>}

            {academy === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>    <h3 onClick={() => navigate("/dashboard/webinar")}><MdOutlineWeb style={{ width: '21px', height: '21px' }} />Academy</h3></>}
            </>}

            {meetings === "true" && <>
              {(token !== "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && show === true) && <>    <h3 onClick={() => navigate("/dashboard/meet")}><SiGooglemeet style={{ width: '21px', height: '21px' }} />Meetings</h3></>}
            </>}



            {meetings === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <h3 onClick={() => setmanukonssa("9")}>  <SiGooglemeet style={{ width: '21px', height: '21px' }} />Meetings      </h3>
                {manukonssa === "9" && <>     <ul>

                  {show && <>
                    <li onClick={() => navigate("/dashboard/meet")}><FaTicketAlt style={{ width: '15px', height: '15px' }} />Meetings Appointments</li>
                  </>}
                  <li onClick={() => navigate("/dashboard/meet-user")}><FaChalkboardUser style={{ width: '15px', height: '15px' }} />Users For Meeting </li>
                </ul>
                </>}
              </>}
            </>}

            {emails === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <h3 onClick={() => setmanukonssa("12")}>  <MdOutlineEmail style={{ width: '21px', height: '21px' }} />Emails      </h3>
                {manukonssa === "12" && <>     <ul>
                  <li onClick={() => navigate("/dashboard/send-email")}><RiSendPlaneLine style={{ width: '15px', height: '15px' }} />Send Single  Email </li>
                  <li onClick={() => navigate("/dashboard/send-mass-email")}><SiAmazonsimpleemailservice style={{ width: '15px', height: '15px' }} />Send Mass Email </li>
                  {/* <li onClick={() => navigate("/dashboard/email-reports")}><TbReportSearch style={{ width: '15px', height: '15px' }} />Mass Email Reports</li> */}
                  <li onClick={() => navigate("/dashboard/email-templates")}><CgTemplate style={{ width: '15px', height: '15px' }} />Email Templates </li>
                </ul>
                </>}
              </>} </>}


            {helpCenter === "true" && <>
              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                <>
                  <h3 onClick={() => setmanukonssa("5")}>
                    {" "}
                    <BiHelpCircle style={{ width: "21px", height: "21px" }} />
                    Help Center{" "}
                  </h3>
                  {manukonssa === "5" && (
                    <>
                      {" "}
                      <ul>
                        <li onClick={() => navigate("/dashboard/tickets")}>
                          <IoTicketSharp
                            style={{ width: "15px", height: "15px" }}
                          />
                          Help Center Tickets{" "}
                        </li>

                        <li onClick={() => navigate("/dashboard/helpcenter")}>
                          <FaClipboardQuestion
                            style={{ width: "15px", height: "15px" }}
                          />
                          Help Center FAQs{" "}
                        </li>
                      </ul>
                    </>
                  )}
                </>
              )} </>}



            {profile === "true" && <>
              <h3 onClick={() => navigate("/dashboard/profile")}><CgProfile style={{ width: '21px', height: '21px' }} />Profile</h3>
            </>}
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
              <>
                {/* <Space size="large">

                                <Badge count={tickets.length} overflowCount={99} >Tasks 
                                    <FaTasks className='hovar' onClick={() => { navigate("/dashboard/teams/tickets") }} style={{ width: '35px', height: '35px', color: "#4180B7", marginLeft: '30px' }} />
                                </Badge>

                            </Space> */}
                <h3 onClick={() => navigate("/dashboard/teams/tickets")}>
                  <CgProfile style={{ width: "21px", height: "21px" }} />
                  Tasks
                </h3>
              </>
            )}

            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
              <>
                <h5
                  onClick={() => {
                    navigate("/samplerecieved");
                  }}
                  className="hovar link"
                  style={{
                    margin: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: prinarycolor,
                  }}
                >
                  {" "}
                  SAMPLE RECIEVED
                </h5>
                <h5
                  onClick={() => {
                    navigate("/samplereturn");
                  }}
                  className="hovar link"
                  style={{
                    margin: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: prinarycolor,
                  }}
                >
                  {" "}
                  SAMPLE RETURN
                </h5>

                <h5
                  onClick={() => {
                    navigate("/kitack");
                  }}
                  className="hovar link"
                  style={{
                    margin: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: prinarycolor,
                  }}
                >
                  {" "}
                  KIT RECIEVED
                </h5>
                <h5
                  onClick={() => {
                    navigate("/shipping");
                  }}
                  className="hovar link"
                  style={{
                    margin: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: prinarycolor,
                  }}
                >
                  {" "}
                  SHIPPING FORM
                </h5>
                <h5
                  onClick={() => {
                    navigate("/eventinfo");
                  }}
                  className="hovar link"
                  style={{
                    margin: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: prinarycolor,
                  }}
                >
                  {" "}
                  EVENT INFO
                </h5>

                <h5
                  onClick={() => {
                    navigate("/bucket");
                  }}
                  className="hovar link"
                  style={{
                    margin: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: prinarycolor,
                  }}
                >
                  {" "}
                  BUCKET
                </h5>
              </>
            )}
            <h5
              onClick={logout}
              className="hovar"
              style={{
                margin: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: prinarycolor,
              }}
            >
              {" "}
              <GiExitDoor style={{ width: "35px", height: "35px" }} /> LOGOUT
            </h5>
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;
