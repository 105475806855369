import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../../components/navbar';
import Sidemanu from '../../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Button, Checkbox, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../../css/orderlables.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaMapMarkerAlt, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaUserFriends, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendar2EventFill, BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaEarthAfrica, FaFileArrowDown } from 'react-icons/fa6';
import NotLogin from '../../components/notLogin';
import { GiReturnArrow } from 'react-icons/gi';
import DOMPurify from 'dompurify'; // Ensures HTML is safe (prevents XSS)


function Secondstepformdata() {
  var sno1 = 1
  var sno2 = 1

  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")
  const apiurlforbackend = process.env.REACT_APP_APIURL;


  const [popup, setpopup] = useState(false)



  const [orders, setorders] = useState([])


  const [orderdetails, setorderdetails] = useState([])

  const [kit_code, setkitcode] = useState([])

  const getorders = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch(apiurlforbackend + "/secondstepform-data", requestOptions)
      .then(response => response.json())
      .then(result => setorders(result))
  };



  useEffect(() => {
    getorders()


  }, [])


  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);

  const [Message, setMessages] = useState([]);

  const messagesEndRef = useRef(null); // Reference to the bottom of the message list


  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [Message]);






  return (
    <div>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ?
        <>



          <Navbar />

          <div className='deshboardmain'>
            <Sidemanu />

            <div className='mainbody'>

              <div className='header'>
                <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><FaUserFriends style={{ width: '30px', height: '30px' }} />Sample Recieved - data </h1>


              </div>



              {popup === true && <>
                <div onClick={() => {
                  setpopup(false)


                }
                } className='popupbg'></div>
                <div className='popup'>


                  <div className='header' >
                    <h2>Event Info</h2>

                  </div>

                  <div className='kitdetailsmain' >



                  {orderdetails.name && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.name} </h3>
                      </div>
                    </>)}

                    {orderdetails.email && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.email} </h3>
                      </div>
                    </>)}

                    {orderdetails.phone && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Phone</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.phone} </h3>
                      </div>
                    </>)}

                    {orderdetails.address && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Address</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.address} </h3>
                      </div>
                    </>)}

                    {orderdetails.accountType && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Account Type</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.accountType} </h3>
                      </div>
                    </>)}

                    {orderdetails.practiceArea && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Practice Area</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.practiceArea} </h3>
                      </div>
                    </>)}

                    {orderdetails.practiceAreaother && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Practice Area Other</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.practiceAreaother} </h3>
                      </div>
                    </>)}

                    {orderdetails.governingBody && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Governing Body</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.governingBody} </h3>
                      </div>
                    </>)}

                    {orderdetails.governingBodyother && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Governing Body Other</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.governingBodyother} </h3>
                      </div>
                    </>)}

                    {orderdetails.membershipNumber && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Membership Number</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.membershipNumber} </h3>
                      </div>
                    </>)}

                    {orderdetails.studyArea && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Study Area</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.studyArea} </h3>
                      </div>
                    </>)}

                    {orderdetails.studyAreaother && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Study Area Other</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.studyAreaother} </h3>
                      </div>
                    </>)}

                    {orderdetails.institution && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Institution</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.institution} </h3>
                      </div>
                    </>)}

                    {orderdetails.institutionother && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Institution Other</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.institutionother} </h3>
                      </div>
                    </>)}

                    {orderdetails.studentId && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Student ID</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.studentId} </h3>
                      </div>
                    </>)}

                    {orderdetails.graduationDate && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Graduation Date</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.graduationDate} </h3>
                      </div>
                    </>)}

                    {orderdetails.referralSource && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Referral Source</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.referralSource} </h3>
                      </div>
                    </>)}

                    {orderdetails.referralSourceother && (<>
                      <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Referral Source Other</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.referralSourceother} </h3>
                      </div>
                    </>)}


                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbGitBranchDeleted style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action </h3><h3 onClick={async () => {
                      const hide = message.loading("Action in progress", 0)
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                      var urlencoded = new URLSearchParams();
                      urlencoded.append("_id", orderdetails._id);

                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                      };


                      await fetch(apiurlforbackend + "/secondstepform-dlt", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                          setorders(result)
                          setpopup(false)

                        })
                        .catch(error => console.log('error', error));





                      await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                      }, 2000);

                    }} className='hovar' style={{ display: "flex", color: 'red', alignItems: 'center' }}>  Detele This Data <AiFillDelete style={{ width: '20px', height: '20px', margin: "0 10px" }} /></h3>
                    </div>


                  </div>

                  <div className='bownpopupbutton'>
                    <button onClick={() => {
                      setpopup(false)

                    }
                    } style={{ border: '1px solid red', color: 'black' }} >Close</button>






                  </div>

                </div>
              </>}





              <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Name</th>
                    <th>Email </th>




                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>




                  {orders.map((value, index) => {



                    return (<>
                      <tr>

                        <td>{sno1++}</td>
                        <td>{value.name}</td>
                        <td>{value.email}</td>






                        <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                          <button className='button'
                            onClick={() => {
                              setpopup(true)
                              setorderdetails(value)
                            }}

                          > Details</button>



                        </td>


                      </tr>
                    </>)
                  })}















                </tbody>
              </table>







            </div>

          </div>



        </> : <NotLogin />}
    </div>
  )
}

export default Secondstepformdata
