import React, { useState } from "react";
import "./formforpopup.css";
import axios from 'axios';

function Formforpopup() {
    const [accountType, setAccountType] = useState("");
    const [email, setemail] = useState("");
    const [practiceArea, setPracticeArea] = useState("");
    const [practiceAreaother, setPracticeAreaother] = useState("");

    const [governingBody, setGoverningBody] = useState("");
    const [governingBodyother, setGoverningBodyother] = useState("");


    const [membershipNumber, setMembershipNumber] = useState("");

    const [studyArea, setStudyArea] = useState("");
    const [studyAreaother, setStudyAreaother] = useState("");


    const [institution, setInstitution] = useState("");
    const [institutionother, setInstitutionother] = useState("");



    const [studentId, setStudentId] = useState("");

    const [graduationDate, setGraduationDate] = useState("");
    // const [graduationDateother, setGraduationDateother] = useState("");

    const [referralSource, setReferralSource] = useState("");
    const [referralSourceother, setReferralSourceother] = useState("");

    const [loading, setloading] = useState("");


    const sentdata = async (e) => {   // this function will be called when the form is submitted
        e.preventDefault();

        setloading(true)
        await axios.post(`${process.env.REACT_APP_APIURL}/submit-secondstep-form`, {
            accountType,
            email,
            practiceArea,
            practiceAreaother,
            governingBody,
            governingBodyother,
            membershipNumber,
            studyArea,
            studyAreaother,
            institution,
            institutionother,
            studentId,
            graduationDate,
            referralSource,
            referralSourceother,
        })
            .then((response) => {
                console.log("Success:", response.data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        // setloading(fals)
    }

    return (
        <div className="formforpopup">

            {loading ? (<>

                <div><h2>Thank you for Registering</h2></div>

            </>) : (<>
                <form onSubmit={sentdata}>

                    <div>
                        <label>Please Enter your Email <span style={{ color: "red" }}>*</span></label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <div>
                        <label>What type of account would you like? <span style={{ color: "red" }}>*</span></label>
                        <select
                            required
                            value={accountType}
                            onChange={(e) => setAccountType(e.target.value)}
                        >
                            <option disabled value="">Select Account Type</option>
                            <option value="Active Practitioner">Active Practitioner</option>
                            <option value="Student">Student</option>
                        </select>
                    </div>

                    {accountType === "Active Practitioner" && (
                        <>
                            <div>
                                <label>What is your area of practice? <span style={{ color: "red" }}>*</span></label>
                                <select  required value={practiceArea} onChange={(e) => setPracticeArea(e.target.value)}>
                                    <option disabled value="">Select Practice Area</option>
                                    <option value="Nutritional Therapist">Nutritional Therapist</option>
                                    <option value="Nutritionist">Nutritionist</option>
                                    <option value="Naturopathic Practitioner">Naturopathic Practitioner</option>
                                    <option value="Colonic Hydrotherapist">Colonic Hydrotherapist</option>
                                    <option value="Sports Nutritionist">Sports Nutritionist</option>
                                    <option value="Functional Medicine Practitioner">Functional Medicine Practitioner</option>
                                    <option value="Herbalist">Herbalist</option>
                                    <option value="Homeopath">Homeopath</option>
                                    <option value="Fitness / Health Coach">Fitness / Health Coach</option>
                                    <option value="Osteopath">Osteopath</option>
                                    <option value="Chiropractor">Chiropractor</option>
                                    <option value="Reflexologist">Reflexologist</option>
                                    <option value="Other">Other (please specify)</option>
                                </select>

                                {practiceArea === "Other" && (
                                    <input
                                    required
                                        type="text"
                                        placeholder="Please Specify"
                                        value={practiceAreaother}
                                        onChange={(e) => setPracticeAreaother(e.target.value)}
                                    />
                                )}
                            </div>

                            <div>
                                <label>Are you a member of a Governing Body? <span style={{ color: "red" }}>*</span></label>
                                <select  required value={governingBody} onChange={(e) => setGoverningBody(e.target.value)}>
                                    <option disabled value="">Select Governing Body</option>
                                    <option value="ARCH">ARCH</option>
                                    <option value="BANT">BANT</option>
                                    <option value="NNA">NNA</option>
                                    <option value="NTOI">NTOI</option>
                                    <option value="ANP">ANP</option>
                                    <option value="UCA">UCA</option>
                                    <option value="AFN">AFN</option>
                                    <option value="RICTAT">RICTAT</option>
                                    <option value="NIMH">NIMH</option>
                                    <option value="IFM">IFM</option>
                                    <option value="Other">Other (please specify)</option>
                                </select>

                                {governingBody === "Other" && (
                                    <input
                                    required
                                        type="text"
                                        placeholder="Please Specify"
                                        value={governingBodyother}
                                        onChange={(e) => setGoverningBodyother(e.target.value)}
                                    />
                                )}
                            </div>

                            <div>
                                <label>Membership Number <span style={{ color: "red" }}>*</span></label>
                                <input
                                    required
                                    type="text"
                                    value={membershipNumber}
                                    onChange={(e) => setMembershipNumber(e.target.value)}
                                    placeholder="Enter Membership Number"
                                />
                            </div>

                            <div>
                                <label>How did you hear about YourGutMap <span style={{ color: "red" }}>*</span></label>
                                <select required value={referralSource} onChange={(e) => setReferralSource(e.target.value)}>
                                    <option disabled value="">Please Select Source</option>
                                    <option value="Webinar">Webinar</option>
                                    <option value="Partner Event">Partner Event</option>
                                    <option value="YourGutMap Event">YourGutMap Event</option>
                                    <option value="Social Media">Social Media</option>
                                    <option value="Referral">Referral</option>
                                    <option value="Governing Body">Governing Body</option>
                                    <option value="Educational Institute">Educational Institute</option>
                                    <option value="Other">Other (please specify)</option>
                                </select>

                                {referralSource === "Other" && (
                                    <input
                                        required
                                        type="text"
                                        placeholder="Please Specify"
                                        value={referralSourceother}
                                        onChange={(e) => setReferralSourceother(e.target.value)}
                                    />
                                )}
                            </div>
                        </>
                    )}

                    {accountType === "Student" && (
                        <>
                            <div>
                                <label>What area of health are you studying in? <span style={{ color: "red" }}>*</span></label>
                                <select required value={studyArea} onChange={(e) => setStudyArea(e.target.value)}>
                                    <option disabled value="">Select Study Area</option>
                                    <option value="Nutritional Therapist">Nutritional Therapist</option>
                                    <option value="Nutritionist">Nutritionist</option>
                                    <option value="Naturopathic Practitioner">Naturopathic Practitioner</option>
                                    <option value="Colonic Hydrotherapist">Colonic Hydrotherapist</option>
                                    <option value="Sports Nutritionist">Sports Nutritionist</option>
                                    <option value="Functional Medicine Practitioner">Functional Medicine Practitioner</option>
                                    <option value="Herbalist">Herbalist</option>
                                    <option value="Homeopath">Homeopath</option>
                                    <option value="Fitness / Health Coach">Fitness / Health Coach</option>
                                    <option value="Osteopath">Osteopath</option>
                                    <option value="Chiropractor">Chiropractor</option>
                                    <option value="Reflexologist">Reflexologist</option>
                                    <option value="Other">Other (please specify)</option>
                                </select>

                                {studyArea === "Other" && (
                                    <input
                                        required

                                        type="text"
                                        placeholder="Please Specify"
                                        value={studyAreaother}
                                        onChange={(e) => setStudyAreaother(e.target.value)}
                                    />
                                )}
                            </div>

                            <div>
                                <label>Who are you studying with? <span style={{ color: "red" }}>*</span></label>
                                <select required value={institution} onChange={(e) => setInstitution(e.target.value)}>
                                    <option disabled value="">Select Institution</option>
                                    <option value="Institute for Optimum Nutrition">Institute for Optimum Nutrition</option>
                                    <option value="Northern College of Acupuncture">Northern College of Acupuncture</option>
                                    <option value="College of Naturopathic Medicine">College of Naturopathic Medicine</option>
                                    <option value="University of West London">University of West London</option>
                                    <option value="RICTAT">RICTAT</option>
                                    <option value="ARCH">ARCH</option>
                                    <option value="College of Living Nutrition">College of Living Nutrition</option>
                                    <option value="Centre for Nutrition Education & Lifestyle Management">
                                        Centre for Nutrition Education & Lifestyle Management
                                    </option>
                                    <option value="Other">Other (please specify)</option>
                                </select>

                                {institution === "Other" && (
                                    <input
                                        required
                                        type="text"
                                        placeholder="Please Specify"
                                        value={institutionother}
                                        onChange={(e) => setInstitutionother(e.target.value)}
                                    />
                                )}
                            </div>

                            <div>
                                <label>Student ID Number</label>
                                <input

                                    type="text"
                                    value={studentId}
                                    onChange={(e) => setStudentId(e.target.value)}
                                    placeholder="Enter Student ID"
                                />
                            </div>

                            <div>
                                <label>When do you graduate? <span style={{ color: "red" }}>*</span></label>
                                <input
                                    required
                                    type="number"
                                    value={graduationDate}
                                    onChange={(e) => setGraduationDate(e.target.value)}
                                    placeholder="Enter Graduation Year"
                                />
                            </div>

                            <div>
                                <label>How did you hear about YourGutMap <span style={{ color: "red" }}>*</span></label>
                                <select required value={referralSource} onChange={(e) => setReferralSource(e.target.value)}>
                                    <option disabled value="">Please Select Source</option>
                                    <option value="Webinar">Webinar</option>
                                    <option value="Partner Event">Partner Event</option>
                                    <option value="YourGutMap Event">YourGutMap Event</option>
                                    <option value="Social Media">Social Media</option>
                                    <option value="Referral">Referral</option>
                                    <option value="Governing Body">Governing Body</option>
                                    <option value="Educational Institute">Educational Institute</option>
                                    <option value="Other">Other (please specify)</option>
                                </select>

                                {referralSource === "Other" && (
                                    <input
                                        required
                                        type="text"
                                        placeholder="Please Specify"
                                        value={referralSourceother}
                                        onChange={(e) => setReferralSourceother(e.target.value)}
                                    />
                                )}
                            </div>
                        </>
                    )}

                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </>)}
        </div>
    );
};

export default Formforpopup;
