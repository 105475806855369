import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import Navbar from '../components/navbar';
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import '../css/shipping.css'
import { AutoComplete, message } from 'antd';
import axios from 'axios';

function Eventinfoform() {




    const [btn, setbtn] = useState(true)

    const [Name, setName] = useState("")
    const [lName, setlName] = useState("")
    const [KitCodes, setKitCodes] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [event, setevent] = useState("")
    const [accountmanager, setaccountmanager] = useState("")
    const apiurlforbackend = process.env.REACT_APP_APIURL;



    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');

    const [eventlist, seteventlist] = useState([]);


    const uploaddata = async (e) => {

        e.preventDefault()


        if (country === "") {

            message.error("Please Enter your Country")
        } else {


            setbtn(false)
            const hide = message.loading("Action in progress", 0)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded2 = new URLSearchParams();
            urlencoded2.append("Name", Name);
            urlencoded2.append("lName", lName);
            urlencoded2.append("KitCodes", KitCodes);
            urlencoded2.append("Email", Email);
            urlencoded2.append("Phone", Phone);
            urlencoded2.append("Country", country);
            urlencoded2.append("event", event);
            urlencoded2.append("accountmanager", accountmanager);



            var requestOptions2 = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded2,
                redirect: 'follow'
            };


            await fetch(apiurlforbackend+"/eventinfoformdata", requestOptions2)
                .then(response => response.text())
                .then(result => {

                    setName("")
                    setlName("")
                    setKitCodes("")
                    setEmail("")
                    setPhone("")
                    setCountry("")

                    setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success(result);
                    }, 2000);

                })
                .catch(error => console.log('error', error));
            await setbtn(true)
        }

    }


    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                // Sort countries alphabetically by name
                const sortedCountries = response.data.sort((a, b) =>
                    a.name.common.localeCompare(b.name.common)
                );
                setCountries(sortedCountries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);





    const [options, setOptions] = useState([{
        value: "United Kingdom"
    }, {
        value: "Ireland"
    }]);



    const handleSearch = (searchText) => {
        if (!searchText) {
            setOptions([]); // Clear options if no search text
            return;
        }

        // Filter countries that include the search text
        const filteredCountries = countries
            .filter(item => item.name.common.toLowerCase().includes(searchText.toLowerCase()))
            .map(item => ({
                value: item.name.common, // Use country name as value
                label: item.name.common  // Use country name as label
            }));

        setOptions(filteredCountries);
    };

    const [searchvalue, setsearchvalue] = useState("")


  


    const getenevt = async () => {


        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/get-event-list',
            headers: {
                'Content-Type': 'application/json'
            },

        };

        await axios.request(config)
            .then((response) => {
                seteventlist(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }


    useEffect(() => {


        getenevt()

    }, [])


    return (<>


        <Navbar />
        <div className='deshboardmain' style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className='popup' style={{ height: 'fit-contant', backgroundColor: '#fdgh', position: 'relative', maxHeight: 'fit-content', zIndex: '0', left: "0", top: '0' }}>

                {/* <form className='emailbody addemailform' style={{ marginBottom: "25px", backgroundColor: 'transparent' }} onSubmit={async (e) => {
                    e.preventDefault()



                }} >

                </form> */}
                <form className='shippinfform' onSubmit={uploaddata}>


                    <div className='contantdiv  ' style={{ color: '#6E4E9F', backgroundColor: "transparent", marginTop: "10px" }}>

                        <div style={{ display: "flex", gap: "20px" }}>
                            <div className='addemailform' style={{ display: 'flex', width: "100%", gap: '10px' }}>

                                {/* <input type='email' required className='formalinput ' placeholder='' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '60%' }} value={email} onChange={(e) => setemail(e.target.value)} /> */}
                                <select required className='formalinput ' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '50%', height: "40px" }} value={event} onChange={(e) => setevent(e.target.value)} >
                                    <option value="" selected disabled>Select Event</option>

                                    {eventlist.map((item, index) => {
                                        return <option value={item._id} key={index}>{item.event}</option>

                                    })}
                                   

                                </select>


                                <select required className='formalinput ' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '50%', height: "40px" }} value={accountmanager} onChange={(e) => setaccountmanager(e.target.value)} >
                                    <option value="" selected disabled>Select Account Manager</option>
                                    <option value="Josh Netherwood" >Josh Netherwood</option>
                                    <option value="Dominic Kitching" >Dominic Kitching</option>
                                    <option value="Josh Netherwood & Dominic Kitching" >Josh Netherwood & Dominic Kitching </option>

                                    <option value="Other" >Other</option>

                                </select>


                            </div>


                        </div>
                    </div>

                    <div className='lableee'>
                        <label>First Name <span style={{ color: "red" }}>*</span></label>
                        <input onChange={(e) => setName(e.target.value)} value={Name} required placeholder='First Name' />
                    </div>

                    <div className='lableee'>
                        <label>Last Name <span style={{ color: "red" }}>*</span></label>
                        <input onChange={(e) => setlName(e.target.value)} value={lName} required placeholder='Last Name' />
                    </div>




                    <div className='lableee'>
                        <label>Email <span style={{ color: "red" }}>*</span></label>
                        <input onChange={(e) => setEmail(e.target.value)} value={Email} required placeholder='Email' />
                    </div>

                    <div className='lableee'>
                        <label>Phone <span style={{ color: "red" }}>*</span></label>
                        <input onChange={(e) => setPhone(e.target.value)} value={Phone} required placeholder='Phone' />
                    </div>

                    <div className='lableee'>
                        <label>Country  <span style={{ color: "red" }}>*</span></label>
                        <AutoComplete
                            options={options}
                            style={{ backgroundColor: '#D2CDFF' }}
                            placeholder="Search for Conutry"
                            required
                            className='autocompleee'
                            value={country}
                            onSearch={handleSearch}  // When typing, call handleSearch
                            onSelect={(value) => setCountry(value)}  // Handle selection
                            onChange={(e) => setCountry(e)}
                        />
                    </div>


                    <div className='lableee'>
                        <label>Kit Codes or Message <span style={{ color: "red" }}></span></label>
                        <textarea onChange={(e) => setKitCodes(e.target.value)} value={KitCodes} placeholder='Kit Codes or Message' />
                    </div>



                    <button disabled={!btn} style={{ width: "100%" }}>SEND</button>
                </form>



            </div>
        </div>

    </>)
}

export default Eventinfoform