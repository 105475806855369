import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/profile.css"
import Cookies from 'js-cookie';
import { AiFillEdit } from "react-icons/ai"
import { message, AutoComplete, Image } from 'antd';
import { HiUserAdd } from "react-icons/hi"
import { AiFillDelete, AiOutlineClose } from "react-icons/ai"
import { useNavigate } from 'react-router-dom';

import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaRegCircleXmark } from 'react-icons/fa6';
import { ImUpload2 } from 'react-icons/im'
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import NotLogin from '../components/notLogin';
import axios from 'axios';


function Profile(params) {
  const Navigate = useNavigate();
  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const [userinfo, setuserinfo] = useState([])
  const [kit, setkit] = useState([])
  const [popupdetails2, setpopupdetails2] = useState(false)
  const [popup, setpopup] = useState(false)
  const [popup2, setpopup2] = useState(false)
  const [popup3, setpopup3] = useState(false)
  const [popup4, setpopup4] = useState(false)

  const [editname, seteditname] = useState("")
  const [editemail, seteditemail] = useState("")
  const [editaddres, seteditaddres] = useState("")
  const [editphone, seteditphone] = useState("")
  const [editabout, seteditabout] = useState("")
  const [billingpostcode, setbillingpostcode] = useState("")
  const [editpassword, seteditpassword] = useState("")

  const [practitioner, setpractitioner] = useState([])
  const [password, setpassword] = useState("")



  const backgroundcolor = Cookies.get("backgroundcolor") || params.backgroundcolor;
  const color = Cookies.get("color") || params.color;
  const Buttonbackgroundcolor = Cookies.get("Buttonbackgroundcolor") || params.Buttonbackgroundcolor;
  const Buttoninsidecolor = Cookies.get("Buttoninsidecolor") || params.Buttoninsidecolor;
  const prinarycolor = Cookies.get("prinarycolor") || params.prinarycolor;
  const prinarycolortext = Cookies.get("prinarycolortext") || params.prinarycolortext;
  const seconderycolor = Cookies.get("seconderycolor") || params.seconderycolor;
  const portalid = Cookies.get("portalid") || params.portalid;



  const [practitionername, setpractitionername] = useState("")
  const [practitioneremail, setpractitioneremail] = useState("")
  const [practitionerpassword, setpractitionerpassword] = useState("")

  const [img, setimg] = useState("/avatar.png")
  const [img2, setimg2] = useState("/avatar.png")
  const [img3, setimg3] = useState("/avatar.png")

  const getadmin = () => {


    let data = JSON.stringify({
      "portalid": portalid
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + '/getalladminbyid',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
       setpractitioner(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


  }
  useEffect(() => {



    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("_id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfo", requestOptions)
        .then(response => response.json())
        .then(result => {
          setuserinfo(result)


          seteditabout(result.about)
          seteditaddres(result.address)
          seteditemail(result.email)
          seteditname(result.name)
          seteditphone(result.phone)
          setbillingpostcode(result.billingpostcode)
          if (result.profilepic) { setimg3(result.profilepic) }
          else { setimg3("/avatar.png") }



        })
        .catch(error => console.log('error', error));


      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));






      getadmin()


    }


  }, [])







  const subminupdatedata = (e) => {
    e.preventDefault()


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);
    urlencoded.append("password", password);
    urlencoded.append("billingpostcode", billingpostcode);
    urlencoded.append("address", editaddres);
    urlencoded.append("phone", editphone);
    urlencoded.append("about", editabout);
    urlencoded.append("email", editemail);
    urlencoded.append("name", editname);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileedit", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result === "Password is incorrect.") { message.error("Password is incorrect.") } else {
          setuserinfo(result)

          setpopup(false)
          setpopup2(false)
        }
      })
      .catch(error => console.log('error', error));


  }




  var pandingresults = 0
  var totalpatients = 0


  const count = () => {

    // kit.map((value, index) => {

    //   if (value.result && Array.isArray(value.result))  {
    //     if (value.result.length !== 0) { totalpatients = totalpatients + 1 } else { pandingresults = pandingresults + 1 }
    //   }
    // }


    // )



  }





  const addnewPractitioner = (e) => {
    e.preventDefault()




    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("name", practitionername);
    urlencoded.append("email", practitioneremail);
    urlencoded.append("password", practitionerpassword);
    urlencoded.append("portalid", portalid);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addadmin", requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result === 'user found') {
          alert("Admin Already in List")
        } else {

          setpractitioner(result)
          setpopup4(false)
          setpractitioneremail('')
          setpractitionername('')
          setpractitionerpassword('')
        }

      }


      )
      .catch(error => console.log('error', error));


  }






  var conuttt = 0;





  const [searchTerm, setSearchTerm] = useState('');
  var options = [];

  const dooption = () => {
    practitioner.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      options.push({ value: value.name })
      options.push({ value: value.email })


    })


  }



  const handleInputChange = (e) => {
    e.preventDefault()






    const intttt = (searchTerm)

    // Filter the Kit array based on the user's input
    const filteredSuggestions = practitioner.filter((item) =>

      item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



    );


    setpractitioner(filteredSuggestions)
    setsearchdone(true)

  };




  const [searchdone, setsearchdone] = useState(false)
  const navigate = useNavigate()
  const clearsearch = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var myHeaders4 = new Headers();
    myHeaders4.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions4 = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getalladmin", requestOptions4)
      .then(response => response.json())
      .then(result => setpractitioner(result))




    setsearchdone(false)

  }


  var coutppp = 0




  var padingres = 0

  const cout = () => {

    coutppp = kit.filter(item => 'result' in item && item.result.length !== 0).length;
    padingres = kit.length - coutppp;
  }









  const changecard = () => {


    const isSure = window.confirm("Are you certain you want to proceed? Initiating a card change will erase your existing card details, necessitating a subsequent login to securely add a new card.");
    if (isSure) {





      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfochnagecard", requestOptions)
        .then(response => response.text())
        .then(result => {
          if (result === "cardremoved") {
            const allCookies = Cookies.get();
            for (const cookieName in allCookies) {
              Cookies.remove(cookieName);
            }
            navigate('/')
          } else { message.error("Something wrong") }
        })
        .catch(error => console.log('error', error));









    }

  }






  const counttttkit = (type) => {
    var whatsreturn = "0"
    var total = 0

    kit.map((value) => {

      if (type === value.Kittype && userinfo._id === value.assignedto) {
        total = total + 1
      }


    })



    if (type === "100") {
      whatsreturn = "fs 100(" + total + ")"
    } else
      if (type === "210") {
        whatsreturn = "fs 210(" + total + ")"
      } else {
        whatsreturn = total
      }
    return (
      whatsreturn
    )
  }



  const Conuttttttt2 = () => {
    var whatreturn = "0"

    var total2 = 0



    kit.map((value) => {

      if ((value.result.length === 0 || value.result2.length === 0) && userinfo._id === value.assignedto && (value.Kittype === "100" || value.Kittype === "210")) {
        total2 = total2 + 1
      }
      if ((value.otherresults === "nil") && userinfo._id === value.assignedto && (value.Kittype !== "100" && value.Kittype !== "210")) {
        total2 = total2 + 1
      }


    })
    whatreturn = total2




    return whatreturn

  }
  const Conuttttttt = () => {
    var whatreturn = "0"
    var total = 0




    kit.map((value) => {

      if ((value.result || value.result2 || value.otherresults !== "nil") && userinfo._id === value.assignedto) {
        total = total + 1
      }


    })
    whatreturn = total



    return whatreturn

  }


  const handleImgChange = (e) => {
    setimg(URL.createObjectURL(e.target.files[0]));
    setimg2(e.target.files[0])
  };

  const updateimage = async (e) => {
    e.preventDefault()

    const Carimageid = v4();

    const imgRef = ref(imageDb, `profile/${Carimageid}`)
    await uploadBytes(imgRef, img2)
    const cvUrl = await getDownloadURL(imgRef);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded2 = new URLSearchParams();
    urlencoded2.append("profilepic", cvUrl);
    urlencoded2.append("_id", userinfo._id);

    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded2,
      redirect: 'follow'
    };


    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/updateimage2", requestOptions2)
      .then(response => response.json())
      .then(result => {



        setimg3(result.profilepic)


      })
      .catch(error => console.log('error', error));
    setpopupdetails2(false)



  }
  var sno1 = 1
  var sno2 = 1

  return (<>


    {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ?
      <>



        <Navbar />
        {cout()}
        <div className='deshboardmain' style={{ backgroundColor: backgroundcolor }}>
          <Sidemanu />
          <div className='profilemainbody'>





            {popup === true && <>
              <div onClick={() => {
                setpopup(false)
                setpopup2(false)
                setpopup3(false)
                setpopup4(false)

              }
              } className='popupbg'></div>
              <div className='popup'>









                <div className='bownpopupbutton'>


                  {popup2 === false && <>

                    {popup3 === false && <>


                      <form onSubmit={(e) => {
                        e.preventDefault()
                        setpopup2(true)
                      }} className='profileeditingform'>

                        <h3>Edit Profile</h3>
                        <div className='editprofileinputs'>
                          <label>Name :<input required value={editname} onChange={(e) => seteditname(e.target.value)} /></label>
                          <label>Email :<input required value={editemail} onChange={(e) => seteditemail(e.target.value)} /></label>
                          <label>Address :<input required value={editaddres} onChange={(e) => seteditaddres(e.target.value)} /></label>
                          <label>Phone :<input required value={editphone} onChange={(e) => seteditphone(e.target.value)} /></label>
                          <label>billing post code :<input required value={billingpostcode} onChange={(e) => setbillingpostcode(e.target.value)} style={{ width: '70%' }} /></label>

                          <label style={{ alignItems: 'baseline' }}>About :<textarea required onChange={(e) => seteditabout(e.target.value)} value={editabout} /></label>
                        </div>


                        <div className='buttonnnnn'>

                          <button onClick={() => {
                            setpopup(false)
                            setpopup2(false)

                          }
                          } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                          <button style={{ backgroundColor: '#4180b7' }}>Update</button>
                        </div>

                      </form>
                    </>}


                    {popup3 === true && <>


                      <form onSubmit={(e) => {
                        e.preventDefault()

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                        var urlencoded = new URLSearchParams();
                        urlencoded.append("_id", id);
                        urlencoded.append("password", password);
                        urlencoded.append("newpassword", editpassword);

                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };

                        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileeditpassword", requestOptions)
                          .then(response => response.json())
                          .then(result => {
                            if (result === "Password is incorrect.") { message.error("Password is incorrect.") } else {
                              setuserinfo(result)
                              message.success("Password has been changed")
                              setpopup(false)
                              setpopup3(false)
                            }
                          })
                          .catch(error => console.log('error', error));











                      }} className='profileeditingform'>

                        <h3>Edit Profile</h3>
                        <div className='editprofileinputs' style={{ gap: '10px' }}>
                          <label style={{ marginBottom: '50px' }}>Old Password :<input type='password' required value={password} onChange={(e) => setpassword(e.target.value)} style={{ width: '70%' }} /></label>
                          <label>New Password :<input required type='password' value={editpassword} onChange={(e) => seteditpassword(e.target.value)} style={{ width: '70%' }} /></label>
                          <label>Confirm Password :<input required type='password' title='Password does not Match' pattern={editpassword} style={{ width: '70%' }} /></label>

                        </div>


                        <div className='buttonnnnn'>

                          <button onClick={() => {
                            setpopup(false)
                            setpopup2(false)
                            setpopup3(false)

                          }
                          } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                          <button style={{ backgroundColor: '#4180b7' }}>Update</button>
                        </div>

                      </form>
                    </>}

                  </>}



                  {popup2 === true && <>
                    <form className='profileeditingform' onSubmit={subminupdatedata}>

                      <h3>Please Enter your Password to Continue</h3>
                      <div className='editprofileinputs'>
                        <label>Password :<input type='password' required value={password} onChange={(e) => setpassword(e.target.value)} /></label>
                      </div>


                      <div className='buttonnnnn'>

                        <button onClick={() => {
                          setpopup(false)
                          setpopup2(false)
                          setpopup3(false)
                        }
                        } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                        <button style={{ backgroundColor: '#4180b7' }}>Continue</button>
                      </div>

                    </form>
                  </>}








                </div>

              </div>
            </>}

            {popupdetails2 === true && <>
              <div onClick={() => {
                setpopupdetails2(false)


              }
              } className='popupbg'></div>
              <div className='popup' style={{ height: '600px !important' }}>

                <div>

                  <Image src={img} alt='' width={"30%"} />
                </div>

                <form onSubmit={updateimage}>
                  <input
                    type='file'
                    onChange={handleImgChange}
                    accept='image/*'
                  />
                  <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Update</button>
                </form>



              </div>
            </>}

            <div className='addbutton' style={{ width: '100%' }}>

              <a onClick={(e) => {
                e.preventDefault()

                setpopup3(true)
                setpopup(true)
              }} href='/' style={{ display: 'flex', marginRight: '10px', alignItems: "center", color: 'red' }}> <AiFillEdit style={{ width: '20px', height: '20px' }} />change password</a>
              <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} onClick={() => setpopup(true)}><AiFillEdit style={{ width: '20px', height: '20px', }} />Edit Profile</button>
            </div>




            <div className='uperprofile'>

              <div className='profilepicture'>
                <Image alt='avatar' src={img3} width={"300px"} />
                <div> <button className='button' onClick={() => {
                  setpopupdetails2(true)


                }} style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}><AiFillEdit style={{ width: '20px', height: '20px' }} />Change Profile Picture</button></div>

              </div>


              <div className='profileabot'>

                <div ><h3 style={{ width: '200px', color: prinarycolor }}>Name :</h3> <p style={{ margin: '0', color: color }}> {userinfo.name ? (<>{userinfo.name}</>) : (<a href='#' onClick={(e) => {
                  e.preventDefault()

                  setpopup(true)
                }}>Click to edit</a>)}</p></div>
                <div ><h3 style={{ width: '200px', color: prinarycolor }}>Email :</h3> <p style={{ margin: '0', color: color }}> {userinfo.email ? (<>{userinfo.email}</>) : (<a onClick={(e) => {
                  e.preventDefault()

                  setpopup(true)
                }} href='#'>Click to edit</a>)}</p></div>
                <div ><h3 style={{ width: '200px', color: prinarycolor }}>phone :</h3> <p style={{ margin: '0', color: color }}> {userinfo.phone ? (<>{userinfo.phone}</>) : (<a onClick={(e) => {
                  e.preventDefault()

                  setpopup(true)
                }} href='#'>Click to edit</a>)}</p></div>
                <div ><h3 style={{ width: '200px', color: prinarycolor }}>address :</h3> <p style={{ margin: '0', color: color }}> {userinfo.address ? (<>{userinfo.address}</>) : (<a onClick={(e) => {
                  e.preventDefault()

                  setpopup(true)
                }} href='#'>Click to edit</a>)}</p></div>
                <div ><h3 style={{ width: '200px', color: prinarycolor }}>billing post code :</h3> <p style={{ margin: '0', color: color }}> {userinfo.billingpostcode ? (<>{userinfo.billingpostcode}</>) : (<a onClick={(e) => {
                  e.preventDefault()

                  setpopup(true)
                }} href='#'>Click to edit</a>)}</p></div>
                <div ><h3 style={{ width: '200px', color: prinarycolor }}>Registered On   :</h3> <p style={{ margin: '0', color: color }}> {userinfo.timestamp ? (<>{userinfo.timestamp}</>) : (<a onClick={(e) => {
                  e.preventDefault()

                  setpopup(true)
                }} href='#'>Click to edit</a>)}</p></div>





                <div style={{ width: '100%', margin: '30px 0' }}>
                  <h2 style={{ color: prinarycolor }}>About Me</h2>
                  <p style={{ width: '100%', margin: '0', color: color }}>{userinfo.about}</p>
                </div>




                {userinfo.CardID === "nocardneeded" ? (<> <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} className='addbutton button' onClick={changecard}>Consignment (Click to add Card)</button>
                </>) : (<>
                  {userinfo.CardID ? (<>
                    <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} className='addbutton button' onClick={changecard}>Change payment Card</button>
                  </>) : (<>
                    <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} className='addbutton button' disabled>The card has not been added yet.</button>

                  </>)}
                </>)}

              </div>

            </div>










            {userinfo._id === "6548f3f51f9aca26d81e0aea" && <>

              <div className='profileinfo2'>






                <div className='header' style={{ width: '100%' }}>
                  <h1 style={{ color: prinarycolor }}> Admins</h1>
                  {dooption()}

                  {searchdone === false && <>
                    <form onSubmit={handleInputChange}>
                      <AutoComplete
                        type="number"
                        style={{ width: 200 }}
                        options={options}
                        placeholder="Search by Email/Name"

                        filterOption={(inputValue, options) =>
                          options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          //  console.log(kitss) 
                        }
                        onChange={(inputValue) => setSearchTerm(inputValue)}
                      />
                      <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Search</button>
                    </form>

                  </>}
                  {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
                  </>}
                </div>





                <div className='addbutton' style={{ width: '100%' }}><button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} onClick={() => setpopup4(true)}><HiUserAdd style={{ width: '20px', height: '20px' }} />Add Admin</button></div>


                {popup4 === true && <>
                  <div onClick={() => {
                    setpopup4(false)
                    setpractitioneremail('')
                    setpractitionername('')
                    setpractitionerpassword('')

                  }
                  } className='popupbg'></div>
                  <div className='popup'>

                    <form onSubmit={addnewPractitioner} className='addPractitioner'>
                      <input autoComplete="none" style={{ borderRadius: '5px' }} type='name' value={practitionername} onChange={(e) => setpractitionername(e.target.value)} required placeholder='Admin Name' />
                      <input autoComplete="none" style={{ borderRadius: '5px' }} onChange={(e) => setpractitioneremail(e.target.value)} type="email" name='new' value={practitioneremail} required placeholder='Admin Email' />
                      <input autoComplete="none" style={{ borderRadius: '5px' }} onChange={(e) => setpractitionerpassword(e.target.value)} type="password" name='nedfvw' value={practitionerpassword} required placeholder='Password for Admin' />


                      <div className='bownpopupbutton' style={{ width: '85%' }}>

                        <button onClick={() => {
                          setpopup4(false)
                          setpractitioneremail('')
                          setpractitionername('')
                          setpractitionerpassword('')
                        }
                        } style={{ border: '1px solid red', color: 'red', backgroundColor: "transparent" }} >cancel</button>
                        <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Add Admin</button>

                      </div>

                    </form>




                  </div>
                </>}








                {(practitioner.length !== 0 && practitioner) && <>
                  <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                    <thead className='tablephead' style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                      <tr>
                        <th>S NO.</th>
                        <th>Name</th>
                        <th>Email</th>

                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                      {practitioner.map((item, index) => (<>






                        {item._id !== "6548f3f51f9aca26d81e0aea" && <>
                          <tr>
                            <td style={{ color: prinarycolor }}>{sno1++}</td>
                            <td style={{ color: prinarycolor }}>{item.name} </td>
                            <td style={{ color: prinarycolor }}>{item.email}</td>







                            <td className='assignbuttom' ><AiFillDelete className='hovar' style={{ width: '30px', height: '30px', color: 'red' }}
                              onClick={() => {

                                var myHeaders = new Headers();
                                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                var urlencoded = new URLSearchParams();
                                urlencoded.append("_id", item._id);

                                var requestOptions = {
                                  method: 'POST',
                                  headers: myHeaders,
                                  body: urlencoded,
                                  redirect: 'follow'
                                };

                                fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltadmin", requestOptions)
                                  .then(response => response.json())
                                  .then(result => setpractitioner(result))
                                  .catch(error => console.log('error', error));







                              }}


                            /></td>
                          </tr>
                        </>}
                      </>))}







                    </tbody>
                  </table>


                </>}




                {practitioner.length === 0 && <>

                  <img alt='' src='/empty.gif' width={"40%"} />
                </>}


















              </div>

            </>}



          </div>


        </div>
      </> : <NotLogin />}
  </>)
}

export default Profile