import React, { useState, useRef, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { AutoComplete, Dropdown, message, Popconfirm } from 'antd'
import { AiFillDelete, AiOutlineClose, AiOutlineInteraction, AiOutlineMail } from 'react-icons/ai'
import { ImUpload2, ImUserTie } from 'react-icons/im'

import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Cookies from 'js-cookie';
import Papa from 'papaparse';
import { GiFirstAidKit } from 'react-icons/gi'
import { FaRegUser } from 'react-icons/fa'
import { TbReportAnalytics, TbUserShare } from 'react-icons/tb'
import { MdOutlineUpdate, MdOutlineViewDay } from 'react-icons/md'
import { IoIosArrowDropdown } from 'react-icons/io'

import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import NotLogin from './notLogin'


function KitReport(params) {

    const fileInputRef = useRef(null);

    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const [kitdetails, setkitdetails] = useState([])
    const [popupdetails, setpopupdetails] = useState(false)

    const [kit, setkit] = useState([])
    const [practitioner, setpractitioner] = useState([])



    const backgroundcolor = Cookies.get("backgroundcolor") || params.backgroundcolor;
    const color = Cookies.get("color") || params.color;
    const Buttonbackgroundcolor = Cookies.get("Buttonbackgroundcolor") || params.Buttonbackgroundcolor;
    const Buttoninsidecolor = Cookies.get("Buttoninsidecolor") || params.Buttoninsidecolor;
    const prinarycolor = Cookies.get("prinarycolor") || params.prinarycolor;
    const prinarycolortext = Cookies.get("prinarycolortext") || params.prinarycolortext;
    const seconderycolor = Cookies.get("seconderycolor") || params.seconderycolor;
    const portalid = Cookies.get("portalid") || params.portalid;


    const loginid = Cookies.get("id")

    const token2 = Cookies.get("Token2")
    const apiurlforbackend = process.env.REACT_APP_APIURL


    const navigate = useNavigate()

    const [kit2, setkit2] = useState([])

    const [kitids, setkitids] = useState([])
    const [page, setpage] = useState(1)
    const [page2, setpage2] = useState(1)

    const [totalPages, settotalPages] = useState(1)
    const [totalPages2, settotalPages2] = useState(1)


    const [practitioner2, setpractitioner2] = useState([])


    const type = params.kittype



    var sno1 = 1 + (15 * (page - 1))

    const [searchTerm, setSearchTerm] = useState('');
    var options = kitids;

    const handleInputChange = async (e) => {
        e.preventDefault()

        setkit([])

        let tokentosend
        if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
            tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
        } else {
            tokentosend = token
        }
        let data = JSON.stringify({

            "type": type,
            "search": searchTerm,
            "token": tokentosend,
            "loginid": loginid,
            "portalid": portalid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/searchkitsforresults',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then(async (response) => {
                await setkit(response.data.data)
                await setpractitioner(response.data.practitioners)
                await setpage(response.data.totalPages)
                await settotalPages(response.data.totalPages)
                await renderPageNumbers()

            })
            .catch((error) => {
                console.log(error);
            });

        setsearchdone(true)

    };




    const [searchdone, setsearchdone] = useState(false)

    const clearsearch = async () => {


        await setkit(kit2)
        await setpractitioner(practitioner2)
        await setpage(page2)
        await settotalPages(totalPages2)
        await renderPageNumbers()

        setsearchdone(false)

    }







    const setkitbyres = async (response) => {

        setkit(response.data.data);
        setkit2(response.data.data);
        setpractitioner(response.data.practitioner);
        setpractitioner2(response.data.practitioner);
        setpage(response.data["current page"])
        setpage2(response.data["current page"])
        settotalPages(response.data["total pages"])
        settotalPages2(response.data["total pages"])


    }






    const getkits = async (page) => {



        let tokentosend
        if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
            tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
        } else {
            tokentosend = token
        }
        let data = JSON.stringify({
            "pagenumber": page,
            "type": type,
            "token": tokentosend,
            "loginid": loginid,
            "portalid": portalid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/getkitsbytypeforresults',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setkitbyres(response)


            })
            .catch((error) => {
                console.log(error);
            });


    }



    const getkitsids = async () => {



        let tokentosend
        if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
            tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
        } else {
            tokentosend = token
        }
        let data = JSON.stringify({

            "type": type,
            "token": tokentosend,
            "loginid": loginid,
            "portalid": portalid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/getallkitidsforreportpage',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setkitids(response.data.kitID);

            })
            .catch((error) => {
                console.log(error);
            });


    }




    useEffect(() => {
        getkits(page)
        getkitsids()

    }, [])




    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
        const startPage = Math.max(1, page - maxPageToShow);
        const endPage = Math.min(totalPages, page + maxPageToShow);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={page === i ? 'activeeee' : ''}
                >
                    {i}
                </span>
            );
        }
        return pageNumbers;
    };



    const handlePageClick = async (pageNumber) => {
        await setkit([])

        await window.scrollTo(0, 0);
        await getkits(pageNumber)

        await renderPageNumbers()
    };













    const items = [
        {
            key: '1',
            label: (
                <a href={`/report/${kitdetails._id}`} target="_blank" rel="noopener noreferrer">
                    View result
                </a>
            ),
            icon: <MdOutlineViewDay style={{ width: '20px', height: '20px', color: '#4885B9' }} />,
        },

        ...(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
            ? [
                {
                    key: '3',
                    label: (

                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={async (e) => {
                                e.preventDefault();


                                let data = JSON.stringify({
                                    pagenumber: page,
                                    type: type,
                                    id: kitdetails._id,
                                    emailtype: "results",
                                    "portalid": portalid,
                                });

                                let config = {
                                    method: "post",
                                    maxBodyLength: Infinity,
                                    url: apiurlforbackend + "/sendemailtoprac",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    data: data,
                                };

                                await axios
                                    .request(config)
                                    .then(() => {
                                        getkits(page);
                                        setpopupdetails(false);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });


                            }}
                        >
                            <p
                                href="#"
                            // onClick=
                            >
                                Send Results To Practitioner
                            </p>
                        </Popconfirm>
                    ),
                    disabled: kitdetails.ackresult,
                    icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,
                },
            ]
            : []),
    ];









    return (
        <>{(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ? <>
            <div>

                <Navbar />

                <div className='deshboardmain' style={{ backgroundColor: backgroundcolor }}>

                    <Sidemanu />
                    <div className='adminkitmainbody'>



                        <div className='header'>
                            <h1 style={{ color: prinarycolor, display: 'flex', alignItems: 'center' }}><TbReportAnalytics style={{ width: '35px', height: '35px' }} />{type} Results </h1>
                            {searchdone === false && <>
                                <form onSubmit={handleInputChange}>


                                    <AutoComplete

                                        style={{ width: 200 }}
                                        options={options}
                                        placeholder="Search by Kit ID"
                                        onChange={(inputValue) => setSearchTerm(inputValue)}
                                        filterOption={(inputValue, options) =>
                                            options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1

                                        }
                                    />
                                    <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Search</button>
                                </form>
                            </>}
                            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
                            </>}
                        </div>











                        {popupdetails === true && <>
                            <div onClick={() => {
                                setpopupdetails(false)

                            }
                            } className='popupbg'></div>
                            <div className='popup'>



                                <div className='header' >
                                    <h2 style={{ color: prinarycolor }}>Kits Result Details</h2>
                                </div>
                                <div className='kitdetailsmain' >




                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <GiFirstAidKit style={{ width: '20px', height: '20px', margin: "0 10px" }} /> KIT ID</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>  {kitdetails.prefix}{kitdetails.kitid} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Name</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>


                                            {practitioner.map((item2, index) => (<>

                                                {item2._id === kitdetails.assignedto && <>

                                                    <td>{item2.name}</td>


                                                </>}</>))}


                                        </h3>
                                    </div>
                                    <div className='kitdetails'>

                                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <TbUserShare style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Email</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>


                                            {practitioner.map((item2, index) => (<>

                                                {item2._id === kitdetails.assignedto && <>



                                                    <td>{item2.email}</td>
                                                </>}</>))}



                                        </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <ImUserTie style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Patient Name</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>  {kitdetails.patientName} </h3>
                                    </div>
                                    {/* <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <BsCalendar2DateFill style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Date of birth</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>  {kitdetails.DOB} </h3>
                </div> */}
                                    {/* <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <MdOutlinePendingActions style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Sample Date</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>  {kitdetails.SampleDate} </h3>
                </div> */}
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <MdOutlineUpdate style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Result upload on</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>  {kitdetails.resultDate} </h3>
                                    </div>
                                    <div className='kitdetails'><h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <AiOutlineInteraction style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>


                                        <Dropdown menu={{ items: items }} placement="bottomRight" arrow>
                                            <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                                        </Dropdown>


                                    </h3></div>





                                </div>




                                <div className='bownpopupbutton'>
                                    <button onClick={() => {
                                        setpopupdetails(false)

                                    }
                                    } style={{ border: '1px solid red', color: 'black' }} >cancel</button>












                                </div>

                            </div>
                        </>}








                        {kit.length !== 0 && <>



                            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                                <thead className='tablephead' style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                                    <tr>
                                        <th>S NO.</th>

                                        <th>Kit ID</th>



                                        {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>      <th>Practitioner Name</th></>}
                                        {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>       <th>Practitioner Email</th></>}
                                        {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>     <th>Patient Name</th></>}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    {kit.map((item, index) =>

                                        <>
                                            <tr >
                                                <td style={{ color: prinarycolor }}>{sno1++} </td>

                                                <td style={{ color: prinarycolor }}>{item.prefix}{item.kitid}</td>




                                                {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>

                                                    {practitioner.map((item2, index) => (<>

                                                        {item2._id === item.assignedto && <>
                                                            <td style={{ color: prinarycolor }}>{item2.name}</td>

                                                            <td style={{ color: prinarycolor }}>{item2.email}</td>
                                                        </>}</>))}

                                                </>}
                                                {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>
                                                    <td style={{ color: prinarycolor }}>
                                                        {item.patientName}
                                                    </td>
                                                </>}
                                                <td  >
                                                    <button className='button' style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} onClick={() => {
                                                        setpopupdetails(true)
                                                        setkitdetails(item)
                                                    }}>Detail</button>
                                                </td>




                                            </tr>
                                        </>
                                    )}



                                </tbody>
                            </table>




                        </>}


                        {kit.length === 0 && <>
                            {totalPages === 0 ? (<>
                                <img alt='' src='/empty2.gif' width={"35%"} />
                            </>) : (<> <img alt='' src='/empty.gif' width={"40%"} /> </>)}
                        </>}
                        <div className="pagination">
                            {page > 1 && (
                                <span onClick={() => handlePageClick(page - 1)}>&lt;&lt;</span>
                            )}
                            {renderPageNumbers()}
                            {page < totalPages && (
                                <span onClick={() => handlePageClick(page + 1)}>&gt;&gt;</span>
                            )}
                        </div>






                    </div>


                </div>

            </div>
        </> : <NotLogin />}</>
    )
}

export default KitReport