import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import Sidemanu from "../components/sidemanu";
import "../css/deshboard.css";
import NotLogin from "../components/notLogin";
import {
  ResponsiveContainer,

  XAxis,
  YAxis,
  Tooltip,

} from "recharts";
import { LineChart, Line, CartesianGrid, Legend } from "recharts";

import Cookies from "js-cookie";
import { AiOutlineClose } from "react-icons/ai";

import {
  AutoComplete,

} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Desboard(params) {
  const loginname = Cookies.get("Name");
  const id = Cookies.get("id");
  const token = Cookies.get("Token");
  const token2 = Cookies.get("Token2");
  const loginemail = Cookies.get("email");


  const backgroundcolor = Cookies.get("backgroundcolor") || params.backgroundcolor;
  const color = Cookies.get("color") || params.color;
  const Buttonbackgroundcolor = Cookies.get("Buttonbackgroundcolor") || params.Buttonbackgroundcolor;
  const Buttoninsidecolor = Cookies.get("Buttoninsidecolor") || params.Buttoninsidecolor;
  const prinarycolor = Cookies.get("prinarycolor") || params.prinarycolor;
  const prinarycolortext = Cookies.get("prinarycolortext") || params.prinarycolortext;
  const seconderycolor = Cookies.get("seconderycolor") || params.seconderycolor;
  const portalid = Cookies.get("portalid") || params.portalid;

  const foodSensitivity = Cookies.get("foodSensitivity") || params.foodSensitivity;
  const foodSensitivityMap = Cookies.get("foodSensitivityMap") || params.foodSensitivityMap;
  const microbiome = Cookies.get("microbiome") || params.microbiome;
  const dnaMap = Cookies.get("dnaMap") || params.dnaMap;
  const parasitologyTest = Cookies.get("parasitologyTest") || params.parasitologyTest;
  const candidaProfile = Cookies.get("candidaProfile") || params.candidaProfile;
  const allPractitioners = Cookies.get("allPractitioners") || params.allPractitioners;
  const academy = Cookies.get("academy") || params.academy;

  const [practitioner, setpractitioner] = useState([]);
  const [practitioner2, setpractitioner2] = useState([]);
  const [practitioner3, setpractitioner3] = useState([]);

  const [practitioner22, setpractitioner22] = useState([]);




  const [mb, setmb] = useState(0);



  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);



  const currentYear = new Date().getFullYear();

  const [kitdata, setkitdata] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  var options = [];

  const dooption = () => {
    practitioner2.map((value, index) => {


      options.push({ value: value.name });
      options.push({ value: value.email });

    });
  };
  var sno1 = 1;

  const handleInputChange = (e) => {
    e.preventDefault();

    const intttt = searchTerm;

    // Filter the Kit array based on the user's input
    const filteredSuggestions = practitioner2.filter(
      (item) =>
        item.name.toLowerCase().includes(intttt.toLowerCase()) ||
        item.email.toLowerCase().includes(intttt.toLowerCase())
    );

    setpractitioner2(filteredSuggestions);
    setsearchdone(true);
  };

  const [searchdone, setsearchdone] = useState(false);

  const clearsearch = () => {

    console.log(practitioner22)
    setpractitioner2(practitioner22)
    setsearchdone(false)
  };


  const navigate = useNavigate();

  const circleresultdata = () => {
    const percentage = Math.round(
      (kitdata["ANALYSIS PENDING"] / kitdata["Total Kits"]) * 100
    );
    return 100 - percentage;
  };

  let circleSize = 350;
  let strokeWidth = 75;

  if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
    circleSize = 350;
    strokeWidth = 75;
  }

  const completed = circleresultdata();
  const radius = (circleSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const completedOffset = circumference - (completed / 100) * circumference;

  // Calculate angles for completed and pending labels
  const completedAngle = (360 * completed - 1500) / 100;
  const pendingAngle = completedAngle + 30;

  // Convert angles to radians
  const completedX =
    circleSize / 2 + radius * Math.cos((completedAngle - 90) * (Math.PI / 180));
  const completedY =
    circleSize / 2 +
    radius * Math.sin((completedAngle - 90) * (Math.PI / 180)) +
    5;

  const pendingX =
    circleSize / 2 + radius * Math.cos((pendingAngle - 90) * (Math.PI / 180));
  const pendingY =
    circleSize / 2 +
    radius * Math.sin((pendingAngle - 90) * (Math.PI / 180)) +
    5;

  const getkitsvalue = (condition) => {
    let width = 0;
    let value = 0;
    let percentage = 4;

    if (condition === "TOTAL KITS") {
      value = kitdata["Total Kits"];
      width = (value / kitdata["Total Kits"]) * 100;

      percentage = (width / 100) * (94 - 4) + 4;
    } else if (condition === "NOT ASSIGNED YET") {
      value = kitdata["UNASSIGNED KITS"];
      width = (kitdata["UNASSIGNED KITS"] / kitdata["Total Kits"]) * 100;

      percentage = (width / 100) * (94 - 4) + 4;
    } else if (condition === "ASSIGNED KITS") {
      value = kitdata["ASSIGNED KITS"];
      width = (kitdata["ASSIGNED KITS"] / kitdata["Total Kits"]) * 100;

      percentage = (width / 100) * (94 - 4) + 4;
    } else if (condition === "PAID KITS") {
      value = kitdata["PAID KITS"];
      width = (kitdata["PAID KITS"] / kitdata["Total Kits"]) * 100;

      percentage = (width / 100) * (94 - 4) + 4;
    } else if (condition === "UNPAID KITS") {
      value = kitdata["UNPAID KITS"];
      width = (kitdata["UNPAID KITS"] / kitdata["Total Kits"]) * 100;

      percentage = (width / 100) * (94 - 4) + 4;
    } else if (condition === "ANALYSIS PENDING") {
      value = kitdata["ANALYSIS PENDING"];
      width = (kitdata["ANALYSIS PENDING"] / kitdata["Total Kits"]) * 100;

      percentage = (width / 100) * (94 - 4) + 4;
    } else if (condition === "ANALYSIS COMPLETED") {
      value = kitdata["ANALYSIS COMPLETED"];
      width = (kitdata["ANALYSIS COMPLETED"] / kitdata["Total Kits"]) * 100;

      percentage = (width / 100) * (94 - 4) + 4;
    }

    return [percentage, value];
  };

  const PractitionerSignUpChart = () => {
    return (
      <ResponsiveContainer
        style={{
          backgroundColor: seconderycolor,
          padding: "10px 2%",
          borderRadius: "10px",
        }}
        width="94%"
        height={400}
      >
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* <Line
            type="monotone"
            dataKey="signUp2023"
            stroke={`${prinarycolor}`}
            strokeDasharray="5 5"
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            name={"Practitioners Sign Up in " + (currentYear - 1)}
          /> */}
          <Line
            type="monotone"
            dataKey="signUp2024"
            stroke={`${prinarycolor}`}

            dot={{ fill: `${prinarycolor}`, r: 5 }}
            name={"New Practitioners Monthly"}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };


  const PractitionerSignUpChart2 = () => {
    return (
      <ResponsiveContainer
        style={{
          backgroundColor: seconderycolor,
          padding: "10px 2%",
          borderRadius: "10px",
        }}
        width="94%"
        height={400}
      >
        <LineChart data={data2}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend
            iconType="circle" // Show dot instead of line
            wrapperStyle={{ fontSize: "14px" }} // Adjust styling if needed
          />

          <Line
            type="monotone"
            dataKey="totalPractitioners"
            stroke={`${prinarycolor}`}
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            name={"Total Practitioners"}
          />

          {/* <Line
            type="monotone"
            dataKey="new"
            stroke={`${prinarycolor}`}
            strokeDasharray="5 5"
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            name={"New Practitioners Sign Up in this months"}
          /> */}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const PractitionerSignUpChart3 = () => {
    return (
      <ResponsiveContainer
        style={{
          backgroundColor: seconderycolor,
          padding: "10px 2%",
          borderRadius: "10px",
        }}
        width="94%"
        height={400}
      >
        <LineChart data={data3}>
          <CartesianGrid strokeDasharray="3 3" />

          {/* Remove X-Axis labels */}
          <XAxis dataKey="Title" tick={false} axisLine={false} />

          <YAxis />
          <Tooltip />
          <Legend
            iconType="circle" // Show dot instead of line
            wrapperStyle={{ fontSize: "14px" }} // Adjust styling if needed
          />

          {/* Lines with dots */}
          <Line
            type="monotone"
            dataKey="newUsers"
            stroke={`${prinarycolor}`}
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            name={"New Webinar Attendees"}
          />

          <Line
            type="monotone"
            dataKey="oldUsers"
            stroke={`${prinarycolor}`}
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            strokeDasharray="5 5"
            name={"Old Webinar Attendees"}
          />


          <Line
            type="monotone"
            dataKey="total"
            stroke={`${prinarycolor}`}
            strokeDasharray="10 15"
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            name={"Total"}
          />
        </LineChart>
      </ResponsiveContainer>

    );
  };

  const PractitionerSignUpChart32 = () => {
    return (
      <ResponsiveContainer
        style={{
          backgroundColor: seconderycolor,
          padding: "10px 2%",
          borderRadius: "10px",
        }}
        width="94%"
        height={400}
      >
        <LineChart data={data4}>
          <CartesianGrid strokeDasharray="3 3" />

          {/* Remove X-Axis labels */}
          <XAxis dataKey="Title" tick={false} axisLine={false} />

          <YAxis />
          <Tooltip />
          <Legend
            iconType="circle" // Show dot instead of line
            wrapperStyle={{ fontSize: "14px" }} // Adjust styling if needed
          />

          {/* Lines with dots */}
          <Line
            type="monotone"
            dataKey="newUsers"
            stroke={`${prinarycolor}`}
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            name={"New Webinar Registrations"}
          />

          <Line
            type="monotone"
            dataKey="oldUsers"
            stroke={`${prinarycolor}`}
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            strokeDasharray="5 5"
            name={"Previous Webinar Registrations "}
          />


          <Line
            type="monotone"
            dataKey="total"
            stroke={`${prinarycolor}`}
            strokeDasharray="10 15"
            dot={{ fill: `${prinarycolor}`, r: 5 }}
            name={"Total"}
          />
        </LineChart>
      </ResponsiveContainer>

    );
  };

  const getkitdata = async () => {
    let data = JSON.stringify({
      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/getdashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setkitdata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getkitdata2 = async () => {
    let data = JSON.stringify({
      id: id,
      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/getdashboardbyid",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setkitdata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });









  };


  const getkitdata3 = async () => {


    let data = JSON.stringify({
      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/get-prac-withzerokit",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then(async (response) => {
        await setpractitioner2(response.data);
        await setpractitioner22(response.data);
      })
      .catch((error) => {
        console.log(error);
      });









  };


  const getkitdata4 = async () => {

    let data = JSON.stringify({
      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/get-top10-prac-kitcount",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    await axios
      .request(config)
      .then(async (response) => {
        await setpractitioner3(response.data);

      })
      .catch((error) => {
        console.log(error);
      });









  };


  const getkitdata5_neweventuser = async () => {


    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/get-webinar-user-states",
      headers: {
        "Content-Type": "application/json",
      },

    };

    await axios
      .request(config)
      .then(async (response) => {
        await setData3(response.data);

      })
      .catch((error) => {
        console.log(error);
      });









  };

  const getkitdata5_neweventuser2 = async () => {


    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/get-webinar-user-states2",
      headers: {
        "Content-Type": "application/json",
      },

    };

    await axios
      .request(config)
      .then(async (response) => {
        await setData4(response.data);

      })
      .catch((error) => {
        console.log(error);
      });









  };

  const getkitdata5_neweventprac = async () => {


    let data = JSON.stringify({
      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/get-pracdata",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then(async (response) => {
        await setData(response.data);

      })
      .catch((error) => {
        console.log(error);
      });









  };

  const getkitdata5_neweventprac2 = async () => {


    let data = JSON.stringify({
      portalid: portalid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/get-pracdata2",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then(async (response) => {
        await setData2(response.data);

      })
      .catch((error) => {
        console.log(error);
      });









  };

  useEffect(() => {
    if (
      token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
      token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd"
    ) {
      getkitdata();
      getkitdata3();
      getkitdata4();
      getkitdata5_neweventprac();
      getkitdata5_neweventprac2();
      getkitdata5_neweventuser();
      getkitdata5_neweventuser2();
    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {
      getkitdata2();

    }
  }, []);

  return (
    <>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ||
        token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ? (
        <>
          <Navbar />

          <div className="deshboardmain" style={{ backgroundColor: backgroundcolor }}>
            <Sidemanu />

            <div className="mainbody">
              <div style={{ width: "100%", height: "fit-content" }}>

                <div className="kitscouts">

                  {(foodSensitivity === "true" || foodSensitivity === true) && <>
                    <div
                      className="boxxxx"
                      onClick={() => navigate("/dashboard/fs210/kit")}
                    >
                      <h5 className="bbbbg" style={{ backgroundColor: prinarycolor, color: prinarycolortext, color: prinarycolortext }}>FOOD SENSITIVITY KITS</h5>{" "}
                      <h2 style={{ color: prinarycolor }}>{kitdata["FOOD SENSITIVITY KITS"]}</h2>
                    </div>
                  </>}

                  {(foodSensitivityMap === "true" || foodSensitivityMap === true) && (<>
                    <div
                      className="boxxxx"
                      onClick={() => navigate("/dashboard/fsmap/kit")}
                    >
                      <h5 className="bbbbg" style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>FOODSENSITIVITYMAP KITS</h5>{" "}
                      <h2 style={{ color: prinarycolor }}>{kitdata["FOOD SENSITIVITY MAP KITS"]}</h2>
                    </div>
                  </>)}

                  {(microbiome === "true" || microbiome === true) && (<>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                      <>
                        {" "}
                        <div
                          className="boxxxx"
                          onClick={() => navigate("/dashboard/microbiome/kit")}
                        >
                          <h5 className="bbbbg" style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>MICROBIOME KITS </h5>
                          <h2 style={{ color: prinarycolor }}> {mb}</h2>
                        </div>
                      </>
                    )}
                  </>)}
                  {(dnaMap === "true" || dnaMap === true) && (<>
                    <div
                      className="boxxxx"
                      onClick={() => navigate("/dashboard/dna/kit")}
                    >
                      <h5 className="bbbbg" style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>DNAMAP KITS </h5>
                      <h2 style={{ color: prinarycolor }}> {kitdata["DNA MAP KITS"]}</h2>
                    </div>
                  </>)}
                  {(parasitologyTest === "true" || parasitologyTest === true) && (<>
                    <div
                      className="boxxxx"
                      onClick={() => navigate("/dashboard/Parasitology/kit")}
                    >
                      <h5 className="bbbbg" style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>PARASITOLOGY KITS </h5>
                      <h2 style={{ color: prinarycolor }}> {kitdata["PARASITOLOGY KITS"]}</h2>
                    </div>
                  </>)}
                  {(candidaProfile === "true" || candidaProfile === true) && (<>
                    <div
                      className="boxxxx"
                      onClick={() => navigate("/dashboard/candidaprofile/kit")}
                    >
                      <h5 className="bbbbg" style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>CANDIDAPROFILE KITS </h5>
                      <h2 style={{ color: prinarycolor }}> {kitdata["CANDIDA KITS"]}</h2>
                    </div>
                  </>)}
                </div>

                <div className="kitsbarssandcircle">
                  <div className="kitsbarrr">
                    <div className="barmain" style={{ backgroundColor: seconderycolor }}>
                      <div className="barfill" >
                        <div
                          className="barfill2"
                          style={{ backgroundColor: prinarycolor, color: prinarycolortext, width: `${getkitsvalue("TOTAL KITS")[0]}%` }}
                        >
                          <h3>{getkitsvalue("TOTAL KITS")[1]}</h3>
                        </div>
                      </div>
                      <div className="barname" style={{ color: prinarycolor }}>
                        <h3>TOTAL KITS</h3>
                      </div>
                    </div>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                      <>
                        <div className="barmain" style={{ backgroundColor: seconderycolor }}>
                          <div className="barfill">
                            <div
                              className="barfill2"
                              style={{
                                backgroundColor: prinarycolor, color: prinarycolortext,
                                width: `${getkitsvalue("ASSIGNED KITS")[0]}%`,
                              }}
                            >
                              <h3>{getkitsvalue("ASSIGNED KITS")[1]}</h3>
                            </div>
                          </div>
                          <div className="barname" style={{ color: prinarycolor }}>
                            <h3> ASSIGNED KITS</h3>
                          </div>
                        </div>

                        <div className="barmain" style={{ backgroundColor: seconderycolor }}>
                          <div className="barfill">
                            <div
                              className="barfill2"
                              style={{
                                backgroundColor: prinarycolor, color: prinarycolortext,
                                width: `${getkitsvalue("NOT ASSIGNED YET")[0]
                                  }%`,
                              }}
                            >
                              <h3>{getkitsvalue("NOT ASSIGNED YET")[1]}</h3>
                            </div>
                          </div>
                          <div className="barname" style={{ color: prinarycolor }}>
                            <h3> NOT ASSIGNED YET</h3>
                          </div>
                        </div>
                      </>
                    )}

                    {/* {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <> */}
                    <div className="barmain" style={{ backgroundColor: seconderycolor }}>
                      <div className="barfill">
                        <div
                          className="barfill2"
                          style={{
                            backgroundColor: prinarycolor, color: prinarycolortext,
                            width: `${getkitsvalue("ANALYSIS COMPLETED")[0]}%`,
                          }}
                        >
                          <h3>{getkitsvalue("ANALYSIS COMPLETED")[1]}</h3>
                        </div>
                      </div>
                      <div className="barname" style={{ color: prinarycolor }}>
                        <h3> ANALYSIS COMPLETED</h3>
                      </div>
                    </div>

                    <div className="barmain" style={{ backgroundColor: seconderycolor }}>
                      <div className="barfill">
                        <div
                          className="barfill2"
                          style={{
                            backgroundColor: prinarycolor, color: prinarycolortext,
                            width: `${getkitsvalue("ANALYSIS PENDING")[0]}%`,
                          }}
                        >
                          <h3>{getkitsvalue("ANALYSIS PENDING")[1]}</h3>
                        </div>
                      </div>
                      <div className="barname" style={{ color: prinarycolor }}>
                        <h3> ANALYSIS PENDING</h3>
                      </div>
                    </div>
                    {/* </>} */}
                    <div className="barmain" style={{ backgroundColor: seconderycolor }}>
                      <div className="barfill">
                        <div
                          className="barfill2"
                          style={{ backgroundColor: prinarycolor, color: prinarycolortext, width: `${getkitsvalue("PAID KITS")[0]}%` }}
                        >
                          <h3>{getkitsvalue("PAID KITS")[1]}</h3>
                        </div>
                      </div>
                      <div className="barname" style={{ color: prinarycolor }}>
                        <h3> PAID KITS</h3>
                      </div>
                    </div>

                    <div className="barmain" style={{ backgroundColor: seconderycolor }}>
                      <div className="barfill">
                        <div
                          className="barfill2"
                          style={{
                            backgroundColor: prinarycolor, color: prinarycolortext,
                            width: `${getkitsvalue("UNPAID KITS")[0]}%`,
                          }}
                        >
                          <h3>{getkitsvalue("UNPAID KITS")[1]}</h3>
                        </div>
                      </div>
                      <div className="barname" style={{ color: prinarycolor }}>
                        <h3> UNPAID KITS</h3>
                      </div>
                    </div>
                  </div>

                  <div className="kitsresutldcircle">
                    <div
                      style={{
                        position: "relative",
                        width: circleSize,
                        height: circleSize,
                      }}
                    >
                      <svg width={circleSize} height={circleSize}>
                        {/* Pending Segment */}
                        <circle
                          cx={circleSize / 2}
                          cy={circleSize / 2}
                          r={radius}
                          stroke={seconderycolor} // Light purple for pending
                          strokeWidth={strokeWidth}
                          fill="none"
                          strokeDasharray={circumference}
                          strokeDashoffset={0}
                        />
                        {/* Completed Segment */}
                        <circle
                          cx={circleSize / 2}
                          cy={circleSize / 2}
                          r={radius}
                          stroke={prinarycolor} // Dark purple for completed
                          strokeWidth={strokeWidth}
                          fill="none"
                          strokeDasharray={circumference}
                          strokeDashoffset={completedOffset}
                          transform={`rotate(-90 ${circleSize / 2} ${circleSize / 2
                            })`}
                        />

                        {/* Completed Percentage Label */}
                        <text
                          x={completedX}
                          y={completedY}
                          textAnchor="middle"
                          fontSize="18px"
                          fontWeight="bold"
                          fill={prinarycolortext}
                        >
                          {completed}%
                        </text>

                        {/* Pending Percentage Label */}
                        <text
                          x={pendingX}
                          y={pendingY}
                          textAnchor="middle"
                          fontSize="18px"
                          fontWeight="bold"
                          fill={prinarycolor}
                        >
                          {100 - completed}%
                        </text>
                      </svg>

                      {/* Center Text for Analysis Labels */}
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                          fontSize: "12px",
                          color: { prinarycolor },
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            backgroundColor: prinarycolor, color: prinarycolortext,
                            color: prinarycolortext,
                            padding: "4px 5px",
                            borderRadius: "8px",
                            marginBottom: "4px",
                          }}
                        >
                          ANALYSIS COMPLETED
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            backgroundColor: seconderycolor,
                            color: prinarycolor,
                            padding: "4px 8px",
                            borderRadius: "8px",
                          }}
                        >
                          ANALYSIS PENDING
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                <>
                  <div className="piechars">
                    <div className="div1">
                      <div className="header" style={{ marginBottom: "10px" }}>
                        <h2 className="pppheading" style={{ color: prinarycolor }}>
                          {" "}
                          Practitioners who have never purchased a test
                        </h2>
                        {dooption()}

                        {searchdone === false && (
                          <>
                            <form onSubmit={handleInputChange}>
                              <AutoComplete
                                type="number"
                                style={{ width: 200 }}
                                options={options}
                                placeholder="Search by Email/Name"
                                filterOption={
                                  (inputValue, options) =>
                                    options.value
                                      .toString()
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  //  console.log(kitss)
                                }
                                onChange={(inputValue) =>
                                  setSearchTerm(inputValue)
                                }
                              />
                              <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Search</button>
                            </form>
                          </>
                        )}
                        {searchdone === true && (
                          <>
                            {" "}
                            <div className="clearsearch">
                              <h3>search: {searchTerm}</h3>{" "}
                              <button onClick={clearsearch}>
                                <AiOutlineClose /> Clear
                              </button>{" "}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="imp">
                        <table
                          className="tablep"
                          cellPadding={"10"}
                          cellSpacing={"0"}
                        >
                          <thead className="tablephead" style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                            <tr>
                              <th>S NO.</th>
                              <th>NAME</th>
                              <th>EMAIL</th>
                            </tr>
                          </thead>
                          <tbody >
                            {practitioner2.map((kit, index) => (
                              <>

                                <tr >
                                  <td style={{ color: prinarycolor }}>{sno1++}</td>
                                  <td style={{ color: prinarycolor }}>{kit.name} </td>
                                  <td style={{ color: prinarycolor }}>{kit.email} </td>
                                </tr>

                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "400px",
                      marginTop: "100px",
                    }}
                  >
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                      <>
                        <PractitionerSignUpChart />
                      </>
                    )}

                  </div>


                  <div
                    style={{
                      width: "100%",
                      height: "400px",
                      marginTop: "100px",
                    }}
                  >
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                      <>
                        <PractitionerSignUpChart2 />
                      </>
                    )}

                  </div>

                  {(academy === "true" || academy === true) && (<>

                    <div
                      style={{
                        width: "100%",
                        height: "400px",
                        marginTop: "100px",
                      }}
                    >
                      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                        <>
                          <PractitionerSignUpChart3 />
                        </>
                      )}

                    </div>
                  </>)}


                  {(academy === "true" || academy === true) && (<>
                    <div
                      style={{
                        width: "100%",
                        height: "400px",
                        marginTop: "100px",
                      }}
                    >
                      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                        <>
                          <PractitionerSignUpChart32 />
                        </>
                      )}

                    </div>
                  </>)}

                </>
              )}



              {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                <>
                  <div
                    style={{
                      width: "100%",
                      height: "fit-content",
                      marginTop: "50px",
                    }}
                  >

                    <h2 style={{ color: prinarycolor }}>
                      Top Practitioners By Kits{" "}
                    </h2>
                    <h2 style={{ color: color }}>All Time</h2>


                    <div className="kitsbarrr" style={{ width: "100%" }}>


                      {practitioner3.map((value) => {
                        const bigestnumber = practitioner3[0].totalKits;
                        let width;
                        let percentage;

                        width = (value.totalKits / bigestnumber) * 100;

                        // Scale width to be between 4 and 94
                        percentage = (width / 100) * (94 - 4) + 4;

                        return (
                          <>
                            <div className="barmain" style={{ backgroundColor: seconderycolor }}>
                              <div className="barfill">
                                <div
                                  className="barfill2"
                                  style={{ backgroundColor: prinarycolor, color: prinarycolortext, width: `${percentage}%` }}
                                >
                                  <h3>{value.totalKits}</h3>
                                </div>
                              </div>
                              <div className="barname" style={{ color: prinarycolor }}>
                                <h3>{value.name}</h3>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) :

        <NotLogin />


      } {" "}
    </>
  );
}

export default Desboard;
