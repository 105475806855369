import React, { useState, useEffect, useRef, useMemo } from 'react'
import Navbar from '../../components/navbar';
import Sidemanu from '../../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Checkbox, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../../css/sendemail.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaFileArrowDown } from 'react-icons/fa6';
import { ImCross } from 'react-icons/im';
import ReactQuill, { Quill } from 'react-quill';
import Papa from 'papaparse';
import { ImUpload2, ImUserTie } from 'react-icons/im'
import NotLogin from '../../components/notLogin';
import { SiMinutemailer } from 'react-icons/si';
import 'react-quill/dist/quill.snow.css';
import { imageDb } from '../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
const Delta = Quill.import("delta")


function Singalemail() {



    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const token2 = Cookies.get("Token2")
    const [subject, setsubject] = useState("")




    const [from, setfrom] = useState("lab@yourgutmap.co.uk")
    const [Template, setTemplate] = useState([])


    const [to, setto] = useState("")
    const [trackemail, settrackemail] = useState(false)
    const [saveemail, setsaveemail] = useState(false)
    const [emailstyles, setemailstyles] = useState(false)

    const [loading, setloading] = useState(false)
    const apiurlforbackend = process.env.REACT_APP_APIURL;

    const [content, setContent] = useState('');

    const quillRef = useRef(null);



    const sendEmail = async (e) => {
        e.preventDefault();
        if (!content.trim()) {
            message.error("Please enter the email content");
            return;
        }

       
        // Modify the content to add style to img tags
        const modifiedContent = content.replace(/<img /g, '<img style="width: 100%;" ');

        // Use the modified content for sending the email
      
        setloading(true)


      
        try {
            await axios.post(apiurlforbackend + '/send-singal-email', { to, subject, content:modifiedContent, trackemail, from, loginname, loginemail, saveemail,emailstyles });
            message.success("Email sent successfully");

            setto("")

            settrackemail(false)
        } catch (error) {
            message.error("Failed to send email");
        }
        setloading(false)
    };


    const imageHandler = () => {
        console.log("Image button clicked");
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (!file) return;
            const date = Date.now();
            const imgRef = ref(imageDb, `emailimages/${date}-${file.name}`);

            // Upload image to Firebase
            await uploadBytes(imgRef, file);
            const filefirebaseurl = await getDownloadURL(imgRef);

            const quill = quillRef.current?.getEditor();
            const range = quill.getSelection();

            if (quill) {
                quill.insertEmbed(range.index, "image", filefirebaseurl);

               
            }
        };
    };


    const attachmentHandler = () => {
        console.log("Attachment button clicked");
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (!file) return;
            const date = Date.now();
            const fileRef = ref(imageDb, `emailattachments/${date}-${file.name}`);

            // Upload file to Firebase
            await uploadBytes(fileRef, file);
            const fileUrl = await getDownloadURL(fileRef);

            const quill = quillRef.current?.getEditor();
            const range = quill.getSelection();

            // Custom styled attachment block
            const attachmentHTML = `
                <br>
                <a href='${fileUrl}' target="_blank"
                    style="display: flex; text-decoration: none; align-items: center; padding: 10px; 
                    border: 2px solid #ddd; border-radius: 8px; background-color: #f9f9f9; 
                    width: 300px; cursor: pointer;">
                    <img src="https://img.icons8.com/ios-filled/50/000000/pdf.png" alt="PDF Icon"
                        style="width: 40px; height: 40px; margin-right: 10px;">
                    <div>
                        <p style="margin: 0; font-size: 16px; color: #333;">${file.name}</p>
                    </div>
                </a>
                <br>
            `;

            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(range.index, attachmentHTML);
                quill.setSelection(range.index + 1);
            }
        };
    };


    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ header: [1, 2, 3] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ["link", "image", "custom-attachment"],  // Add attachment button
            ],
            handlers: {
                image: imageHandler,
                "custom-attachment": attachmentHandler, // Custom attachment handler
            },
        },
    }), []); // Ensures this object is not recreated on each render

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "list",
        "bullet",
        "link",
        "image",

    ];



    // useEffect(() => {
    //     if (quillRef.current) {
    //         quillRef.current.getEditor().clipboard.addMatcher("img", (node, delta) => {
    //             return new Delta(); // Prevents image insertion
    //         });
    //     }
    // }, []);
    useEffect(() => {
        const toolbar = document.querySelector(".ql-toolbar");
        if (toolbar) {
            const button = document.createElement("button");
            button.innerHTML = "📎"; // Paperclip icon
            button.type = "button"; // Paperclip icon
            button.className = "ql-custom-attachment"; // Custom class
            button.onclick = attachmentHandler;
            toolbar.appendChild(button);
        }
    }, [loading]);





    const gettemp = async () => {



        try {
            const response = await axios.post(apiurlforbackend + '/get-email-template', { tempfor: 'all' });
            await setTemplate(response.data)

        } catch (error) {
            message.error("Failed to send email");
        }

    };


    useEffect(() => {
        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53")
            gettemp()

    }, [])

    return (
        <div>
            {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") ?
                <>



                    <Navbar />

                    <div className='deshboardmain'>
                        <Sidemanu />

                        <div className='mainbody' style={{ paddingTop: "20px" }}>


                            <h1
                                style={{
                                    color: "#6E4E9F",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <SiMinutemailer style={{ width: "35px", height: "35px" }} />
                                Send Email
                            </h1>

                            <form onSubmit={sendEmail} className='emailbody' style={{ marginBottom: "25px" }}>

                                {loading ? (<>

                                    <img src='/empty.gif' width={"50%"} />
                                </>) : (<>
                                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                                        <h3>To:</h3>
                                        <input required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={to} onChange={(e) => setto(e.target.value)} />
                                        <p style={{ padding: '0', margin: '0', fontSize: '0.8em' }}><strong>NOTE:</strong> If you want to send to multiple emails just put <strong>( , )</strong> at the end of each email and type next email.</p>
                                    </div>


                                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                                        <h3>From:</h3>
                                        {/* <input required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={to} onChange={(e) => setto(e.target.value)} /> */}
                                        <select required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", width: "32%", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={from} onChange={(e) => setfrom(e.target.value)}>
                                            <option selected value={"lab@yourgutmap.co.uk"}>lab@yourgutmap.co.uk</option>
                                            <option value={"info@yourgutmap.co.uk"}>info@yourgutmap.co.uk</option>
                                            {loginemail !== "josh@yourgutmap.co.uk" && <> <option value={"josh@yourgutmap.co.uk"}>josh@yourgutmap.co.uk</option></>}
                                            {loginemail !== "dominic@yourgutmap.co.uk" && <> <option value={"dominic@yourgutmap.co.uk"}>dominic@yourgutmap.co.uk</option></>}
                                        </select>

                                        <p style={{ padding: '0', margin: '0', fontSize: '0.8em' }}><strong>NOTE:</strong> This email ({from}) will be used to send email.  </p>
                                    </div>


                                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                                        <h3>Email Subject:</h3>
                                        <input required className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={subject} onChange={(e) => setsubject(e.target.value)} />

                                    </div>



                                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                                        <h3>Template:</h3>
                                        {/* <input required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={to} onChange={(e) => setto(e.target.value)} /> */}
                                        <select
                                            onChange={(e) => {
                                                const selectedTemplate = Template.find((template) => template._id === e.target.value);

                                                if (selectedTemplate && selectedTemplate.content) {


                                                    setContent(selectedTemplate.content);
                                                    setsubject(selectedTemplate.subject);
                                                    console.log(selectedTemplate.content);
                                                }
                                            }}
                                            className="formalinput"
                                            placeholder="Enter Email"
                                            style={{
                                                borderRadius: "10px",
                                                width: "32%",
                                                border: "1px solid #6E4E9F",
                                                color: "#6E4E9F",
                                            }}
                                        >
                                            <option value="" selected disabled>
                                                Please Select
                                            </option>

                                            {Template.map((value, index) => (
                                                <option key={value._id} value={value._id}>
                                                    {value.name}
                                                </option>
                                            ))}
                                        </select>


                                        <p style={{ padding: '0', margin: '0', fontSize: '0.8em' }}><strong>NOTE:</strong> The <strong>subject</strong> and <strong>content</strong> of Template will appear in the editor, allowing for easy modifications and updates as needed.  </p>
                                    </div>

                                    <div >

                                        <div className='contantdiv' style={{ color: '#6E4E9F' }}>



                                            <h3>Email Content:</h3>
                                            <div  >
                                                <ReactQuill

                                                    ref={quillRef}
                                                    value={content}
                                                    onChange={setContent}
                                                    modules={modules}
                                                    formats={formats}
                                                    placeholder="Write your email here..."
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px", display: 'flex', flexDirection: 'row !important', gap: '0', alignItems: 'center' }}>
                                        <input checked={trackemail} id='trackemail' type='checkbox' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => settrackemail(e.target.checked)} />

                                        <label for="trackemail">Track this Email</label >


                                        <input checked={saveemail} id='saveemail' type='checkbox' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", marginLeft: "50px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => setsaveemail(e.target.checked)} />

                                        <label for="saveemail">Save This Mail information in database</label >

                                    </div> */}



                                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px", display: 'flex', flexDirection: 'row !important', gap: '0', alignItems: 'center' }}>


                                        <input name='style' checked={!emailstyles} id='YGMBrandedEmailStyle' type='Radio' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => setemailstyles(false)} />

                                        <label for="YGMBrandedEmailStyle">YGM Branded Email Style</label >

                                        <input name='style' checked={emailstyles} id='SimpleGMailStyle' type='Radio' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", marginLeft: "50px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => setemailstyles(true)} />

                                        <label for="SimpleGMailStyle">Simple G-Mail Style </label >


                                    </div>



                                    <div className='contantdiv' style={{ marginBottom: "10px" }}>
                                        <button type='submit' className='button' style={{ color: "#dcd2fc", padding: "7px 25px", borderRadius: "6px", background: "#6e4f9f" }}>SEND EMAIL</button>
                                    </div>
                                </>)}
                            </form>



                        </div>

                    </div>



                </> : <NotLogin />}
        </div>
    )
}

export default Singalemail
