import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import axios from 'axios';
import { IoFastFood } from 'react-icons/io5';
import NotLogin from '../components/notLogin';
import { FaBarcode, FaRegUser, FaWpforms } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { TbGenderAndrogyne } from 'react-icons/tb';
import { BsFillCalendar2DateFill } from 'react-icons/bs';
import { Popconfirm } from 'antd';
import { ImBin } from 'react-icons/im';
import { AiOutlineInteraction } from 'react-icons/ai';

function Formview(params) {
  const type = params.kittype;
  const formtype = params.formtype
  const loginname = Cookies.get("Name");
  const token = Cookies.get("Token");
  const limit = 15;


  const backgroundcolor = Cookies.get("backgroundcolor") || params.backgroundcolor;
  const color = Cookies.get("color") || params.color;
  const Buttonbackgroundcolor = Cookies.get("Buttonbackgroundcolor") || params.Buttonbackgroundcolor;
  const Buttoninsidecolor = Cookies.get("Buttoninsidecolor") || params.Buttoninsidecolor;
  const prinarycolor = Cookies.get("prinarycolor") || params.prinarycolor;
  const prinarycolortext = Cookies.get("prinarycolortext") || params.prinarycolortext;
  const seconderycolor = Cookies.get("seconderycolor") || params.seconderycolor;
  const portalid = Cookies.get("portalid") || params.portalid;



  const [popup, setpopup] = useState(false);
  const [total, settotal] = useState(1); // ✅ FIX: Initialize to 1

  const [page, setpage] = useState(1); // ✅ FIX: Initialize to 1
  const [totalPages, settotalPages] = useState(0);
  const [data, setdata] = useState([]);
  const [orderdetails, setorderdetails] = useState({}); // ✅ FIX: Add state for order details

  const getdata = async (pageNumber) => {
    try {
      const response = await axios.post(process.env.REACT_APP_APIURL + '/getsubmitedformdata', {
        page: pageNumber,
        type: type,
        limit: limit,
        formtype: formtype
      });

      setdata(response.data.data);
      setpage(response.data.currentPage); // ✅ FIX: Set correct page number
      settotalPages(response.data.totalPages);
      settotal(response.data.totaldata)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getdata(page); // ✅ FIX: Depend on page
  }, []);

  const handlePageClick = (pageNumber) => {
    setdata([]); // Clear old data
    setpage(pageNumber);
    getdata(pageNumber);
    // ✅ FIX: Update page before fetching
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span key={i} onClick={() => handlePageClick(i)} className={page === i ? "activeeee" : ""}>
          {i}
        </span>
      );
    }
    return pageNumbers;
  };


  var sno2 = 1 + limit * (page - 1);
  return (
    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ? (
        <>
          <Navbar />
          <div className='deshboardmain' style={{ backgroundColor: backgroundcolor }}>
            <Sidemanu />
            <div className='mainbody'>
              <div className='header'>
                <h1 style={{ color: prinarycolor, display: 'flex', alignItems: 'center' }}>
                  <FaWpforms style={{ width: '35px', height: '35px' }} />
                  {type} Form
                </h1>

                <h3 style={{ color: prinarycolor, display: 'flex', alignItems: 'center' }}>

                  Total: {total}
                </h3>
              </div>

              {popup && (
                <>
                  <div onClick={() => setpopup(false)} className='popupbg'></div>
                  <div className='popup'>
                    <div className='header' style={{ color: prinarycolor }}><h2> Info</h2></div>
                    <div className='kitdetailsmain' >

                      <div className='kitdetails'>
                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Name</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>  {orderdetails.name}  </h3>
                      </div>

                      <div className='kitdetails'>
                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <MdEmail style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Email </h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>  {orderdetails.email} </h3>
                      </div>

                      <div className='kitdetails'>
                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <FaBarcode style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Kit Code</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center', textAlign: 'right' }}> {orderdetails.KitCode} </h3>
                      </div>

                      <div className='kitdetails'>
                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <TbGenderAndrogyne style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Date of Birth</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center' }}>  {orderdetails.DOB} </h3>
                      </div>



                      <div className='kitdetails'>
                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <BsFillCalendar2DateFill style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Sample Date</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center', textAlign: 'right' }}>  {orderdetails.sampledate} </h3>
                      </div>


                      <div className='kitdetails'>
                        <h3 style={{ display: "flex", color: color, alignItems: 'center' }}> <AiOutlineInteraction style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action</h3><h3 style={{ display: "flex", color: prinarycolor, alignItems: 'center', textAlign: 'right' }}>

                          <Popconfirm
                            title="Are you sure?"
                            onConfirm={async() => {
                              

                               let data = JSON.stringify({
                                _id: orderdetails._id,
                              
                              });
                              
                              let config = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: process.env.REACT_APP_APIURL+'/submitformdata-dlt',
                                headers: { 
                                  'Content-Type': 'application/json'
                                },
                                data : data
                              };
                              
                             await axios.request(config)
                              .then(async(response) => {
                                // console.log(JSON.stringify(response.data));

                               await getdata(page);
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                              setpopup(false)

                            }}
                            
                          >
                            <div style={{color:'red',cursor:'pointer'}}><ImBin />Delete this data</div>
                          </Popconfirm>
                        </h3>
                      </div>






                    </div>
                    <div className='bownpopupbutton'>
                      <button onClick={() => setpopup(false)} style={{ border: '1px solid red', color: "red" }}>
                        Close
                      </button>
                    </div>
                  </div>
                </>
              )}

              {data.length !== 0 ? (
                <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                  <thead className='tablephead' style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                    <tr>
                      <th>S NO.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Kit Code</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((value, index) => (
                      <tr key={index}>
                        <td style={{ color: prinarycolor }}>{sno2++}</td>
                        <td style={{ color: prinarycolor }}>{value.name}</td>
                        <td style={{ color: prinarycolor }}>{value.email}</td>
                        <td style={{ color: prinarycolor }}>{value.KitCode}</td>
                        <td style={{ display: "flex", gap: '20px', justifyContent: 'center', color: prinarycolor }}>
                          <button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} className='button' onClick={() => {
                            setpopup(true);
                            setorderdetails(value);
                          }}> Details</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>
                  <img alt="" src={totalPages === 0 ? "/empty2.gif" : "/empty.gif"} width={"35%"} />
                </div>
              )}

              <div className="pagination">
                {page > 1 && <span onClick={() => handlePageClick(page - 1)}>&lt;&lt;</span>}
                {renderPageNumbers()}
                {page < totalPages && <span onClick={() => handlePageClick(page + 1)}>&gt;&gt;</span>}
              </div>
            </div>
          </div>
        </>
      ) : (
        <NotLogin />
      )}
    </div>
  );
}

export default Formview;
