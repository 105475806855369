import React, { useEffect, useState } from 'react'

import { MdBiotech, MdDataExploration, MdEmail, MdLabel, MdMedicalInformation, MdOutlineAnalytics, MdOutlineAppRegistration, MdOutlineEmail, MdOutlineQueryStats, MdOutlineSpaceDashboard, MdOutlineWeb } from "react-icons/md"
import { FaBacteria, FaBowlFood, FaChalkboardUser, FaClipboardQuestion, FaKitMedical } from "react-icons/fa6"
import { HiOutlineUsers, HiUserGroup } from 'react-icons/hi2'
import { HiOutlineDocumentReport } from "react-icons/hi"
import { CgProfile, CgReorder, CgTemplate } from "react-icons/cg"
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import { GiDna1, GiDna2, GiInsectJaws, GiLiver, GiMicroscope, GiReturnArrow, GiTongue } from 'react-icons/gi'
import { FcBiomass } from "react-icons/fc";
import { FaMapMarkedAlt, FaMicroscope, FaShippingFast, FaShoppingCart, FaTicketAlt, FaUpload, FaUserFriends, FaWpforms } from 'react-icons/fa'
import { BiHelpCircle } from 'react-icons/bi'
import { IoFastFood, IoFastFoodOutline, IoTicketSharp } from 'react-icons/io5'
import { BsCalendar2EventFill } from 'react-icons/bs'
import { SiAmazonsimpleemailservice, SiGoogleadsense, SiGooglemeet } from 'react-icons/si'
import { RiMailSendLine, RiMicroscopeLine, RiSendPlaneLine } from "react-icons/ri";
import { TbReportSearch } from 'react-icons/tb'
import axios from 'axios'

function Sidemanu() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const token2 = Cookies.get("Token2")
    const apiurlforbackend = process.env.REACT_APP_APIURL;

    const [manukonssa, setmanukonssa] = useState("0")
    const navigate = useNavigate()

    const [show, setshow] = useState(false)


    const logourl = Cookies.get("logourl") || "empty.gif";
    const backgroundcolor = Cookies.get("backgroundcolor") || "#999";
    const color = Cookies.get("color") || "#000";
    const Buttonbackgroundcolor = Cookies.get("Buttonbackgroundcolor") || "#dddddd";
    const Buttoninsidecolor = Cookies.get("Buttoninsidecolor") || "#000";
    const prinarycolor = Cookies.get("prinarycolor") || "#000";
    const prinarycolortext = Cookies.get("prinarycolortext") || "#000";
    const seconderycolor = Cookies.get("seconderycolor") || "#000";
    const portalid = Cookies.get("portalid") || "";

    const dashboard = Cookies.get("dashboard") || false;
    const microbiome = Cookies.get("microbiome") || false;
    const microbiomePlus = Cookies.get("microbiomePlus") || false;
    const microbiomeAdvanced = Cookies.get("microbiomeAdvanced") || false;
    const foodSensitivity = Cookies.get("foodSensitivity") || false;
    const foodSensitivityMap = Cookies.get("foodSensitivityMap") || false;
    const dnaMap = Cookies.get("dnaMap") || false;
    const parasitologyTest = Cookies.get("parasitologyTest") || false;
    const candidaProfile = Cookies.get("candidaProfile") || false;
    const sampleReceived = Cookies.get("sampleReceived") || false;
    const uploadResults = Cookies.get("uploadResults") || false;
    const allPractitioners = Cookies.get("allPractitioners") || false;
    const dataAndAnalytics = Cookies.get("dataAndAnalytics") || false;
    const ordersInformation = Cookies.get("ordersInformation") || false;
    const maps = Cookies.get("maps") || false;
    const shipping = Cookies.get("shipping") || false;
    const whiteLabel = Cookies.get("whiteLabel") || false;
    const academy = Cookies.get("academy") || false;
    const meetings = Cookies.get("meetings") || false;
    const emails = Cookies.get("emails") || false;
    const helpCenter = Cookies.get("helpCenter") || false;
    const profile = Cookies.get("profile") || false;



    // useEffect(() => {
    //     const baseUrl = `${window.location.host}`;
    //     let data = JSON.stringify({
    //         "url": baseUrl
    //     });

    //     let config = {
    //         method: 'post',
    //         maxBodyLength: Infinity,
    //         url: apiurlforbackend + '/get-portal-by-url',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     };

    //     axios.request(config)
    //         .then((response) => {
    //             console.log(JSON.stringify(response.data));
    //             setlogourl(response.data.logourl)
    //             document.title = response.data.bartitle;
    //             setbackgroundcolor(response.data.backgroundcolor)
    //             setcolor(response.data.testcolor)
    //             setButtonbackgroundcolor(response.data.Buttonbackgroundcolor)
    //             setButtoninsidecolor(response.data.Buttoninsidecolor)
    //             setprinarycolor(response.data.prinarycolor)
    //             setprinarycolortext(response.data.prinarycolortext)
    //             setportalid(response.data._id)
    //             setseconderycolor(response.data.seconderycolor)

    //             setDashboard(response.data.dashboard);
    //             setMicrobiome(response.data.microbiome);
    //             setMicrobiomePlus(response.data.microbiomePlus);
    //             setMicrobiomeAdvanced(response.data.microbiomeAdvanced);
    //             setFoodSensitivity(response.data.foodSensitivity);
    //             setFoodSensitivityMap(response.data.foodSensitivityMap);
    //             setDnaMap(response.data.dnaMap);
    //             setParasitologyTest(response.data.parasitologyTest);
    //             setCandidaProfile(response.data.candidaProfile);
    //             setSampleReceived(response.data.sampleReceived);
    //             setUploadResults(response.data.uploadResults);
    //             setAllPractitioners(response.data.allPractitioners);
    //             setDataAndAnalytics(response.data.dataAndAnalytics);
    //             setOrdersInformation(response.data.ordersInformation);
    //             setMaps(response.data.maps);
    //             setShipping(response.data.shipping);
    //             setWhiteLabel(response.data.whiteLabel);
    //             setAcademy(response.data.academy);
    //             setMeetings(response.data.meetings);
    //             setEmails(response.data.emails);
    //             setHelpCenter(response.data.helpCenter);
    //             setProfile(response.data.profile);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, [])


    useEffect(() => {



        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("email", loginemail);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };


        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmeetschudulebyemail", requestOptions)
            .then((response) => response.json())
            .then(async (result) => {

                if (id === result.id) {
                    setshow(true)
                    //    Cookies.set('Token2', "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd", { expires: 7 }); // Expires in 7 days

                }

            })



            .catch((error) => console.error(error));



    }, [])



    return (
        <div className='mainsidemanu' style={{ color: prinarycolor }} >

            {dashboard && <>

                <h3 onClick={() => navigate("/dashboard")}><MdOutlineSpaceDashboard style={{ width: '21px', height: '21px' }} /> Dashboard</h3>
            </>}

            {microbiome === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                    <h3 onClick={() => setmanukonssa("2")}><GiLiver style={{ width: '21px', height: '21px' }} />  Microbiome           </h3>
                    {manukonssa === "2" && <>     <ul>
                        <li onClick={() => navigate("/dashboard/microbiome/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/microbiome/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                        <li onClick={() => navigate("/dashboard/microbiome/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>

                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/kitregistration")}><MdOutlineAppRegistration style={{ width: '15px', height: '15px' }} />White Label KIT Registration </li></>}


                    </ul>
                    </>}
                </>}
            </>}


            {microbiomeAdvanced === "true" && <>
                <h3 onClick={() => setmanukonssa("11")}> <GiMicroscope style={{ width: '21px', height: '21px' }} /> MicrobiomePlus            </h3>
                {manukonssa === "11" && <>   <ul>
                    <li onClick={() => navigate("/dashboard/microbiomeplus/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kits </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/microbiomeplus/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                    <li onClick={() => navigate("/dashboard/microbiomeplus/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>

                </ul>
                </>}
            </>}

            {microbiomePlus === "true" && <>
                <h3 onClick={() => setmanukonssa("12")}> <RiMicroscopeLine style={{ width: '21px', height: '21px' }} /> MicrobiomeAdvanced         </h3>
                {manukonssa === "12" && <>   <ul>
                    <li onClick={() => navigate("/dashboard/microbiomeadvanced/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kits </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/microbiomeadvanced/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                    <li onClick={() => navigate("/dashboard/microbiomeadvanced/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>

                </ul>
                </>}
            </>}


            {microbiomePlus === "true" && <>
                <h3 onClick={() => setmanukonssa("14")}> <GiTongue style={{ width: '21px', height: '21px' }} /> Oral Microbiome         </h3>
                {manukonssa === "14" && <>   <ul>
                    <li onClick={() => navigate("/dashboard/oralmicrobiome/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kits </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/oralmicrobiome/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                    <li onClick={() => navigate("/dashboard/oralmicrobiome/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/oralmicrobiomeform-view")}><IoFastFood style={{ width: '15px', height: '15px' }} />Oral Microbiome Form </li></>}

                </ul>
                </>}
            </>}



            {foodSensitivity === "true" && <>
                <h3 onClick={() => setmanukonssa("1")}> <FaBowlFood style={{ width: '21px', height: '21px' }} /> Food Sensitivity        </h3>
                {manukonssa === "1" && <>   <ul>
                    <li onClick={() => navigate("/dashboard/fs100/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />100 foods Kits </li>
                    <li onClick={() => navigate("/dashboard/fs210/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />210 foods Kits </li>

                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/fs100/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />100 foods kit Practitioners </li></>}
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/fs210/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} /> 210 foods kit Practitioners </li></>}

                    <li onClick={() => navigate("/dashboard/fs100/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />100 foods Reports </li>
                    <li onClick={() => navigate("/dashboard/fs210/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />210 foods Reports </li>

                </ul>
                </>}  </>}

            {foodSensitivityMap === "true" && <>
                <h3 onClick={() => setmanukonssa("10")}> <IoFastFoodOutline style={{ width: '21px', height: '21px' }} /> FoodSensitivityMap           </h3>
                {manukonssa === "10" && <>   <ul>
                    <li onClick={() => navigate("/dashboard/fsmap/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/fsmap/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                    <li onClick={() => navigate("/dashboard/fsmap/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/foodsensitivityform-view")}><IoFastFood style={{ width: '15px', height: '15px' }} />Food Sensitivity Form </li></>}

                </ul>
                </>}  </>}

            {dnaMap === "true" && <>
                <h3 onClick={() => setmanukonssa("3")}>  <GiDna1 style={{ width: '21px', height: '21px' }} />DNAMap      </h3>
                {manukonssa === "3" && <>     <ul>
                    <li onClick={() => navigate("/dashboard/dna/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/dna/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                    <li onClick={() => navigate("/dashboard/dna/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dnamapform-view")}><FaWpforms style={{ width: '15px', height: '15px' }} />DNAMap Form Data </li></>}

                </ul>
                </>}  </>}

            {parasitologyTest === "true" && <>
                <h3 onClick={() => setmanukonssa("4")}>  <FaMicroscope style={{ width: '21px', height: '21px' }} />Parasitology Test      </h3>
                {manukonssa === "4" && <>     <ul>
                    <li onClick={() => navigate("/dashboard/Parasitology/kit?page=1")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/Parasitology/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                    <li onClick={() => navigate("/dashboard/Parasitology/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/parasitologyform-view")}><FaWpforms style={{ width: '15px', height: '15px' }} />Parasitology Form Data </li></>}

                </ul>
                </>}  </>}


            {candidaProfile === "true" && <>
                <h3 onClick={() => setmanukonssa("8")}>  <FaBacteria style={{ width: '21px', height: '21px' }} />Candida Profile      </h3>
                {manukonssa === "8" && <>     <ul>
                    <li onClick={() => navigate("/dashboard/candidaprofile/kit")}><FaKitMedical style={{ width: '15px', height: '15px' }} />Kit </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dashboard/candidaprofile/practitioner")}><HiUserGroup style={{ width: '15px', height: '15px' }} />Practitioners </li></>}
                    <li onClick={() => navigate("/dashboard/candidaprofile/report")}><HiOutlineDocumentReport style={{ width: '15px', height: '15px' }} />Reports </li>
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/candidaform-view")}><FaWpforms style={{ width: '15px', height: '15px' }} />Candida Form Data </li></>}


                </ul>
                </>}  </>}

            {uploadResults === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/release-reults")}><RiMailSendLine style={{ width: '21px', height: '21px' }} />Release Results</h3></>}
            </>}


            {sampleReceived === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/samplerecieved-data")}><GiReturnArrow style={{ width: '21px', height: '21px' }} />Sample Received</h3></>}
            </>}


            {uploadResults === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/uploadreport")}><FaUpload style={{ width: '21px', height: '21px' }} />Upload Results</h3></>}
            </>}

            {allPractitioners === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/practitioner")}><HiOutlineUsers style={{ width: '21px', height: '21px' }} />All Practitioners</h3></>}
            </>}

            {dataAndAnalytics === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

                    <h3 onClick={() => setmanukonssa("7")}>  <MdDataExploration style={{ width: '21px', height: '21px' }} />Data and Analytics                </h3>

                    {manukonssa === "7" && <>     <ul>
                        <li onClick={() => navigate("/leads")}><SiGoogleadsense style={{ width: '15px', height: '15px' }} />Leads </li>
                        <li onClick={() => navigate("/leads-converted")}><SiGoogleadsense style={{ width: '15px', height: '15px' }} />Converted Leads </li>

                        <li onClick={() => navigate("/new-Practitioner-data")}><FaUserFriends style={{ width: '15px', height: '15px' }} />New Practitioner Data</li>

                        <li onClick={() => navigate("/dashboard/practitionerstates")}><MdOutlineQueryStats style={{ width: '15px', height: '15px' }} />Practitioners States</li>

                        <li onClick={() => navigate("/dashboard/eventinfogdata")}><BsCalendar2EventFill style={{ width: '15px', height: '15px' }} />Event Info </li>
                        <li onClick={() => navigate("/dashboard/samplereturn-data")}><GiReturnArrow style={{ width: '15px', height: '15px' }} />Sample Return - Data </li>

                        <li onClick={() => navigate("/dashboard/analysis")}><MdOutlineAnalytics style={{ width: '15px', height: '15px' }} />Kits Analysis </li>

                        <li onClick={() => navigate("/dashboard/order-states")}><FaShoppingCart style={{ width: '15px', height: '15px' }} />Order States </li>

                    </ul>
                    </>}

                </>}
            </>}





            {ordersInformation === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/orders")}><CgReorder style={{ width: '21px', height: '21px' }} />Orders Information</h3></>}
            </>}
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <h3 onClick={() => navigate("/dashboard/reportstyles")}><HiOutlineDocumentReport style={{ width: '21px', height: '21px' }} />Report Styles</h3></>}
            {maps === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>    <h3 onClick={() => navigate("/dashboard/map")}><FaMapMarkedAlt style={{ width: '21px', height: '21px' }} />Maps</h3></>}
            </>}
            {shipping === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>    <h3 onClick={() => navigate("/dashboard/shippingdata")}><FaShippingFast style={{ width: '21px', height: '21px' }} />Shipping</h3></>}
            </>}

            {whiteLabel === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                    <h3 onClick={() => setmanukonssa("6")}><MdLabel style={{ width: '21px', height: '21px' }} />  White Label                </h3>
                    {manukonssa === "6" && <>     <ul>

                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/dnamapform-view-wl")}><GiDna2 style={{ width: '15px', height: '15px' }} />DNAMap Form </li></>}
                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/parasitologyform-view-wl")}><GiInsectJaws style={{ width: '15px', height: '15px' }} />Parasitology Test Form </li></>}
                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/foodsensitivityform-view-wl")}><IoFastFoodOutline style={{ width: '15px', height: '15px' }} />FoodSensitivityMap Form </li></>}
                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>   <li onClick={() => navigate("/candidaform-view-wl")}><FaBacteria style={{ width: '15px', height: '15px' }} />CandidaProfile Form </li></>}


                    </ul>
                    </>}   </>}</>}

            {academy === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>    <h3 onClick={() => navigate("/dashboard/webinar")}><MdOutlineWeb style={{ width: '21px', height: '21px' }} />Academy</h3></>}
            </>}

            {meetings === "true" && <>
                {(token !== "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && show === true) && <>    <h3 onClick={() => navigate("/dashboard/meet")}><SiGooglemeet style={{ width: '21px', height: '21px' }} />Meetings</h3></>}
            </>}



            {meetings === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                    <h3 onClick={() => setmanukonssa("9")}>  <SiGooglemeet style={{ width: '21px', height: '21px' }} />Meetings      </h3>
                    {manukonssa === "9" && <>     <ul>

                        {show && <>
                            <li onClick={() => navigate("/dashboard/meet")}><FaTicketAlt style={{ width: '15px', height: '15px' }} />Meetings Appointments</li>
                        </>}
                        <li onClick={() => navigate("/dashboard/meet-user")}><FaChalkboardUser style={{ width: '15px', height: '15px' }} />Users For Meeting </li>
                    </ul>
                    </>}
                </>}
            </>}

            {emails === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                    <h3 onClick={() => setmanukonssa("12")}>  <MdOutlineEmail style={{ width: '21px', height: '21px' }} />Emails      </h3>
                    {manukonssa === "12" && <>     <ul>
                        <li onClick={() => navigate("/dashboard/send-email")}><RiSendPlaneLine style={{ width: '15px', height: '15px' }} />Send Single  Email </li>
                        <li onClick={() => navigate("/dashboard/send-mass-email")}><SiAmazonsimpleemailservice style={{ width: '15px', height: '15px' }} />Send Mass Email </li>
                        {/* <li onClick={() => navigate("/dashboard/email-reports")}><TbReportSearch style={{ width: '15px', height: '15px' }} />Mass Email Reports</li> */}
                        <li onClick={() => navigate("/dashboard/email-templates")}><CgTemplate style={{ width: '15px', height: '15px' }} />Email Templates </li>
                    </ul>
                    </>}
                </>} </>}


            {helpCenter === "true" && <>
                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
                    <>
                        <h3 onClick={() => setmanukonssa("5")}>
                            {" "}
                            <BiHelpCircle style={{ width: "21px", height: "21px" }} />
                            Help Center{" "}
                        </h3>
                        {manukonssa === "5" && (
                            <>
                                {" "}
                                <ul>
                                    <li onClick={() => navigate("/dashboard/tickets")}>
                                        <IoTicketSharp
                                            style={{ width: "15px", height: "15px" }}
                                        />
                                        Help Center Tickets{" "}
                                    </li>

                                    <li onClick={() => navigate("/dashboard/helpcenter")}>
                                        <FaClipboardQuestion
                                            style={{ width: "15px", height: "15px" }}
                                        />
                                        Help Center FAQs{" "}
                                    </li>
                                </ul>
                            </>
                        )}
                    </>
                )} </>}



            {profile === "true" && <>
                <h3 onClick={() => navigate("/dashboard/profile")}><CgProfile style={{ width: '21px', height: '21px' }} />Profile</h3>
            </>}
        </div >
    )
}

export default Sidemanu