import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Checkbox, Flex, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../css/orderlables.css"
import html2pdf from 'html2pdf.js';
import NotLogin from '../components/notLogin';
import jsPDF from "jspdf";

function Orderlables(params) {
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")





  const backgroundcolor = Cookies.get("backgroundcolor") || params.backgroundcolor;
  const color = Cookies.get("color") || params.color;
  const Buttonbackgroundcolor = Cookies.get("Buttonbackgroundcolor") || params.Buttonbackgroundcolor;
  const Buttoninsidecolor = Cookies.get("Buttoninsidecolor") || params.Buttoninsidecolor;
  const prinarycolor = Cookies.get("prinarycolor") || params.prinarycolor;
  const prinarycolortext = Cookies.get("prinarycolortext") || params.prinarycolortext;
  const seconderycolor = Cookies.get("seconderycolor") || params.seconderycolor;
  const portalid = Cookies.get("portalid") || params.portalid;
  const logo2 = Cookies.get("logo2") || params.logo2;
  const ordersInformationData = params.ordersInformationData || [];



  const [popup, setpopup] = useState(false)
  const [isloading, setisloading] = useState(false)
  const [print, setprint] = useState(false)
  const [popup2, setpopup2] = useState("addkit")

  const [orders, setorders] = useState([])
  const [labels, setLabels] = useState([])
  const [seequetion, setseequetion] = useState(params.ordersInformationData[0] || {})

  const [orderdetails, setorderdetails] = useState([])

  const [totalPages, settotalPages] = useState(1);


  const getorders = async (pagesss) => {
    const hide = message.loading("Action in progress", 0);

    // let woocommerceApiUrl = '';
    // let consumerKey = '';
    // let consumerSecret = '';


    const woocommerceApiUrl = seequetion.wooCommerceUrl;
    const consumerKey = seequetion.wooCommerceKey;
    const consumerSecret = seequetion.wooCommerceSecret;


    async function getAllOrders() {
      setisloading(true);

      const encodedCredentials = btoa(`${consumerKey}:${consumerSecret}`);
      const headers = {
        'Authorization': `Basic ${encodedCredentials}`,
        'Accept': 'application/json' // Ensure the server returns JSON
      };

      let orders = [];
      let page = pagesss;
      let totalPages = 1;

      try {
        const response = await axios.get(`${woocommerceApiUrl}orders`, {
          headers,
          params: {
            per_page: 30, // Number of orders per page (max 100)
            page: page
          }
        });

        console.log("Raw API Response:", response.data); // Debugging

        // If response is a string, check if it contains HTML warnings
        let responseData = response.data;
        if (typeof responseData === "string") {
          const jsonStartIndex = responseData.indexOf("["); // Find where JSON starts
          if (jsonStartIndex !== -1) {
            responseData = responseData.substring(jsonStartIndex).trim();
          }
          try {
            responseData = JSON.parse(responseData);
          } catch (error) {
            console.error("JSON Parse Error:", error);
            return [];
          }
        }

        orders = Array.isArray(responseData) ? responseData : [];
        settotalPages(parseInt(response.headers['x-wp-totalpages'], 10));

        return orders;
      } catch (error) {
        console.error("Error fetching orders:", error.response ? error.response.data : error);
        return [];
      }
    }

    try {
      const orders = await getAllOrders();
      setorders(orders); // Always set an array to prevent .map() errors
      setisloading(false);
    } catch (error) {
      console.error('Error:', error);
    }

    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  };


  useEffect(() => {
    getorders(currentPage)


  }, [seequetion])




  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = async (pageNumber) => {

    const hide = message.loading("Action in progress", 0)

    await getorders(pageNumber)
    await setCurrentPage(pageNumber);

    await renderPageNumbers()

    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  };



  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
    const startPage = Math.max(1, currentPage - maxPageToShow);
    const endPage = Math.min(totalPages, currentPage + maxPageToShow);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? 'activeeee' : ''}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };


  const isShippingChecked = (shipping, id) => {
    return labels.some(label => label.id === id);
  };

  const handleCheckboxChange = (e, shipping, id, items) => {
    const shippingWithId = { ...shipping, id, items };

    if (e.target.checked) {
      setLabels(prevLabels => [...prevLabels, shippingWithId]);
    } else {
      setLabels(prevLabels => prevLabels.filter(label => label.id !== id));
    }
  };





  const RemoveItemByIndex = (index) => {
    // Create a new array without the item at the specified index
    const updatedAddkit = labels.filter((_, i) => i !== index);
    setLabels(updatedAddkit);
  };

  useEffect(() => {
    if (labels.length === 0) {
      setpopup(false)

      setpopup2("addkit")
    }
  }, [labels]);







  const handlePrint = async () => {
    await setprint(true);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;


    const content = document.getElementById("print");

    const opt = {
      margin: 0,
      filename: "order shipping - " + formattedDate + ".pdf",
      image: { type: "jpeg", quality: 2 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(content).set(opt).save();
  };



  const handlePrintname = async () => {
    await setprint(true)

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // getMonth() is zero-based
    const year = currentDate.getFullYear();

    // Format date as DD/MM/YYYY
    const formattedDate = `${day}/${month}/${year}`;

    const content = await document.getElementById('printt'); // Replace with the ID of the content you want to export


    const opt = {
      margin: 0,
      filename: 'my_webpage.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const seee = html2pdf().from(content).set(opt).outputPdf();



    seee.save("order Name - " + formattedDate + ".pdf")

    await setprint(false)
    await setpopup(false)
    await setLabels([])

  };

  var sno1 = 1
  var sno2 = 1
  var sno3 = 1


  return (
    <div>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ?
        <>



          <Navbar />

          <div className='deshboardmain' style={{ backgroundColor: backgroundcolor }}>
            <Sidemanu />

            <div className='mainbody'>{isloading ? <><img alt='' src='/empty.gif' width={"40%"} /></> :
              <div className="">
                <div className='header'>
                  <h1 style={{ color: prinarycolor, display: 'flex', alignItems: 'center' }}><CgReorder style={{ width: '30px', height: '30px' }} />Orders information</h1>


                </div>
                <div style={{ display: 'flex' }}>
                  {/* {seequetion ? (<>

                    <h4 className='qqqqqqq ' style={{ backgroundColor: prinarycolor, color: prinarycolortext, display: 'flex', margin: "0", }}> UK ORDERS</h4>
                    <h4 className='qqqqqqq ' onClick={() => setseequetion(!seequetion)} style={{ color: prinarycolor, backgroundColor: seconderycolor, display: 'flex', margin: "0" }}> IR ORDERS</h4>

                  </>) : (<>


                    <h4 className='qqqqqqq ' onClick={() => setseequetion(!seequetion)} style={{ color: prinarycolor, backgroundColor: seconderycolor, display: 'flex', margin: "0" }}> UK ORDERS</h4>
                    <h4 className='qqqqqqq ' style={{ backgroundColor: prinarycolor, color: prinarycolortext, display: 'flex', margin: "0" }}> IR ORDERS</h4>

                  </>)}
 */}

                  {/* {ordersInformationData.length > 0 && <> */}
                    {params.ordersInformationData.map((value, index) => {


                      return (<>
                        <h4
                          className="qqqqqqq"
                          onClick={() => setseequetion(value)}
                          style={{
                            backgroundColor: seequetion === value ? prinarycolor : seconderycolor,
                            color: seequetion === value ? prinarycolortext : prinarycolor,
                            display: "flex",
                            margin: "0",
                          }}
                        >
                          {value.wooCommerceName}
                        </h4>
                      </>)
                    }
                    )}

                  {/* </>} */}
                </div>


                {popup === true && <>
                  <div onClick={() => {
                    setpopup(false)

                    setpopup2("addkit")
                  }
                  } className='popupbg'></div>
                  <div className='popup'>

                    {popup2 === "addkit" && <>

                      <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                        <thead className='tablephead' style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                          <tr>
                            <th>S No.</th>
                            <th>Name	</th>
                            <th>Address</th>
                            <th>Country</th>
                            <th>City</th>
                            <th>company</th>
                            <th>Action</th>


                          </tr>
                        </thead>
                        <tbody>
                          {labels.map((value, index) => {

                            return (
                              <tr style={{ color: prinarycolor }} key={index}>
                                <td style={{ color: prinarycolor }}>{sno1++}</td>
                                <td style={{ color: prinarycolor }}>{value.first_name} {value.last_name}</td>
                                <td style={{ color: prinarycolor }}>{value.address_1}<br />{value.address_2}</td>
                                <td style={{ color: prinarycolor }}>{value.country}</td>
                                <td style={{ color: prinarycolor }}>{value.city}</td>
                                <td style={{ color: prinarycolor }}>{value.company}</td>
                                <td className='assignbuttom' ><AiFillDelete className='hovar' onClick={() => RemoveItemByIndex(index)} style={{ width: '30px', height: '30px', color: 'red' }} /></td>



                              </tr>
                            );
                          })}
                        </tbody>
                      </table>



                    </>}



                    {popup2 === "kitdetails" && <>

                      <div className='header' style={{ color: prinarycolor }}>
                        <h2>Order #{orderdetails.id}</h2>
                        <h2>{orderdetails.status}</h2>
                      </div>
                      <div className='kitdetailsmain' >


                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ textAlign: 'left' }}>
                            <h3>Billing details</h3>

                            {orderdetails.billing.first_name} {orderdetails.billing.last_name}<br />

                            {orderdetails.billing.email}<br />
                            {orderdetails.billing.address_1}<br />
                            {orderdetails.billing.address_2}<br />

                            {orderdetails.billing.city}<br />
                            {orderdetails.billing.postcode}<br /><br />
                            Email<br />
                            {orderdetails.billing.email}<br /><br /><br />
                            Phone<br />
                            {orderdetails.billing.phone}<br /><br />
                            Payment via<br />
                            {orderdetails.payment_method_title} ({orderdetails.transaction_id})<br /><br />
                          </div>



                          <div style={{ textAlign: 'left' }}>
                            <h3>Shipping details</h3>
                            {orderdetails.shipping.first_name} {orderdetails.shipping.last_name}<br />

                            {orderdetails.shipping.company}<br />
                            {orderdetails.shipping.address_1}<br />
                            {orderdetails.shipping.address_2}<br />
                            {orderdetails.shipping.city}<br />
                            {orderdetails.shipping.postcode}<br /><br />
                            Shipping method<br />
                            {orderdetails.shipping_lines[0].method_title}
                          </div>
                        </div>




                        <div>
                          <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                            <thead className='tablephead' style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                              <tr>
                                <th>Product</th>
                                <th>Quantity	</th>
                                <th>Total</th>


                              </tr>
                            </thead>
                            <tbody>
                              {orderdetails.line_items.map((order, index) => {

                                return (
                                  <tr style={{ color: prinarycolor }} key={index}>
                                    <td style={{ color: prinarycolor }}>{order.name}</td>
                                    <td style={{ color: prinarycolor }}>{order.quantity}</td>
                                    <td style={{ color: prinarycolor }}>&euro;{order.subtotal}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>




                      </div>
                    </>}


                    <div className='bownpopupbutton'>
                      <button onClick={() => {
                        setpopup(false)

                      }
                      } style={{ border: '1px solid red', color: 'black' }} >cancel</button>


                      {popup2 === "addkit" && <>
                        <button onClick={handlePrint} style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Print Labels</button>
                        <button onClick={handlePrintname} style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}>Print Name</button>
                      </>}




                    </div>

                  </div>
                </>}


                <div style={{ margin: "50px" }}></div>
                <div className="pagination">
                  {currentPage > 1 && (
                    <span onClick={() => handlePageClick(currentPage - 1)}>&lt;&lt;</span>
                  )}
                  {renderPageNumbers()}
                  {currentPage < totalPages && (
                    <span onClick={() => handlePageClick(currentPage + 1)}>&gt;&gt;</span>
                  )}

                  {labels.length !== 0 && <><button style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }} className='button' onClick={() => {
                    setpopup(true)
                    setpopup2("addkit")
                  }}>Print</button></>}
                </div>



                <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                  <thead className='tablephead' style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                    <tr>
                      <th></th>

                      <th>Order</th>
                      <th>Date</th>

                      <th>Status</th>
                      <th>Total</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>




                    {orders.map((value, index) => {
                      return (<>
                        <tr>
                          <td style={{ color: prinarycolor }} ><Checkbox checked={isShippingChecked(value.shipping, value.id, value.line_items)}
                            onChange={(e) => handleCheckboxChange(e, value.shipping, value.id, value.line_items)}
                          ></Checkbox></td>

                          <td style={{ color: prinarycolor }}>#{value.id} {value.billing.first_name} {value.billing.last_name}</td>
                          <td style={{ color: prinarycolor }}>{value.date_created}</td>
                          <td style={{ color: prinarycolor }}>{value.status}</td>
                          <td style={{ color: prinarycolor }}>{value.currency_symbol}{value.total}</td>
                          <td>
                            <button className='button'
                              onClick={() => {
                                setpopup(true)
                                setpopup2("kitdetails")
                                setorderdetails(value)
                              }}

                              style={{ backgroundColor: Buttonbackgroundcolor, color: Buttoninsidecolor }}  > Details</button>
                          </td>


                        </tr>
                      </>)
                    })}















                  </tbody>
                </table>






              </div>}
            </div>

          </div>

          <div id='print' className='printt' style={{ display: print ? 'block' : 'none' }} >
            <div className='prrintbox'>
              {labels.map((value, index) => (
                <div className='labelprint' key={index}>
                  <div className='insideprint'>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ fontWeight: 'bold' }}> {value.first_name} {value.last_name}</div>
                      <div>  {value.company}</div>
                      <div>   {value.address_1}</div>
                      <div>   {value.address_2}</div>
                      <div>   {value.city}</div>
                      <div style={{ fontWeight: 'bold' }}>   {value.postcode}</div>

                    </div>
                    <div style={{ display: "Flex", flexDirection: 'column', justifyContent: "center", textAlign: "center" }}>
                      <div style={{ fontWeight: 'bold' }}>{value.id}</div>
                      <div><img className='printimg' src={logo2} width={"100px"} /></div>

                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>


          <div id='printt' className='printt' style={{ display: print ? 'block' : 'none' }} >
            <div className='prrintbox2' style={{}}>
              {labels.map((value, index) => (
                // <div className='labelprint' key={index}>
                //  <div className='insideprint'>
                //   <div style={{ display: 'flex', flexDirection: 'column' }}>
                //     <div style={{ fontWeight: 'bold' }}> {value.first_name} {value.last_name}</div>
                //     <div>  {value.company}</div>
                //     <div>   {value.address_1}</div>
                //     <div>   {value.address_2}</div>

                //     <div>   {value.city}</div>
                //     <div style={{ fontWeight: 'bold' }}>   {value.postcode}</div>

                //   </div>
                //   <div style={{ textAlign: "center" }}>
                //     <div style={{ fontWeight: 'bold' }}>{value.id}</div>
                //     <div><img className='printimg' src='/logo2.png' width={"100px"} /></div>

                //   </div>
                //   </div>
                // </div>
                <div>
                  <div style={{ fontWeight: 'bold', marginTop: '50px' }}> {value.first_name} {value.last_name}</div>
                  <div>
                    <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                      <thead className='tablephead' style={{ backgroundColor: prinarycolor, color: prinarycolortext }}>
                        <tr>
                          <th>Product</th>
                          <th>Quantity	</th>
                          <th>Total</th>


                        </tr>
                      </thead>
                      <tbody>

                        {value.items.map((order, index) => {
                          return (
                            <tr style={{ color: prinarycolor }} key={index}>
                              <td style={{ color: prinarycolor }}>{order.name}</td>
                              <td style={{ color: prinarycolor }}>{order.quantity}</td>
                              <td style={{ color: prinarycolor }}>&euro;{order.subtotal}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}


            </div>
          </div>

        </> : <NotLogin />}
    </div>
  )
}

export default Orderlables