import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../../components/navbar';
import Sidemanu from '../../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Button, Checkbox, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../../css/orderlables.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaMapMarkerAlt, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendar2EventFill, BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaEarthAfrica, FaFileArrowDown } from 'react-icons/fa6';
import NotLogin from '../../components/notLogin';
import { GiReturnArrow } from 'react-icons/gi';
import DOMPurify from 'dompurify'; // Ensures HTML is safe (prevents XSS)


function Samplerecieveddata() {
  var sno1 = 1
  var sno2 = 1

  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")
  const apiurlforbackend = process.env.REACT_APP_APIURL;


  const [popup, setpopup] = useState(false)



  const [orders, setorders] = useState([])


  const [orderdetails, setorderdetails] = useState([])

  const [kit_code, setkitcode] = useState([])

  const getorders = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch(apiurlforbackend + "/samplerecieved-data", requestOptions)
      .then(response => response.json())
      .then(result => setorders(result))
  };



  useEffect(() => {
    getorders()


  }, [])


  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);

  const [Message, setMessages] = useState([]);

  const messagesEndRef = useRef(null); // Reference to the bottom of the message list


  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [Message]);


  const senddatatobackend = async () => {


    setloading(true)
    setloading2(true)

    if (orderdetails.kitExist.length > 0) {
      await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">Proccessing ${orderdetails.kitExist.length} KITs that are available on our portal</p>`]);


      for (const value of orderdetails.kitExist) {
        let data = JSON.stringify({
          "Kitcode": value
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: apiurlforbackend + '/processrec-kit',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        await axios.request(config)
          .then(async (response) => {

            await setMessages((prevMessages) => [...prevMessages, response.data]);


          })
          .catch(async (error) => {
            console.log(error);
            await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">${error.message} while proccessing KID : ${value} </p>`]);


          });

      }
    } else if (orderdetails.kitExistOnEnbiosis.length > 0) {

      await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">Proccessing ${orderdetails.kitExistOnEnbiosis.length} KITs that are available on Embiosis</p>`]);


      for (const value of orderdetails.kitExistOnEnbiosis) {
        let data = JSON.stringify({
          "Kitcode": value
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: apiurlforbackend + '/processrec-kit',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        await axios.request(config)
          .then(async (response) => {

            await setMessages((prevMessages) => [...prevMessages, response.data]);


          })
          .catch(async (error) => {
            console.log(error);
            await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">${error.message} while proccessing KID : ${value} </p>`]);


          });

      }
    } else {
      await setMessages((prevMessages) => [...prevMessages, `<p style="color:red;">Unable to perfome any action</p>`]);

    }


    setloading(false)

  }




  return (
    <div>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ?
        <>



          <Navbar />

          <div className='deshboardmain'>
            <Sidemanu />

            <div className='mainbody'>

              <div className='header'>
                <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><GiReturnArrow style={{ width: '30px', height: '30px' }} />Sample Recieved - data </h1>


              </div>



              {popup === true && <>
                <div onClick={() => {
                  setpopup(false)


                }
                } className='popupbg'></div>
                <div className='popup'>
                  {loading2 ? (<>
                    {/* <img alt='' src='/empty.gif' width={"20%"} /> */}
                    <h3>Logs</h3>
                    <div className='showreportlogs'>
                      {Message.map((value, index) => (<>
                        <div
                          dangerouslySetInnerHTML={{ __html: value }}
                        />



                      </>))}
                      {loading ? (<>
                        <img alt='' src='/empty.gif' width={"5%"} />
                      </>) : (<>
                        <Button onClick={() => {
                          setMessages([])
                          setloading2(false)

                        }} danger>Clear</Button >
                      </>)}
                      <div ref={messagesEndRef} />
                    </div>
                  </>) : (<>

                    <div className='header' >
                      <h2>Event Info</h2>

                      {orderdetails.saved ? (<>
                        <button className='button'>Acknowledgment Already Sent</button>

                      </>) : (<>
                        <button className='button' onClick={senddatatobackend}>Send Acknowledgment Emails</button>

                      </>)}

                    </div>

                    <div className='kitdetailsmain' >


                      {orderdetails.kitExist.length > 0 && <>
                        <div className='kitdetails' style={{ flexDirection: 'column', }}>
                          <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Found on Portal</h3>

                          <h5 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', flexWrap: 'wrap' }}>



                            {orderdetails.kitExist.map((kit, index) => (
                              <span style={{ color: "green", }} key={`exist-${index}`}>
                                {kit}{index !== orderdetails.kitExist.length - 1 ? ", " : ""}
                              </span>
                            ))}
                          </h5>
                        </div>
                      </>}



                      {orderdetails.kitExistOnEnbiosis.length > 0 && <>


                        <div className='kitdetails' style={{ flexDirection: 'column' }}>
                          <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Found on Embiosis</h3><h5 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', flexWrap: 'wrap' }}>

                            {orderdetails.kitExistOnEnbiosis.map((kit, index) => (
                              <span style={{ color: "blue" }} key={`exist-${index}`}>
                                {kit}{index !== orderdetails.kitExistOnEnbiosis.length - 1 ? ", " : ""}
                              </span>
                            ))}
                          </h5>
                        </div>
                      </>}

                      {orderdetails.kitNotExist.length > 0 && <>

                        <div className='kitdetails' style={{ flexDirection: 'column' }}>
                          <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Does not Exist</h3><h5 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', flexWrap: 'wrap' }}>

                            {orderdetails.kitNotExist.map((kit, index) => (
                              <span style={{ color: "red" }} key={`exist-${index}`}>
                                {kit}{index !== orderdetails.kitNotExist.length - 1 ? ", " : ""}
                              </span>
                            ))}
                          </h5>
                        </div>
                      </>}

                      <div className='kitdetails'>
                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Total Kits</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.kitNotExist.length + orderdetails.kitExistOnEnbiosis.length + orderdetails.kitExist.length} </h3>
                      </div>




                      <div className='kitdetails' style={{ flexDirection: 'column', textAlign: 'left' }}>
                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>
                          Message Snapshot
                        </h3>

                        <div
                          style={{
                            // backgroundColor: "blue",
                            // color: "white", // Ensures text is visible on a blue background
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            padding: "10px", // Adds padding for better readability
                            borderRadius: "5px", // Optional: rounded corners
                            overflowWrap: "break-word", // Ensures long text breaks correctly
                            wordBreak: "break-word"
                          }}
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(orderdetails.snapshot) }}
                        />
                      </div>





                      <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbGitBranchDeleted style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action </h3><h3 onClick={async () => {
                        const hide = message.loading("Action in progress", 0)
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                        var urlencoded = new URLSearchParams();
                        urlencoded.append("_id", orderdetails._id);

                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };


                        await fetch(apiurlforbackend + "/samplerecieved-dlt", requestOptions)
                          .then(response => response.json())
                          .then(result => {
                            setorders(result)
                            setpopup(false)

                          })
                          .catch(error => console.log('error', error));





                        await setTimeout(() => {
                          hide(); // Call hide to stop the loading message
                          message.success("Action completed successfully");
                        }, 2000);

                      }} className='hovar' style={{ display: "flex", color: 'red', alignItems: 'center' }}>  Detele This Data <AiFillDelete style={{ width: '20px', height: '20px', margin: "0 10px" }} /></h3>
                      </div>


                    </div>
                  </>)}
                  <div className='bownpopupbutton'>
                    <button onClick={() => {
                      setpopup(false)

                    }
                    } style={{ border: '1px solid red', color: 'black' }} >Close</button>






                  </div>

                </div>
              </>}





              <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>KITS</th>
                    {/* <th>Email </th>
  
  
                    <th>KitCodes</th> */}

                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>




                  {orders.map((value, index) => {



                    return (<>
                      <tr>

                        <td>{sno1++}</td>
                        {/* <td style={{width:"fit-content"}}>{value.kitExist.map((value)=><>{value},</>)}
                        {value.kitExist.map((value)=><>{value},</>)}
                        
                        </td> */}
                        <td>
                          {/* Kits found in the portal (Green) */}
                          {value.kitExist.map((kit, index) => (
                            <span style={{ color: "green" }} key={`exist-${index}`}>
                              {kit}{index !== value.kitExist.length - 1 ? ", " : ""}
                            </span>
                          ))}

                          {/* Kits found on Enbiosis but not in the portal (Blue) */}
                          {value.kitExistOnEnbiosis.length > 0 && value.kitExist.length > 0 && ", "} {/* Add a comma if both exist */}
                          {value.kitExistOnEnbiosis.map((kit, index) => (
                            <span style={{ color: "blue" }} key={`enbiosis-${index}`}>
                              {kit}{index !== value.kitExistOnEnbiosis.length - 1 ? ", " : ""}
                            </span>
                          ))}

                          {/* Kits not found anywhere (Red) */}
                          {value.kitNotExist.length > 0 && (value.kitExist.length > 0 || value.kitExistOnEnbiosis.length > 0) && ", "} {/* Add a comma if necessary */}
                          {value.kitNotExist.map((kit, index) => (
                            <span style={{ color: "red" }} key={`notexist-${index}`}>
                              {kit}{index !== value.kitNotExist.length - 1 ? ", " : ""}
                            </span>
                          ))}
                        </td>


                        {/* <td style={{textAlign:'left'}}>{kit_code.map((value2, index) => (<>
                          {index + 1} - {value2}<br/>
                        </>))}</td> */}


                        <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                          <button className='button'
                            onClick={() => {
                              setpopup(true)



                              // setkitcode(value.KitCodes.split("\n"))

                              setorderdetails(value)
                            }}

                          > Details</button>



                        </td>


                      </tr>
                    </>)
                  })}















                </tbody>
              </table>







            </div>

          </div>



        </> : <NotLogin />}
    </div>
  )
}

export default Samplerecieveddata
