import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Navbar from "../../components/navbar";
import {
  AiFillDelete,
  AiOutlineMail,
  AiTwotoneInteraction,
} from "react-icons/ai";
import "../../css/shipping.css";
import { AutoComplete, message } from "antd";
import axios from "axios";

function Samplereturnform() {
  const [btn, setbtn] = useState(true);

  const [KitCodes, setKitCodes] = useState("");

  const [formdone, setformdone] = useState(false);
  const apiurlforbackend = process.env.REACT_APP_APIURL;
  
  const uploaddata = async (e) => {
    e.preventDefault();

    
      setbtn(false);
      const hide = message.loading("Action in progress", 0);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded2 = new URLSearchParams();

      urlencoded2.append("KitCodes", KitCodes);

      var requestOptions2 = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded2,
        redirect: "follow",
      };

      await fetch(
        `${apiurlforbackend}/samplerecieve-form`,
        requestOptions2
      )
        .then((response) => response.text())
        .then((result) => {

          setKitCodes("");

          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success(result);
          }, );
          setformdone(true);
        })
        .catch((error) => console.log("error", error));
      await setbtn(true);
    
  };






  return (
    <>
      <Navbar />
      <div
        className="deshboardmain"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div
          className="popup"
          style={{
            height: "fit-contant",
            backgroundColor: "#fdgh",
            position: "relative",
            maxHeight: "fit-content",
            zIndex: "0",
            left: "0",
            top: "0",
          }}
        >
          {formdone ? (

            <>
              <h2 style={{ color: "#6E4E9F" }}>Thank You</h2>
              
            </>
          ) : (
            <>
              <form className="shippinfform2" onSubmit={uploaddata}>
            
                  {/* <label>
                    Kit Codes <span style={{ color: "red" }}></span>
                  </label> */}
                  <textarea
                    onChange={(e) => setKitCodes(e.target.value)}
                    value={KitCodes}
                    placeholder="Please Paste Kit Codes"
                    required
                  />
                

               
                <button disabled={!btn} style={{ width: "100%" }}>
                  SEND
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Samplereturnform;
