import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import Navbar from '../../components/navbar';
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import '../../css/shipping.css'
import { AutoComplete, message } from 'antd';
import axios from 'axios';

function Samplereturnform() {




    const [btn, setbtn] = useState(true)

    const [Name, setName] = useState("")
    const [lName, setlName] = useState("")
    const [KitCodes, setKitCodes] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")


    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const [address, setaddress] = useState('');
    const [StreetAddress, setStreetAddress] = useState('');
    const [Postal , setPostal ] = useState('');

    const [formdone, setformdone] = useState(false)


    const uploaddata = async (e) => {

        e.preventDefault()


        if (country === "") {

            message.error("Please Enter your Country")
        } else {


            setbtn(false)
            const hide = message.loading("Action in progress", 0)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded2 = new URLSearchParams();
            urlencoded2.append("Name", Name);
            urlencoded2.append("lName", lName);
            urlencoded2.append("KitCodes", KitCodes);
            urlencoded2.append("Email", Email);
            urlencoded2.append("Phone", Phone);
            urlencoded2.append("Country", country);
            urlencoded2.append("address", address);
            urlencoded2.append("StreetAddress", StreetAddress);
            urlencoded2.append("Postal", Postal);



            var requestOptions2 = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded2,
                redirect: 'follow'
            };


            await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/samplereturn-form", requestOptions2)
                .then(response => response.text())
                .then(result => {

                    setName("")
                    setlName("")
                    setKitCodes("")
                    setEmail("")
                    setPhone("")
                    setCountry("")
                    setaddress("")
                    setStreetAddress("")
                    setPostal("")
                    setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success(result);
                    }, 2000);
                    setformdone(true)

                })
                .catch(error => console.log('error', error));
            await setbtn(true)
        }

    }


    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                // Sort countries alphabetically by name
                const sortedCountries = response.data.sort((a, b) =>
                    a.name.common.localeCompare(b.name.common)
                );
                setCountries(sortedCountries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);





    const [options, setOptions] = useState([{
        value: "United Kingdom"
    }, {
        value: "Ireland"
    }]);



    const handleSearch = (searchText) => {
        if (!searchText) {
            setOptions([]); // Clear options if no search text
            return;
        }

        // Filter countries that include the search text
        const filteredCountries = countries
            .filter(item => item.name.common.toLowerCase().includes(searchText.toLowerCase()))
            .map(item => ({
                value: item.name.common, // Use country name as value
                label: item.name.common  // Use country name as label
            }));

        setOptions(filteredCountries);
    };

 
    return (<>


        <Navbar />
        <div className='deshboardmain' style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className='popup' style={{ height: 'fit-contant', backgroundColor: '#fdgh', position: 'relative', maxHeight: 'fit-content', zIndex: '0', left: "0", top: '0' }}>

                {formdone ? (<>

                    <h2 style={{color:'#6E4E9F'}} >Thank You</h2>
                    <h4  style={{color:'#6E4E9F'}} >We will be in touch via email shortly</h4>
                </>) : (<>
                    <form className='shippinfform' onSubmit={uploaddata}>
                        <div className='lableee'>
                            <label>First Name <span style={{ color: "red" }}>*</span></label>
                            <input onChange={(e) => setName(e.target.value)} value={Name} required placeholder='First Name' />
                        </div>

                        <div className='lableee'>
                            <label>Last Name <span style={{ color: "red" }}>*</span></label>
                            <input onChange={(e) => setlName(e.target.value)} value={lName} required placeholder='Last Name' />
                        </div>

                        <div className='lableee'>
                            <label>Kit Codes/s <span style={{ color: "red" }}></span></label>
                            <textarea onChange={(e) => setKitCodes(e.target.value)} value={KitCodes} placeholder='Kit Codes' />
                        </div>


                        <div className='lableee'>
                            <label>Your Email <span style={{ color: "red" }}>*</span></label>
                            <input onChange={(e) => setEmail(e.target.value)} value={Email} required placeholder='Email' />
                        </div>
                        <div className='lableee'>
                            <label>Your Address <span style={{ color: "red" }}>*</span></label>
                            <input onChange={(e) => setaddress(e.target.value)} value={address} required placeholder='Address' />
                        </div>

                     

                        <div className='lableee'>
                            <label>Street Address <span style={{ color: "red" }}>*</span></label>
                            <input onChange={(e) => setStreetAddress(e.target.value)} value={StreetAddress} required placeholder='Street Address' />
                        </div>

                        <div className='lableee'>
                            <label>Country  <span style={{ color: "red" }}>*</span></label>
                            <AutoComplete
                                options={options}
                                style={{ backgroundColor: '#D2CDFF' }}
                                placeholder="Search for Conutry"
                                required
                                className='autocompleee'
                                value={country}
                                onSearch={handleSearch}  // When typing, call handleSearch
                                onSelect={(value) => setCountry(value)}  // Handle selection
                                onChange={(e) => setCountry(e)}
                            />
                        </div>

                
                        <div className='lableee'>
                            <label>Postal / ZIP code <span style={{ color: "red" }}>*</span></label>
                            <input onChange={(e) => setPostal(e.target.value)} value={Postal} required placeholder='Postal / ZIP code ' />
                        </div>


                        <div className='lableee'>
                            <label>Contact telephone number<span style={{ color: "red" }}>*</span> (include international dialing code) </label>
                            <input onChange={(e) => setPhone(e.target.value)} value={Phone} required placeholder='Phone' />
                        </div>

               

                        <div className='lableee'>
                            <label>Once the form has been completed, we will email you to arrange the pick up at a convenient time and location</label>
                            {/* <textarea onChange={(e) => setKitCodes(e.target.value)} value={KitCodes} placeholder='Kit Codes' /> */}
                        </div>
                        <button disabled={!btn} style={{ width: "100%" }}>SEND</button>
                    </form>
                </>)}


            </div>
        </div>

    </>)
}

export default Samplereturnform
