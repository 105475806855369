import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
// import 'antd/dist/reset.css'; // Import Ant Design styles

import Index from "./pages";
import Desboard from "./pages/desboard";
import Adminkit from "./pages/adminkit";
import Adminreport from "./pages/adminreport";
import Practitioner from "./pages/Practitioner";
import Profile from "./pages/Profile";
import Viewreport from "./pages/viewreport";
import Viewreport2 from "./pages/viewreport2";
import { useNavigate } from "react-router-dom";

import Microkit from "./pages/microkit";

import Micropre from "./pages/Micropre";

import Pracstate from "./pages/pracstates";
import Orderlables from "./pages/orderlables";
import Ackpage from "./pages/ackpage";
import Bucket from "./pages/dropresult";
import Shippingform from "./pages/shippingform";
import Shipping from "./pages/shipping";
import KitAnalysis from "./pages/KitAnalysis";
import Kitregistration from "./pages/kitregistration";
import Helpcenter from "./pages/helpcenter";
import Tickets from "./pages/tickets";
import Ticketsid from "./pages/ticketsid";
import Webinar from "./pages/Webinar";
import Webinardetails from "./pages/Webinardetails";
import Meeting from "./pages/meeting";
import Eventinfoform from "./pages/eventinfoform";
import Eventinfo from "./pages/eventinfo";
import Maps from "./pages/maps";
import Dnamapform from "./pages/Dnamapform";
import Praform from "./pages/praform";
import Leads from "./pages/Leads";
import Orderstates from "./pages/orderstates";
import Pararesults from "./pages/pararesults";

import Meetings from "./pages/meetings";
import Meetingmeet from "./pages/meetingmeet";
import Fsform from "./pages/fsform";
import Fsformdata from "./pages/foodsform";
import Meetingsusers from "./pages/meetingsusers";
import Sendemail from "./pages/sendemail";

import Candidaform from "./pages/candidaform";
import Candidaformdata from "./pages/candidaformdata";
import Leadsstate from "./pages/Leadsstates";
import Meetingsdetails from "./pages/meetingsdetails";

import KITS from "./components/kits";
import KitReport from "./components/report";
import Practitionerpage from "./components/practitioners";
import Fsformdatawl from "./pages/foodsformwl";
import Teamticks from "./pages/teams tickets/teamticks";
import Teamticketsdetails from "./pages/teams tickets/teamticketsdetails";
import NotLogin from "./components/notLogin";
import Leadscoverted from './pages/Leadscoverted';
import Forms from "./components/forms";
import Formview from "./components/formsview";
import Singalemail from "./pages/emails/singalemail";
import Massemail from "./pages/emails/massemail";
import Emailreport from "./pages/emails/emailreport";
import Emailtemplates from "./pages/emails/emailtemplates";
import Samplereturnform from "./pages/samplereturn/samplereturn-form";
import Samplerecievedform from "./pages/samplerecieved/samplerecieved-form";
import Samplereturndata from "./pages/samplereturn/samplereturn-data";
import Webinarpast from "./pages/Webinarpast";
import Samplerecieveddata from "./pages/samplerecieved/Samplerecieved-data";
import Formforpopup from "./pages/prac second step form/formforpopup";
import Secondstepformdata from "./pages/prac second step form/secondstep-data";
import ReportStyles from "./pages/reportStyles"
import Releasereports from "./pages/releasereports";




// view kits 
const Fskits = (params) => <KITS kittype="Food Sensitivtiy 100" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Fskits210 = (params) => <KITS kittype="Food Sensitivtiy 210" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Fskitsmap = (params) => <KITS kittype="FoodSensitivityMap" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Parasitologykit = (params) => <KITS kittype="Parasitology Test" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Candidakit = (params) => <KITS kittype="CandidaProfile" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const DNAkit = (params) => <KITS kittype="DNAMap Genetic Test" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const MicrobiomeAdvanced = (params) => <KITS kittype="MicrobiomeAdvanced" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Microbiomeplus = (params) => <KITS kittype="MicrobiomePlus" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Oralmicrobiome = (params) => <KITS kittype="Oral Microbiome" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Microbiomkit = (params) => <KITS kittype="Microbiome" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;


// view report 
const Fs100report = (params) => <KitReport kittype="Food Sensitivtiy 100" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Fs210report = (params) => <KitReport kittype="Food Sensitivtiy 210" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Fsmapreport = (params) => <KitReport kittype="FoodSensitivityMap" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Parasitologykitreport = (params) => <KitReport kittype="Parasitology Test" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Candidakitreport = (params) => <KitReport kittype="CandidaProfile" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Microbiomeplusreport = (params) => <KitReport kittype="MicrobiomePlus" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const MicrobiomeAdvancedreport = (params) => <KitReport kittype="MicrobiomeAdvanced" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const DNAkitreport = (params) => <KitReport kittype="DNAMap Genetic Test" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Oralmicrobiomereport = (params) => <KitReport kittype="Oral Microbiome" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Microbiomereport = (params) => <KitReport kittype="Microbiome" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;


// view Practitioner 
const Fs100prac = (params) => <Practitionerpage kittype="Food Sensitivtiy 100" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Fs210prac = (params) => <Practitionerpage kittype="Food Sensitivtiy 210" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Fsmapprac = (params) => <Practitionerpage kittype="FoodSensitivityMap" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Parasitologykitprac = (params) => <Practitionerpage kittype="Parasitology Test" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Candidakitprac = (params) => <Practitionerpage kittype="CandidaProfile" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Microbiomeplusprac = (params) => <Practitionerpage kittype="MicrobiomePlus" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const MicrobiomeAdvancedprac = (params) => <Practitionerpage kittype="MicrobiomeAdvanced" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const DNAkitprac = (params) => <Practitionerpage kittype="DNAMap Genetic Test" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Oralmicrobiomeprac = (params) => <Practitionerpage kittype="Oral Microbiome" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const Microbiomepre = (params) => <Practitionerpage kittype="Microbiome" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;


// fill form 
const FSFORM = () => <Forms kittype="FoodSensitivityMap" />;
const CANDIDAFORM = () => <Forms kittype="CandidaProfile" />;
const OralmicrobiomeFORM = () => <Forms kittype="Oral Microbiome" />;


const FSFORMview = (params) => <Formview kittype="FoodSensitivityMap" formtype="normal" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const CANDIDAFORMview = (params) => <Formview kittype="CandidaProfile" formtype="normal" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const ParasitologyFORMview = (params) => <Formview kittype="Parasitology Test" formtype="normal" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const DNAMapFORMview = (params) => <Formview kittype="DNAMap Genetic Test" formtype="normal" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;
const OralmicrobiomeFORMview = (params) => <Formview kittype="Oral Microbiome" formtype="normal" portalid={params.portalid} backgroundcolor={params.backgroundcolor} color={params.color} Buttonbackgroundcolor={params.Buttonbackgroundcolor} Buttoninsidecolor={params.Buttoninsidecolor} prinarycolor={params.prinarycolor} prinarycolortext={params.prinarycolortext} seconderycolor={params.seconderycolor} />;


const FSFORMview_wl = () => <Formview kittype="FoodSensitivityMap" formtype="White-label" />;
const CANDIDAFORMview_wl = () => <Formview kittype="CandidaProfile" formtype="White-label" />;
const ParasitologyFORMview_wl = () => <Formview kittype="Parasitology Test" formtype="White-label" />;
const DNAMapFORMview_wl = () => <Formview kittype="DNAMap Genetic Test" formtype="White-label" />;
const OralmicrobiomeFORMview_wl = () => <Formview kittype="Oral Microbiome" formtype="White-label" />;


function ScrollToTop() {
  const { pathname } = useLocation();
  const loginemail = Cookies.get("email");
  const loginname = Cookies.get("Name");
  const id = Cookies.get("id");
  const token = Cookies.get("Token");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("_id", id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        "https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfocheck",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result === "deactivated") {
            const allCookies = Cookies.get();
            for (const cookieName in allCookies) {
              Cookies.remove(cookieName);
            }
            navigate("/");
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [pathname]);

  return null;
}

const Report = () => {
  const { id } = useParams();

  const getinfo = () => {
    let data = JSON.stringify({
      id: id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/getidandtype",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const CNAME = response.data.data.Kittype.toLowerCase().replace(
          /\s+/g,
          ""
        );
        const urltothrough = `https://${CNAME}-report.yourgutmap.co.uk/${response.data.data._id}`;
        window.location.href = urltothrough;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getinfo();
  }, []);
};



function App() {
  const apiurlforbackend = process.env.REACT_APP_APIURL;

  const [logourl, setlogourl] = useState(Cookies.get("logourl") || "empty.gif");
  const [logo2, setlogo2] = useState(Cookies.get("logo2") || "empty.gif");

  const [backgroundcolor, setbackgroundcolor] = useState(Cookies.get("backgroundcolor") || "#999");
  const [color, setcolor] = useState(Cookies.get("color") || "#000");
  const [Buttonbackgroundcolor, setButtonbackgroundcolor] = useState(Cookies.get("Buttonbackgroundcolor") || "#dddddd");
  const [Buttoninsidecolor, setButtoninsidecolor] = useState(Cookies.get("Buttoninsidecolor") || "#000");
  const [prinarycolor, setprinarycolor] = useState(Cookies.get("prinarycolor") || "#000");
  const [prinarycolortext, setprinarycolortext] = useState(Cookies.get("prinarycolortext") || "#000");
  const [seconderycolor, setseconderycolor] = useState(Cookies.get("seconderycolor") || "#000");
  const [portalid, setportalid] = useState(Cookies.get("portalid") || "#000");
  const [febicon, setfebicon] = useState(Cookies.get("febicon") || "#000");

  const [dashboard, setDashboard] = useState(Cookies.get("dashboard") || false);
  const [microbiome, setMicrobiome] = useState(Cookies.get("microbiome") || false);
  const [microbiomePlus, setMicrobiomePlus] = useState(Cookies.get("microbiomePlus") || false);
  const [microbiomeAdvanced, setMicrobiomeAdvanced] = useState(Cookies.get("microbiomeAdvanced") || false);
  const [foodSensitivity, setFoodSensitivity] = useState(Cookies.get("foodSensitivity") || false);
  const [foodSensitivityMap, setFoodSensitivityMap] = useState(Cookies.get("foodSensitivityMap") || false);
  const [dnaMap, setDnaMap] = useState(Cookies.get("dnaMap") || false);
  const [parasitologyTest, setParasitologyTest] = useState(Cookies.get("parasitologyTest") || false);
  const [candidaProfile, setCandidaProfile] = useState(Cookies.get("candidaProfile") || false);
  const [sampleReceived, setSampleReceived] = useState(Cookies.get("sampleReceived") || false);
  const [uploadResults, setUploadResults] = useState(Cookies.get("uploadResults") || false);
  const [allPractitioners, setAllPractitioners] = useState(Cookies.get("allPractitioners") || false);
  const [dataAndAnalytics, setDataAndAnalytics] = useState(Cookies.get("dataAndAnalytics") || false);
  const [ordersInformation, setOrdersInformation] = useState(Cookies.get("ordersInformation") || false);
  const [maps, setMaps] = useState(Cookies.get("maps") || false);
  const [shipping, setShipping] = useState(Cookies.get("shipping") || false);
  const [whiteLabel, setWhiteLabel] = useState(Cookies.get("whiteLabel") || false);
  const [academy, setAcademy] = useState(Cookies.get("academy") || false);
  const [meetings, setMeetings] = useState(Cookies.get("meetings") || false);
  const [emails, setEmails] = useState(Cookies.get("emails") || false);
  const [helpCenter, setHelpCenter] = useState(Cookies.get("helpCenter") || false);
  const [profile, setProfile] = useState(Cookies.get("profile") || false);
  const [ordersInformationData, setordersInformationData] = useState(Cookies.get("ordersInformationData") || []);


  const definedRoutes = [
    "/",
    "/report/:id",
    "/kitack",
    "/shipping",
    "/leads",
    "/leads-state",
    "/eventinfo",
    "/bucket",
    "/dashboard",
    "/dashboard/analysis",
    "/dashboard/webinar",
    "/dashboard/past-webinar",
    "/dashboard/webinar/:id",
    "/dashboard/webinar/meeting/:id",
    "/dashboard/helpcenter",
    "/dashboard/tickets",
    "/dashboard/order-states",
    "/dashboard/map",
    "/dashboard/meet",

    "/dashboard/meet-user",
    "/dashboard/meet-user/:id",
    "/dashboard/meet/:id",
    "/dashboard/tickets/:id",
    "/dashboard/kitregistration",
    "/dashboard/para-report/:id",
    "/dashboard/view-report/:id",
    "/dashboard/view-report100/:id",
    "/dashboard/teams/tickets",
    "/dashboard/teams/tickets/:id",
    "/oralmicrobiomeform",
    ...(foodSensitivity ? ["/dashboard/fs100/kit"] : []),
    ...(foodSensitivity ? ["/dashboard/fs210/kit"] : []),
    ...(foodSensitivity ? ["/dashboard/fs100/report"] : []),
    ...(foodSensitivity ? ["/dashboard/fs210/report"] : []),
    "/dashboard/uploadreport",
    "/dashboard/fs210/viewreport/:id",
    "/dashboard/fs100/practitioner",
    "/dashboard/fs210/practitioner",
    "/foodsensitivityform",
    "/fs-Form",
    "/dashboard/fsmap/kit",
    "/dashboard/fsmap/report",
    "/dashboard/fsmap/practitioner",
    "/dashboard/microbiome/kit",
    "/dashboard/microbiome/practitioner",
    "/dashboard/microbiomeplus/kit",
    "/dashboard/microbiomeplus/report",
    "/dashboard/microbiomeplus/practitioner",
    "/dashboard/microbiomeadvanced/kit",
    "/dashboard/microbiomeadvanced/report",
    "/dashboard/microbiomeadvanced/practitioner",
    "/dashboard/oralmicrobiome/kit",
    "/dashboard/oralmicrobiome/report",
    "/dashboard/oralmicrobiome/practitioner",
    "/dashboard/dna/kit",
    "/dnamap-form",
    "/dashboard/dna/report",
    "/dashboard/dna/practitioner",
    "/dashboard/Parasitology/kit",
    "/dashboard/Parasitology/report",
    "/dashboard/Parasitology/practitioner",
    "/Parasitology-Test-Form",
    "/dashboard/candidaprofile/kit",
    "/dashboard/candidaprofile/report",
    "/dashboard/candidaprofile/practitioner",
    "/candidaform",
    "/foodsensitivityform-view",
    "/candidaform-view",
    "/dnamapform-view",
    "/parasitologyform-view",
    "/foodsensitivityform-view-wl",
    "/candidaform-view-wl",
    "/dnamapform-view-wl",
    "/parasitologyform-view-wl",
    "/dashboard/kit",
    "/leads-converted",
    "/dashboard/practitioner",
    "/dashboard/practitionerstates",
    "/dashboard/orders",
    "/dashboard/shippingdata",
    "/dashboard/eventinfogdata",
    "/dashboard/profile",
    "/dashboard/send-email",
    "/dashboard/send-mass-email",
    "/dashboard/email-reports",
    "/dashboard/email-templates",
    "/samplereturn",
    "/samplerecieved",
    "/dashboard/samplereturn-data",
    "/dashboard/samplerecieved-data",
    "/formformpopup",
    "/new-Practitioner-data",
    "/dashboard/microbiome/report",
    "/dashboard/release-reults",
    "/oralmicrobiomeform-view-wl",
    "/oralmicrobiomeform-view",
    "/dashboard/reportstyles"
  ];

  function NotFound() {
    return (
      <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <img src="/page not found.gif" width="20%" alt="Page Not Found" />
      </div>
    );
  }




  function AppRouter() {
    const location = useLocation();
    const isValidRoute = definedRoutes.some((route) => {
      const dynamicRoute = route.replace(/:\w+/g, ".*"); // Convert dynamic params into regex
      const regex = new RegExp(`^${dynamicRoute}$`);
      return regex.test(location.pathname);
    });

    return (
      <Routes>
        {!isValidRoute && <Route path="*" element={<NotFound />} />}
      </Routes>

    );
  }



  useEffect(() => {

    let baseUrl = `${window.location.host}`;

    if (baseUrl.includes("localhost") || baseUrl.match(/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/)) {
      baseUrl = "portal.yourgutmap.co.uk";
    }
    let data = JSON.stringify({
      "url": baseUrl

    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/get-portal-by-url',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setlogourl(response.data.logourl)
        setlogo2(response.data.logo2)

        document.title = response.data.bartitle;
        setbackgroundcolor(response.data.backgroundcolor)
        setcolor(response.data.testcolor)
        setButtonbackgroundcolor(response.data.Buttonbackgroundcolor)
        setButtoninsidecolor(response.data.Buttoninsidecolor)
        setprinarycolor(response.data.prinarycolor)
        setprinarycolortext(response.data.prinarycolortext)
        setportalid(response.data._id)
        setseconderycolor(response.data.seconderycolor)

        setfebicon(response.data.febicon)

        const link = document.querySelector("link[rel~='icon']");
        if (link) {
          link.href = response.data.febicon;
        } else {
          const newLink = document.createElement("link");
          newLink.rel = "icon";
          newLink.href = response.data.febicon;
          document.head.appendChild(newLink);

        }

        setDashboard(response.data.dashboard);
        setMicrobiome(response.data.microbiome);
        setMicrobiomePlus(response.data.microbiomePlus);
        setMicrobiomeAdvanced(response.data.microbiomeAdvanced);
        setFoodSensitivity(response.data.foodSensitivity);
        setFoodSensitivityMap(response.data.foodSensitivityMap);
        setDnaMap(response.data.dnaMap);
        setParasitologyTest(response.data.parasitologyTest);
        setCandidaProfile(response.data.candidaProfile);
        setSampleReceived(response.data.sampleReceived);
        setUploadResults(response.data.uploadResults);
        setAllPractitioners(response.data.allPractitioners);
        setDataAndAnalytics(response.data.dataAndAnalytics);
        setOrdersInformation(response.data.ordersInformation);
        setMaps(response.data.maps);
        setShipping(response.data.shipping);
        setWhiteLabel(response.data.whiteLabel);
        setAcademy(response.data.academy);
        setMeetings(response.data.meetings);
        setEmails(response.data.emails);
        setHelpCenter(response.data.helpCenter);
        setProfile(response.data.profile);
        setordersInformationData(response.data.ordersInformationData);






        Cookies.set("logo2", response.data.logo2, { expires: 7 });
        Cookies.set("febicon", response.data.febicon, { expires: 7 });

        Cookies.set("logourl", response.data.logourl, { expires: 7 });
        Cookies.set("backgroundcolor", response.data.backgroundcolor, { expires: 7 });
        Cookies.set("color", response.data.testcolor, { expires: 7 });
        Cookies.set("Buttonbackgroundcolor", response.data.Buttonbackgroundcolor, { expires: 7 });
        Cookies.set("Buttoninsidecolor", response.data.Buttoninsidecolor, { expires: 7 });
        Cookies.set("prinarycolor", response.data.prinarycolor, { expires: 7 });
        Cookies.set("prinarycolortext", response.data.prinarycolortext, { expires: 7 });
        Cookies.set("portalid", response.data._id, { expires: 7 });
        Cookies.set("seconderycolor", response.data.seconderycolor, { expires: 7 });

        Cookies.set("dashboard", response.data.dashboard, { expires: 7 });
        Cookies.set("microbiome", response.data.microbiome, { expires: 7 });
        Cookies.set("microbiomePlus", response.data.microbiomePlus, { expires: 7 });
        Cookies.set("microbiomeAdvanced", response.data.microbiomeAdvanced, { expires: 7 });
        Cookies.set("foodSensitivity", response.data.foodSensitivity, { expires: 7 });
        Cookies.set("foodSensitivityMap", response.data.foodSensitivityMap, { expires: 7 });
        Cookies.set("dnaMap", response.data.dnaMap, { expires: 7 });
        Cookies.set("parasitologyTest", response.data.parasitologyTest, { expires: 7 });
        Cookies.set("candidaProfile", response.data.candidaProfile, { expires: 7 });
        Cookies.set("sampleReceived", response.data.sampleReceived, { expires: 7 });
        Cookies.set("uploadResults", response.data.uploadResults, { expires: 7 });
        Cookies.set("allPractitioners", response.data.allPractitioners, { expires: 7 });
        Cookies.set("dataAndAnalytics", response.data.dataAndAnalytics, { expires: 7 });
        Cookies.set("ordersInformation", response.data.ordersInformation, { expires: 7 });
        Cookies.set("maps", response.data.maps, { expires: 7 });
        Cookies.set("shipping", response.data.shipping, { expires: 7 });
        Cookies.set("whiteLabel", response.data.whiteLabel, { expires: 7 });
        Cookies.set("academy", response.data.academy, { expires: 7 });
        Cookies.set("meetings", response.data.meetings, { expires: 7 });
        Cookies.set("emails", response.data.emails, { expires: 7 });
        Cookies.set("helpCenter", response.data.helpCenter, { expires: 7 });
        Cookies.set("profile", response.data.profile, { expires: 7 });
      })
      .catch((error) => {
        console.log(error);
      });



  }, [])


  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Index portalid={portalid} logourl={logourl} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} foodSensitivity={foodSensitivity} foodSensitivityMap={foodSensitivityMap} microbiome={microbiome} dnaMap={dnaMap} parasitologyTest={parasitologyTest} candidaProfile={candidaProfile} allPractitioners={allPractitioners} academy={academy} />} />
          <Route path="/report/:id" element={<Report />} />
          <Route path="/kitack" element={<Ackpage />} />
          <Route path="/shipping" element={<Shippingform />} />
          <Route path="/formformpopup" element={<Formforpopup />} />
          <Route path="/leads" element={<Leads />} />
          <Route path='/leads-converted' element={<Leadscoverted />} />
          <Route path="/leads-state" element={<Leadsstate />} />
          <Route path="/dashboard/uploadreport" element={<Adminreport />} />
          <Route path="/samplereturn" element={<Samplereturnform />} />
          <Route path="/samplerecieved" element={<Samplerecievedform />} />
          <Route path="/dashboard/samplereturn-data" element={<Samplereturndata />} />
          <Route path="/dashboard/samplerecieved-data" element={<Samplerecieveddata />} />
          <Route path="/dashboard/release-reults" element={<Releasereports />} />


          <Route path="/eventinfo" element={<Eventinfoform />} />
          <Route path="/bucket" element={<Bucket />} />
          <Route path="/dashboard" element={<Desboard portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} />
          <Route path="/dashboard/analysis" element={<KitAnalysis />} />
          <Route path="/dashboard/past-webinar" element={<Webinarpast />} />

          <Route path="/dashboard/webinar" element={<Webinar />} />
          <Route path="/dashboard/webinar/:id" element={<Webinardetails />} />
          <Route path="/dashboard/webinar/meeting/:id" element={<Meeting />} />
          <Route path="/dashboard/helpcenter" element={<Helpcenter />} />
          <Route path="/dashboard/tickets" element={<Tickets />} />
          <Route path="/dashboard/order-states" element={<Orderstates />} />
          <Route path="/dashboard/map" element={<Maps />} />
          <Route path="/dashboard/meet" element={<Meetings />} />

          <Route path="/dashboard/meet-user" element={<Meetingsusers />} />
          <Route path="/dashboard/meet-user/:id" element={<Meetingsdetails />} />
          <Route path="/dashboard/meet/:id" element={<Meetingmeet />} />
          <Route path="/dashboard/tickets/:id" element={<Ticketsid />} />
          <Route path="/dashboard/kitregistration" element={<Kitregistration />} />
          <Route path="/dashboard/para-report/:id" element={<Pararesults />} />
          <Route path="/dashboard/view-report/:id" element={<Viewreport2 />} />
          <Route path="/dashboard/view-report100/:id" element={<Viewreport2 />} />

          {/* teamstickets */}
          <Route path="/dashboard/teams/tickets" element={<Teamticks />} />
          <Route path="/dashboard/teams/tickets/:id" element={<Teamticketsdetails />} />
        </Routes>

        <Routes>
          {/* fs/  */}
          {foodSensitivity && <>  <Route path="/dashboard/fs100/kit" element={<Fskits portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {foodSensitivity && <>   <Route path="/dashboard/fs210/kit" element={<Fskits210 portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {foodSensitivity && <>   <Route path="/dashboard/fs100/report" element={<Fs100report portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {foodSensitivity && <>   <Route path="/dashboard/fs210/report" element={<Fs210report portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {/* {foodSensitivity && <>   <Route path="/dashboard/fs210/viewreport/:id" element={<Viewreport />} /></>} */}
          {foodSensitivity && <>   <Route path="/dashboard/fs100/practitioner" element={<Fs100prac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {foodSensitivity && <>   <Route path="/dashboard/fs210/practitioner" element={<Fs210prac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {/* <Route path="/foodsensitivityform" element={<Fsform />} /> */}
          {/* <Route path="/foodsensitivityform-data" element={<Fsformdata />} /> */}
          {/* {foodSensitivity && <>   <Route path="/fs-Form" element={<Fsformdatawl />} /> */}

          {/* fsmap/  */}
          {foodSensitivityMap && <>  <Route path="/dashboard/fsmap/kit" element={<Fskitsmap portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {foodSensitivityMap && <>   <Route path="/dashboard/fsmap/report" element={<Fsmapreport portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {foodSensitivityMap && <>   <Route path="/dashboard/fsmap/practitioner" element={<Fsmapprac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}

          {/* micro/     */}
          {microbiome && <> <Route path="/dashboard/microbiome/kit" element={<Microbiomkit portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {microbiome && <> <Route path="/dashboard/microbiome/practitioner" element={<Microbiomepre portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {microbiome && <> <Route path="/dashboard/microbiome/report" element={<Microbiomereport portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}

          {/* micro plus/     */}
          {microbiomePlus && <>  <Route path="/dashboard/microbiomeplus/kit" element={<Microbiomeplus portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {microbiomePlus && <><Route path="/dashboard/microbiomeplus/report" element={<Microbiomeplusreport portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {microbiomePlus && <> <Route path="/dashboard/microbiomeplus/practitioner" element={<Microbiomeplusprac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}

          {/* micro adv/     */}
          {microbiomeAdvanced && <><Route path="/dashboard/microbiomeadvanced/kit" element={<MicrobiomeAdvanced portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {microbiomeAdvanced && <><Route path="/dashboard/microbiomeadvanced/report" element={<MicrobiomeAdvancedreport portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {microbiomeAdvanced && <><Route path="/dashboard/microbiomeadvanced/practitioner" element={<MicrobiomeAdvancedprac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}


          {/* micro adv/     */}
          {microbiomeAdvanced && <><Route path="/dashboard/oralmicrobiome/kit" element={<Oralmicrobiome portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {microbiomeAdvanced && <><Route path="/dashboard/oralmicrobiome/report" element={<Oralmicrobiomereport portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {microbiomeAdvanced && <><Route path="/dashboard/oralmicrobiome/practitioner" element={<Oralmicrobiomeprac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}



          {/* dna/     */}
          {dnaMap && <>  <Route path="/dashboard/dna/kit" element={<DNAkit portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {dnaMap && <> <Route path="/dashboard/dna/report" element={<DNAkitreport portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {dnaMap && <>  <Route path="/dashboard/dna/practitioner" element={<DNAkitprac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}

          {/* Parasitology */}
          {parasitologyTest && <> <Route path="/dashboard/Parasitology/kit" element={<Parasitologykit portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {parasitologyTest && <> <Route path="/dashboard/Parasitology/report" element={<Parasitologykitreport portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {parasitologyTest && <> <Route path="/dashboard/Parasitology/practitioner" element={<Parasitologykitprac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}

          {/* condida */}
          {candidaProfile && <>  <Route path="/dashboard/candidaprofile/kit" element={<Candidakit portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {candidaProfile && <>  <Route path="/dashboard/candidaprofile/report" element={<Candidakitreport portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {candidaProfile && <>  <Route path="/dashboard/candidaprofile/practitioner" element={<Candidakitprac portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}
          {/* <Route path="/candidaform" element={<Candidaform />} /> */}
          {/* <Route path="/candidaform-data" element={<Candidaformdata />} /> */}
          {/* <Route path="/dashboard/kit" element={<Adminkit />} /> */}
          {allPractitioners && <Route path="/dashboard/practitioner" element={<Practitioner portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} />}
          <Route path="/dashboard/practitionerstates" element={<Pracstate />} />
          {ordersInformation && <Route path="/dashboard/orders" element={<Orderlables ordersInformationData={ordersInformationData} logo2={logo2} portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} />}
          {shipping && <>   <Route path="/dashboard/shippingdata" element={<Shipping />} /></>}
          <Route path="/dashboard/eventinfogdata" element={<Eventinfo />} />
          {profile && <> <Route path="/dashboard/profile" element={<Profile portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} /></>}

        </Routes>





        {/* form urls  */}
        <Routes>
          <Route path="/foodsensitivityform" element={<FSFORM />} />

          <Route path="/candidaform" element={<CANDIDAFORM />} />
          <Route path="/oralmicrobiomeform" element={<OralmicrobiomeFORM />} />


          <Route path="/foodsensitivityform-view" element={<FSFORMview portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} />
          <Route path="/candidaform-view" element={<CANDIDAFORMview portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} />
          <Route path="/dnamapform-view" element={<DNAMapFORMview portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} />
          <Route path="/parasitologyform-view" element={<ParasitologyFORMview portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} />
          <Route path="/oralmicrobiomeform-view" element={<OralmicrobiomeFORMview portalid={portalid} backgroundcolor={backgroundcolor} color={color} Buttonbackgroundcolor={Buttonbackgroundcolor} Buttoninsidecolor={Buttoninsidecolor} prinarycolor={prinarycolor} prinarycolortext={prinarycolortext} seconderycolor={seconderycolor} />} />



          <Route path="/foodsensitivityform-view-wl" element={<FSFORMview_wl />} />
          <Route path="/candidaform-view-wl" element={<CANDIDAFORMview_wl />} />
          <Route path="/dnamapform-view-wl" element={<DNAMapFORMview_wl />} />
          <Route path="/parasitologyform-view-wl" element={<ParasitologyFORMview_wl />} />
          <Route path="/oralmicrobiomeform-view-wl" element={<OralmicrobiomeFORMview_wl />} />
      
      
      
      
          <Route path="/new-Practitioner-data" element={<Secondstepformdata />} />


        </Routes>





        {emails && <>
          <Routes>


            <Route path="/dashboard/send-email" element={<Singalemail />} />
            <Route path="/dashboard/send-mass-email" element={<Massemail />} />
            <Route path="/dashboard/email-reports" element={<Emailreport />} />
            <Route path="/dashboard/email-templates" element={<Emailtemplates />} />


            <Route path="/dashboard/reportstyles" element={<ReportStyles />} />


          </Routes>
        </>}
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
